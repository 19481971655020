import {MobileContainer} from "../Global/Global";
import Space from "../Basic/Space";
import Typography from "@mui/material/Typography";
import Row, {CenterRow} from "../Basic/Row";
import React, {Fragment} from "react";
import {useMediaContext} from "../../MediaContext";
import {observer} from "mobx-react";
import Divider from "../Basic/Divider";
import {Stack} from "@mui/material";
import {useNavigate, Navigate} from "react-router-dom";
import PictureRender from "../Shared/Pictures/PictureRender";

const Title = ({children}) => {
    return <Typography
        variant={"h6"}
        sx={{fontWeight: 'bold'}}>
        {children}
    </Typography>
}

const SubTitle = ({children, path}) => {
    const navigate = useNavigate();

    return <Typography
        sx={{
            cursor: "pointer",
            padding: "5px 0px",
        }}
        onClick={() => {
            navigate(path)
        }}
    >
        {children}
    </Typography>
}

const OmJagtbasen = () => {
    const mediaContext = useMediaContext();

    if (!mediaContext.isMobile) {
        return <Navigate to={'/'} />;
    }

    return (<React.Fragment>

            <Space height="20px"/>

            {!mediaContext.isMobile &&
                <Fragment>
                    <CenterRow>
                        <Typography variant={"h5"}>Om Jagtbasen</Typography>
                    </CenterRow>
                    <Space height="20px"/>
                    <Divider sx={{height: '0.5px'}}/>
                    <Space height="30px"/>
                </Fragment>
            }

            <MobileContainer>

                {/* Kontakt og regler */}
                <Title>Kontakt og regler</Title>
                <SubTitle path="/kontakt-jagtbasen">
                    Kontakt Jagtbasen
                </SubTitle>
                <SubTitle path="/regler">
                    Annonceringsregler
                </SubTitle>

                {/* Vilkår og sikkerhed */}
                <Space height="15px"/>
                <Title>Vilkår og sikkerhed</Title>
                <SubTitle path="/vilkaar-betingelser">
                    Vilkår og betingelser
                </SubTitle>
                <SubTitle path="/sikker-handel">
                    En sikker handel
                </SubTitle>
                <SubTitle path="/sikker-salg">
                    Sikkert salg
                </SubTitle>
                <SubTitle path="/snydet-af-saelger">
                    Jeg er blevet snydt af sælger
                </SubTitle>

                {/* Persondata og cookies */}
                <Space height="15px"/>
                <Title>Persondata og cookies</Title>
                <SubTitle path="/gdpr-cookies">
                    Persondata- og cookiepolitik
                </SubTitle>
                <SubTitle path="/cookie-deklaration">
                    Cookiedeklaration
                </SubTitle>

                {/* Handel og betaling */}
                <Space height="15px"/>
                <Title>Handel og betaling</Title>
                <SubTitle path="/kvittering-betaling">
                    Kvittering og betaling
                </SubTitle>
                <SubTitle path="/handel-med-vaaben">
                    Handel med våben
                </SubTitle>
                <Typography sx={{padding: "5px 0px"}}>
                    Betaling:
                </Typography>
                <Stack>
                    <Space height="5px"/>
                    <Stack direction={"row"}>
                        <PictureRender image="payment-credit-card" size={176} style={{opacity: 0.8}}/>
                        <Space width={10}/>
                        <PictureRender image="payment-mobile-pay-transparent" size={37}/>
                    </Stack>
                </Stack>

            </MobileContainer>
        </React.Fragment>
    );
}
export default observer(OmJagtbasen)