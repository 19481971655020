import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Space from "../../Basic/Space";
import React, { Fragment } from "react";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function ScamInfo() {
  return (
    <React.Fragment>
      <Space height="20px" />
      <Box>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography sx={{ m: 2 }} variant="h4">Jeg er blevet snydt af sælger – Hvad gør jeg?</Typography>
        <Typography sx={{ m: 1 }}>
          1. Hvis sælger ikke har afsendt varen som aftalt, og du har forudbetalt, bør du straks kontakte sælger og
          forlange, at varen bliver afsendt. </Typography>
        <Typography sx={{ m: 1 }}>
          2. Hvis sælger stadig ikke reagere, skal du anmelde det til kundeservice, så der kan oprettes en sag.
          Ligeledes vil sælger bliver kontaktet herfra med henvisning om at få løst sagen. </Typography>
        <Typography sx={{ m: 1 }}>
          3. Du kan anmelde sagen til Jagtbasen her: help@jagtbasen.dk </Typography>
        <Typography sx={{ m: 1 }}>
          4. Derudover skal du også anmelde sagen til politiet - samt til MobilePay hvis du har brugt dem til at
          betale </Typography>
        <Typography sx={{ m: 1 }}>
          5. Vi hjælper efterfølgende politiet med alle relevante oplysninger, som kan hjælpe dem i deres efterforskning
          af sagen. </Typography>
        <Typography sx={{ m: 1 }}>
          6. Som hovedregel er det altid risikabelt at forudbetale via bankoverførsel. Der er ingen mulighed for at få
          tilbageført pengene, og den eneste mulighed er derfor at gå rettens vej. </Typography>
        <Typography sx={{ m: 1 }}>
          7. Vi anbefaler derfor, at man mødes ansigt til ansigt. </Typography>
        <Typography sx={{ m: 1 }}>
          8. Det skal siges, at langt de fleste handler på Jagtbasen sker uden problemer, og at det hører til sjældenhederne,
          at der opstår problemer </Typography>
      </Box>
    </React.Fragment>
  );
}