import { Button } from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import css from "./InputFiles.module.css";

const InputFiles = ({
  register,
  errors,
  id,
  options,
  maxSize, // in MB
  callback = () => {},
  disable = false,
}) => {
  const { onChange, onBlur, name, ref } = register(id, options);

  return (
    <div>
      {/* Visual button */}
        <div className={css.wrapper}>
            <label htmlFor="file-upload">
                <Button component="span" variant="contained" color="jagtred" className={css.button}>
                    Tilføj billeder
                </Button>
            </label>
        {disable && <div className={css.overlay}></div>}
      </div>

      <div className={css.info}>Højst 8</div>
      {/* Hidden input element */}
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        multiple
        name={name}
        onBlur={onBlur}
        ref={ref}
        onChange={async (event) => {
          let files = [...event.target.files];
          let removedFile = false;

          // Loop through and remove too big images
          for (let i = 0; i < files.length; i++) {
            if (files[i].size > maxSize * 1097152) {
              removedFile = true;
              files.splice(i, 1);
            }
          }

          if (removedFile)
            alert(
              `En eller flere af filerne var for stor. Vi har sat en grænse på ${maxSize}MB`
            );

          await callback(files);
          onChange(event);
          event.target.value = null;
        }}
        className={css.input}
      />
      <p style={{ fontSize: "0.75rem", color: "red" }}>
        {errors[id] && errors[id].message}
      </p>
    </div>
  );
};

export default observer(InputFiles);
