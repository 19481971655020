import React from "react";
import {observer} from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import {MobileContainer} from "../Global/Global";
import {accountStore} from "../../Stores/AccountStore";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import KeyIcon from '@mui/icons-material/Key';
import Row from "../Basic/Row";
import EmailIcon from '@mui/icons-material/Email';
import Divider from "../Basic/Divider";
import Space from "../Basic/Space";
import Column from "../Basic/Column";
import {AccountAvatar} from "../Shared/Shared";
import InfoIcon from '@mui/icons-material/Info';
import {getLogoutConfirmation, useConfirmationDialog} from "../Dialogs/ConfirmationDialog";
import {useNavigate} from "react-router-dom";

// A row consisting of an icon and a text
const MenuItem = ({type, onClick}) => {
    const icons = {
        "Kontoindstillinger": <PersonIcon/>,
        "Loginoplysninger": <KeyIcon/>,
        "Mine annoncer": <NewspaperIcon/>,
        "Min indbakke": <EmailIcon/>,
        "Log ud": <LogoutIcon/>,
        "Om Jagtbasen": <InfoIcon/>
    }

    return <Row
        sx={{
            height: "35px",
            cursor: "pointer",
        }}
        onClick={onClick}
    >
        {icons[type]}
        <Space width="10px"/>
        <Typography>
            {type}
        </Typography>
    </Row>
}

export const MySiteMobile = observer(() => {
    const navigate = useNavigate();
    const getConfirmation = useConfirmationDialog();

    const navigateTo = (path) => {
        navigate(path)
    }

    return <React.Fragment>
        <Space height="50px"/>
        <MobileContainer>
            <Row sx={{
                alignItems: "center",
            }}>
                <AccountAvatar radius="65px"
                               firstName={accountStore.user.firstname}
                               lastName={accountStore.user.lastname}/>
                <Space width="20px"/>
                <Column>
                    <Typography id="userName" sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                    }}>
                        {accountStore.user.firstname} {accountStore.user.lastname}
                    </Typography>
                    <Typography sx={{
                        lineHeight: "20px",
                        fontSize: "15px"
                    }}>
                        {accountStore.user.email}
                    </Typography>
                    <Typography sx={{
                        lineHeight: "20px",
                        fontSize: "15px"
                    }}>
                        {accountStore.user.phone}
                    </Typography>
                </Column>
            </Row>
        </MobileContainer>

        <Space height="30px"/>
        <Divider/>
        <Space height="30px"/>

        <MobileContainer>
            <MenuItem
                type="Mine annoncer"
                onClick={() => {navigateTo("/min-side/my-posts")}}
            />
            <MenuItem
                type="Min indbakke"
                onClick={() => {navigateTo("/min-side/min-indbakke")}}
            />
        </MobileContainer>

        <Space height="15px"/>
        <Divider/>
        <Space height="15px"/>

        <MobileContainer>
            <MenuItem
              type="Om Jagtbasen"
              onClick={() => {navigateTo("/om-jagtbasen")}}
            />
            <MenuItem
                type="Kontoindstillinger"
                onClick={() => {navigateTo("/min-side/profil")}}
            />
            <MenuItem
                type="Log ud"
                onClick={() => {
                    getLogoutConfirmation(getConfirmation).then((confirmed) => {
                        if (confirmed) {
                            accountStore.logOutCognito().then(_ => navigate("/"));
                        }
                    }).catch((error) => {
                        console.error("Error confirming logout", error);
                    });
                }}
            />
        </MobileContainer>
    </React.Fragment>
})