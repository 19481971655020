import accountService from "./AccountService";
import { reportException, reportMessage } from "../Utilities/sentry";

class UserPostVisitsService {
  async addUserPostVisit(postId){
    const jwt = await accountService.getCognitoJwt()
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`,
        }
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/api/UserPostVisits/AddVisit?postId=${postId}`,
        requestOptions
      );
      if (response.ok){
        const jsonRes = await response.json();
        return jsonRes.message
      } else {
        reportMessage(
          "Received status != 200 when adding user's post visit", {options : {
              status: response.status,
              message: response.statusText,
              response: response.clone().text(),
              report: JSON.stringify(`postId=${postId}`)
            }}
        );
      }
    } catch (e) {
      reportException(e);
    }
  }
}
export default new UserPostVisitsService();