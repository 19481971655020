import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {zipToCity} from "../../Constants/Constants";
import {SizedBox} from "../Global/Global";
import {styled, TextField} from "@mui/material";
import {formatZip} from "../../Utilities/postUtils";

const LockedField = styled(TextField)(({theme}) => ({
    ".MuiOutlinedInput-root": {
        backgroundColor: theme.palette.grey["300"],
    },
}));

const InputZip = ({
    register,
    errors,
    id,
    label,
    options,
    disabled = false,
    defaultValue = "",
}) => {
    const {onChange, onBlur, name, ref} = register(id, options);

    const [zip, setZip] = useState(defaultValue);

    return (
        <div style={{display: "flex"}}>
            <TextField
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: "white",
                    }
                }}
                style={{maxWidth: "5rem"}}
                fullWidth
                value={zip}
                disabled={disabled}
                error={errors[name] ? true : false}
                label={label}
                helperText={errors[name] && errors[name].message}
                onChange={(event) => {
                    event.target.value = formatZip(event.target.value);
                    setZip(event.target.value);
                    onChange(event);
                }} // assign onChange event
                onBlur={onBlur} // assign onBlur event
                name={name} // assign name prop
                ref={ref} // assign ref prop
            />
            <SizedBox width="1rem" height="1rem"/>
            <LockedField
                style={{maxWidth: "15rem"}}
                fullWidth
                value={zipToCity["DK"][zip] ?? "By"}
                disabled={true}
            />
        </div>
    );
};

export default observer(InputZip);
