import {makeAutoObservable, runInAction} from "mobx";
import bannerService from "../Services/BannerService";


class BannerStore {
    banners = {};

    constructor() {
        makeAutoObservable(this)
        this.init();
    }

    async init() {
        await this.fetchBanners();
    }

    async fetchBanners() {
        const banners = await bannerService.getBanners();

        // If an error occurred or there are no banners
        if (banners == null) return;

        runInAction(() => {
            this.banners = banners;
        })
    }

    // In case a link is broken,
    // we can remove the banner from the page here
    removeBanner(location){
        runInAction(() => {
            delete this.banners[location];
        })
    }
}

export const bannerStore = new BannerStore();