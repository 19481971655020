import {makeAutoObservable, runInAction} from "mobx";
import CategoryService from "../Services/CategoryService";
import {toJS} from "mobx";

class CategoryStore {
    isInitialized = false; // False until everything is loaded
    categories = [];
    categoryPosts;
    selectedPosts = [];
    latestPosts = [];
    categoryNameToId = {};
    categoryIdToName = {};
    categoryIdToSubcategories = {};

    constructor() {
        makeAutoObservable(this);
        this.init();
    }

    async init() {
        await this.getCategories();
        await this.createMapOfCategories();
        runInAction(() => {
            this.isInitialized = true;
        });
    }

    async getCategories() {
        let result = await CategoryService.fetchCategories();

        // If we were unsuccessful in fetching categories, we just do not load them
        // Maybe not the best..
        if (result == null) return;

        // Make sure the "Other" category goes at the end
        const other = result.splice(0, 1);
        result = [...result, ...other];

        runInAction(() => {
            this.categories = result;
        });
    }

    createMapOfCategories() {
        if (this.categories.length > 0) {
            runInAction(() => {
                // todo should this be done in the backend?
                this.categories = toJS(this.categories).map((category) => {
                    // Categories map
                    this.categoryNameToId[category.Name] = category.CategoryId;
                    this.categoryIdToName[category.CategoryId] = category.Name;

                    // Subcategories map
                    const subcategoriesMap = {};
                    category.Subcategories.forEach((subcategory) => {
                        if (subcategory !== undefined) {
                            subcategoriesMap[subcategory.Name] = subcategory.SubcategoryId;
                        }
                    });
                    this.categoryIdToSubcategories[category.CategoryId] = subcategoriesMap;

                    category.MitIdRequired = category.Subcategories.every(subcategory => subcategory.MitIdRequired)
                    return category;
                });
            });
        }
    }

    arrangeSelectedAndLatestPosts() {
        let randomArr = [];
        while (randomArr.length < 3) {
            let randomIndex = Math.floor(Math.random() * this.categoryPosts.length);
            if (!randomArr.includes(randomIndex)) {
                randomArr.push(randomIndex);
            }
        }

        for (const randomArrElement of randomArr) {
            runInAction(() =>
                this.selectedPosts.push(this.categoryPosts[randomArrElement])
            );
        }

        runInAction(() => (this.latestPosts = this.categoryPosts.slice(-3)));
    }
}

export const categoryStore = new CategoryStore();
