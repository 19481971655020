import React from "react";
import css from "./Global.module.css";
import {Box, Container, useMediaQuery, useTheme} from "@mui/material";
import {useMediaContext} from "../../MediaContext";

export function SizedBox({ width= "0", height= "0" }) {
  return (
    <div
      style={{
        width: width,
        height: height,
      }}
    ></div>
  );
}

/**
 * A Container that only adds padding on mobile screens
 */
export const MobileContainer = ({children, ...props}) => {
  const mediaContext = useMediaContext();

  return <Box {...props} style={mediaContext.isMobile
      ? {margin: "0 20px"}
      : {}
  }>
    {children}
  </Box>
}
