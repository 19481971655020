import Space from "../../Basic/Space";
import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function GDPRPersonData() {
  return (
    <React.Fragment>
      <Space height="20px" />
      <Container>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography variant="h3">Persondata (GDPR) & Cookiepolitik</Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>1. Generelt</Typography>
        <Typography>Jagtbasens forretningsmodel bygger på at forbedre og professionalisere jægeres hverdag med digitale produkter og tjenester.
          Vi arbejder dagligt på at forbedre og optimere vores produkter og tjenester og arbejder hårdt for at nå dette hovedmål – alt sammen under Jagtbasen ApS kontrol og behandlingsansvar.
          Det er afgørende for os, at dine personoplysninger behandles sikkert for at opnå og opretholde vores brugeres tillid.
        </Typography>
        <Typography>
          Vi er klar over, at anvendelsen af personlige oplysninger er en nødvendighed for at kunne tilbyde de bedste og mest relevante produkter og tjenester til vores brugere.
          Jagtbasen stræber hårdt efter at skabe gennemsigtighed og klare retningslinjer omkring vores behandling af personoplysninger og søger altid at finde den rette balance mellem privatlivets fred og forretningsmæssige interesser.
        </Typography>
        <Typography>
          Denne politik om behandling af personoplysninger (persondatapolitik også kaldet GDPR) beskriver, hvordan www.jagtbasen.dk indsamler og behandler oplysninger om dig.
          Persondatapolitikken gælder for personoplysninger, som du giver til os, eller som vi indsamler via Jagtbasen ApS også kendt som hjemmeside, www.jagtbasen.dk.
          jagtbasen.dk er dataansvarlig for dine personoplysninger. Alle henvendelser vedrørende persondata hos Jagtbasen skal ske via de kontaktoplysninger, der er anført under punkt 3.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>2. Indledning</Typography>
        <Typography>
          Denne persondatapolitik beskriver, hvordan vi indsamler, bruger, opbevarer og beskytter dine personoplysninger, når du bruger vores online handelsplatform - jagtbasen.dk
          Ved at bruge platformen accepterer du denne persondatapolitik og vores behandling af dine personoplysninger i overensstemmelse hermed.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>3. Dataansvarlig</Typography>
        <Typography>
          Den dataansvarlige for behandlingen af dine personoplysninger på Platformen er:
        </Typography>
        <Typography>
          Jagtbasen ApS
        </Typography>
        <Typography>
          Ny Østergade 9, 1 sal
        </Typography>
        <Typography>
          3600 Frederikssund, Danmark
        </Typography>
        <Typography>
          Du kan kontakte os vedrørende spørgsmål om databeskyttelse og denne persondatapolitik på gdpr@jagtbasen.dk eller på telefon +45 91528585.
        </Typography>

        <Space height="10px" />
        <Typography fontWeight={"bold"}>Personoplysninger, vi indsamler</Typography>
        <Typography variant="body1">
          Vi indsamler følgende personoplysninger, når du bruger Platformen Jagtbasen:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Registreringsoplysninger: Når du opretter en konto på Platformen, indsamler vi dine kontaktoplysninger, såsom navn, adresse, alder, skyttehånd, jagtformer,
              telefonnummer og e-mailadresse.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Annonceringsoplysninger: Når du opretter en annonce på Jagtbasen,
              indsamler vi oplysninger om de varer eller tjenester, du tilbyder, herunder billeder, beskrivelser og priser.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Transaktionsoplysninger: Når du handler på platformen Jagtbasen, indsamler vi oplysninger om dine transaktioner,
              såsom købsdato, beløb og modpart.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Kommunikationsoplysninger: Når du kommunikerer med andre brugere eller med os via Jagtbasen,
              indsamler vi oplysninger om din kommunikation, såsom beskedindhold og tidspunkt.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Tekniske oplysninger: Når du bruger Jagtbasen, indsamler vi tekniske oplysninger om din enhed, såsom IP-adresse, browsertype,
              operativsystem og besøgte sider.
            </Typography>
          </li>
        </ul>

        <Space height="10px" />
        <Typography fontWeight={"bold"}>5. Formål med behandlingen</Typography>
        <Typography variant="body1">
          Vi behandler dine personoplysninger til følgende formål:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              At levere Jagtbasen tjenester, herunder at gennemføre transaktioner og facilitere kommunikation mellem brugere. Både erhverv og private.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At administrere og vedligeholde din konto og dine annoncer.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At forbedre og tilpasse Jagtbasen og vores tjenester baseret på dine præferencer og brugsmønstre, samt viser målrettet reklameindhold til brugerne og måler effektiviteten heraf.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At overvåge og analysere platformens brug og trafik for at forhindre misbrug, sikre sikkerhed og optimere ydeevne.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At kommunikere med dig om Jagtbasen og vores tjenester, herunder at sende dig nyhedsbreve, opdateringer og markedsføringsmateriale.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At overholde gældende love og regler, herunder at besvare anmodninger fra retshåndhævende myndigheder og beskytte vores rettigheder og ejendom.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              At foretager prisberegninger og viser erhvervsannoncer side om side med annoncer fra privatpersoner.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Når du opretter en brugerprofil, sørger vi for, at du får sikker adgang til vores tjenester.
              På din kontoside kan du administrere dine personlige jagtpræferencer,
              verificere dine kontaktoplysninger via Mit ID og skabe en tryg identitet til interaktion med andre private og erhvervs brugere på Jagtbasen.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Vi varetager betalinger, fakturering og andre aktiviteter og opbevarer dokumentation i henhold til de gældende bogføringsregler.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Vi forbedrer kontinuerligt jagtbasen.dk og vores tjenester på grundlag af markeds- og brugerundersøgelser samt analyser af brugeradfærd.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Vi sørger for at Jagtbasen og vores tjenester er sikre, og vi kontrollerer persondata for at beskytte vores brugere og os selv mod potentielle svigagtige aktiviteter, overtrædelse af vores politikker og krænkende adfærd. Vi scanner meddelelser automatisk og kontrollerer for spam,
              virus, phishing og anden skadelig aktivitet, ulovligt eller forbudt indhold eller overtrædelser af vores brugervilkår, denne politik om beskyttelse af personlige oplysninger eller vores øvrige politikker.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Vi formidler personligt tilpasset indhold til brugerne,
              f.eks. et udvalg af personligt tilpasset indhold på forsiden af visse nyhedssektioner.
              Vi sender også nyhedsbreve til brugere af vores websteder, og vi anbefaler annoncer på vores markedspladser.
            </Typography>
          </li>
        </ul>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>6. Deling af dine personoplysninger</Typography>
        <Typography variant="body1">
          Vi deler dine personoplysninger med følgende tredjeparter:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Andre brugere af platformen Jagtbasen: Vi deler dine kontaktoplysninger og annonceringsoplysninger med andre brugere, så de kan kontakte dig og se dine annoncer.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Tjenesteudbydere: Vi deler dine personoplysninger med tjenesteudbydere, der hjælper os med at levere platformens tjenester, såsom betalingsbehandlere, webhosting firmaer og analysevirksomheder.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Juridiske grunde: Vi kan dele dine personoplysninger med retshåndhævende myndigheder, reguleringsorganer eller andre tredjeparter, når vi er forpligtet eller berettiget til det i henhold til gældende lovgivning eller for at beskytte vores eller andres rettigheder, ejendom eller sikkerhed.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Virksomhedsoverdragelse: Hvis vores virksomhed eller en del af den fusioneres, købes, sælges eller på anden måde overføres, kan vi dele dine personoplysninger med den nye ejer eller dennes repræsentanter.
            </Typography>
          </li>
        </ul>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          7. Dine rettigheder
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Som bruger af platformen Jagtbasen har du følgende rettigheder i forhold til dine personoplysninger:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" component="span">
              Retten til indsigt: Du har ret til at anmode om en kopi af de personoplysninger, vi behandler om dig.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Retten til berigtigelse: Du har ret til at anmode om rettelse af ukorrekte eller ufuldstændige personoplysninger om dig.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Retten til sletning: Du har ret til at anmode om sletning af dine personoplysninger under visse omstændigheder, såsom når behandlingen ikke længere er nødvendig for det formål, de blev indsamlet til.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Retten til begrænsning af behandlingen: Du har ret til at anmode om begrænsning af behandlingen af dine personoplysninger under visse omstændigheder, såsom når du bestrider rigtigheden af oplysningerne.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Retten til dataportabilitet: Du har ret til at anmode om overførsel af dine personoplysninger til en anden dataansvarlig, når det er teknisk muligt og behandlingen er baseret på samtykke eller en kontrakt.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Retten til at gøre indsigelse: Du har ret til at gøre indsigelse mod behandlingen af dine personoplysninger, når behandlingen er baseret på vores legitime interesser eller udføres til markedsføringsformål.
              For at udøve dine rettigheder kan du kontakte os på de kontaktoplysninger, der er angivet i afsnit 3. Vi vil besvare din anmodning inden for en måned efter modtagelsen, medmindre vi har brug for mere tid, i hvilket tilfælde vi vil informere dig.
            </Typography>
          </li>
        </ul>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          8. Sikkerhed
        </Typography>
        <Typography>
          Vi træffer passende tekniske og organisatoriske foranstaltninger for at beskytte dine personoplysninger mod uautoriseret adgang, tab,
          misbrug, ændring og ødelæggelse. Vi overholder branchestandarder for databeskyttelse og opdaterer løbende vores sikkerhedsprocedurer for at imødekomme nye trusler og teknologiske fremskridt.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          9.	Opbevaring af personoplysninger
        </Typography>
        <Typography>
          Vi opbevarer dine personoplysninger, så længe det er nødvendigt for at levere platformens tjenester, overholde vores juridiske forpligtelser, løse tvister og håndhæve vores aftaler.
          Opbevaringsperioden varierer afhængigt af formålet med behandlingen og de gældende lovgivningskrav.
          Når dine personoplysninger ikke længere er nødvendige, vil vi slette eller anonymisere dem, dog tidligst efter 5 år.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          10.	Tredjelands overførsler
        </Typography>
        <Typography>
          Når vi overfører dine personoplysninger til tredjelande (lande uden for det Europæiske Økonomiske Samarbejdsområde),
          sikrer vi, at overførslen sker i overensstemmelse med gældende databeskyttelseslovgivning. Dette kan omfatte brug af standardkontraktbestemmelser godkendt af EU-Kommissionen eller overførsel til virksomheder,
          der er certificeret under Privacy Shield-ordningen (for overførsler til USA).
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          11.	Ændringer i persondatapolitikken
        </Typography>
        <Typography>
          Vi forbeholder os retten til at opdatere og ændre denne persondatapolitik fra tid til anden uden forudgående varsel.
            Det er dit ansvar løbende at gennemgå og overholde den gældende persondatapolitik. Din fortsatte brug af platformen Jagtbasen efter ændringer i persondatapolitikken udgør din accept af sådanne ændringer.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          12.	Kontakt og klager
        </Typography>
        <Typography>
          Hvis du har spørgsmål, kommentarer eller bekymringer vedrørende denne persondatapolitik eller vores behandling af dine personoplysninger, bedes du kontakte os på de kontaktoplysninger, der er angivet i afsnit 3.
          Hvis du mener, at vi ikke overholder gældende databeskyttelseslovgivning, har du ret til at indgive en klage til den relevante databeskyttelsesmyndighed i dit land.
          Ved at bruge denne platform bekræfter du din forståelse af og accept af denne persondatapolitik og vores behandling af dine personoplysninger i overensstemmelse hermed. Det er dit ansvar at sikre,
          at du er bekendt med og overholder alle gældende love og bestemmelser vedrørende databeskyttelse og privatliv.
        </Typography>
        <Space height="10px" />
        <Typography fontWeight={"bold"}>
          Cookiepolitik
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          1. Indledning
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Denne cookiepolitik beskriver, hvordan vi bruger cookies og lignende teknologier på vores online handelsplatform Jagtbasen for at forbedre din brugeroplevelse, indsamle analytiske data og levere målrettet annoncering. Ved at bruge Jagtbasen accepterer du denne cookiepolitik og vores brug af cookies og lignende teknologier i overensstemmelse hermed.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          2. Hvad er cookies?
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Cookies er små tekstfiler, der gemmes på din enhed (computer, smartphone eller tablet),
          når du besøger en hjemmeside. Cookies hjælper med at genkende din enhed og dine præferencer,
          når du vender tilbage til hjemmesiden. De kan også indsamle oplysninger om din brug af hjemmesiden og interagere med andre teknologier,
          såsom lokal lagring og web-beacons.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          3. Hvordan vi bruger cookies?
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi bruger cookies og lignende teknologier på Platformen til følgende formål:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" component="span">
              Funktionelle cookies: Disse cookies er nødvendige for at Platformen kan fungere korrekt og leverer grundlæggende funktioner, såsom navigation, login og adgang til sikre områder. Uden disse cookies vil Platformen muligvis ikke fungere korrekt.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Præferencecookies: Disse cookies gemmer dine præferencer og indstillinger, såsom sprog og søgeord, så vi kan tilpasse platformen efter dine ønsker.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Analytiske cookies: Disse cookies indsamler oplysninger om din brug af Platformen, såsom hvilke sider du besøger, hvor lang tid du bruger på Platformen og eventuelle fejl, du måtte opleve. Vi bruger disse oplysninger til at forbedre Platformens ydeevne, fejlfinde og optimere vores tjenester.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              Marketingcookies: Disse cookies sporer din onlineaktivitet for at levere målrettet annoncering,
              der er relevant for dig og dine interesser. De kan også begrænse,
              hvor mange gange du ser en bestemt annonce og måle effektiviteten af annonceringskampagner.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" component="p" gutterBottom>
          4. Tredjeparts cookies
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi tillader også tredjeparter at sætte cookies på din enhed, når du besøger Platformen.
          Disse tredjeparts cookies kan være funktionelle, præference-,
          analytiske eller marketingcookies og bruges af tredjeparter til at levere tjenester på deres vegne,
          såsom sociale medieintegration, analyse og målrettet annoncering.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          5. Kontrol af cookies
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          De fleste browsere giver dig mulighed for at styre dine cookieindstillinger, herunder at acceptere eller afvise cookies, slette eksisterende cookies og indstille, hvordan cookies skal behandles af hjemmesider. Bemærk, at hvis du vælger at blokere eller slette cookies, kan det påvirke din brugeroplevelse på Platformen, og nogle funktioner kan muligvis ikke fungere korrekt.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          6. Lokal lagring og web-beacons
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi kan også bruge lokal lagring og web-beacons i forbindelse med Platformen. Lokal lagring fungerer på samme måde som cookies, men kan gemme større mængder data og er ikke begrænset til tekstfiler. Web-beacons er små grafiske billeder, der er indlejret i webindhold og e-mails og bruges til at spore din interaktion med hjemmesider og e-mail-meddelelser.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          7. Ændringer i cookiepolitikken
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi forbeholder os retten til at opdatere og ændre denne cookiepolitik fra tid til anden uden forudgående varsel. Det er dit ansvar løbende at gennemgå og overholde den gældende cookiepolitik. Din fortsatte brug af Platformen efter ændringer i cookiepolitikken udgør din accept af sådanne ændringer.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          8. Kontakt
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Hvis du har spørgsmål, kommentarer eller bekymringer vedrørende denne cookiepolitik eller vores brug af cookies og lignende teknologier,
          bedes du kontakte os ved hjælp af kontaktoplysningerne angivet i vores persondatapolitik eller brugervilkår.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          9. Yderligere
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at bruge denne platform bekræfter du din forståelse af og accept af denne cookiepolitik og vores brug af cookies og lignende teknologier i overensstemmelse hermed.
          Det er dit ansvar at sikre, at du er bekendt med og overholder alle gældende love og bestemmelser vedrørende cookies og online-privatliv.
        </Typography>
        <Space height="15px"/>
      </Container>
    </React.Fragment>
  )
}