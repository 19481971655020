import bannerService from "../../Services/BannerService";
import {bannerStore} from "../../Stores/BannerStore";
import React from "react";

export const Ad = ({banner, aspectRatio}) => {
    if (!banner) return null;

    return  <img
        alt="Ad"
        src={banner.image}
        style={{
            width: "100%",
            aspectRatio: aspectRatio,
            objectFit: "cover",
            cursor: banner.link ? "pointer" : "default"
        }}
        onClick={() => {
            bannerService.reportClick(banner.id);
            if (banner.link) {
                window.open(banner.link, "_blank");
            }
        }}
        onError={(e) => {
            bannerStore.removeBanner(banner.location)
        }}
    />
}