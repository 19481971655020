import React, {useEffect, useState} from "react";
import "./App.css";
import {observer} from "mobx-react-lite";
import Login from "./Components/Auth/Login/Login";
import OpretAnnonce from "./Components/Posts/Create/CreatePost";
import {
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import Home from "./Components/Home";
import FrontPage from "./Components/Frontpage/FrontPage";
import Post from "./Components/Posts/Post";
import Search from "./Components/Search/Results/SearchResults";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Profile from "./Components/MySite/Profile/Profile";
import SignUpFlow from "./Components/Auth/SignUp/SignUpFlow";
import UserPosts from "./Components/MySite/MyPosts/MyPosts";
import Inbox from "./Components/MySite/Inbox/Inbox";
import ForgotPassword from "./Components/Auth/ForgotPassword/ForgotPassword";
import {BadPath} from "./Components/BadPath";
import {accountStore} from "./Stores/AccountStore";
import MySite from "./Components/MySite/MySite";
import {Rules} from "./Components/Footer/Documents/Rules";
import {SafeSale} from "./Components/Footer/Documents/SafeSale";
import {SafeTrade} from "./Components/Footer/Documents/SafeTrade";
import {ReceiptAndPayment} from "./Components/Footer/Documents/ReceiptAndPayment";
import {ScamInfo} from "./Components/Footer/Documents/ScamInfo";
import {Terms} from "./Components/Footer/Documents/Terms";
import UnsubscribePage from "./Components/Chat/UnsubscribeEmail";
import OmJagtbasen from "./Components/Footer/OmJagtbasen";
import {KontaktJagtbasen} from "./Components/Footer/Documents/KontaktJagtbasen";
import {FirmaKontakt} from "./Components/Footer/Documents/FirmaKontakt";
import {GDPRPersonData} from "./Components/Footer/Documents/GDPR-PersonData";
import {TradeWithWeapon} from "./Components/Footer/Documents/TradeWithWeapon";
import {Authentication} from "./Components/Auth/Authentication";
import CookieSettings from "./Components/Footer/Cookies/CookieSettings";
import ForgotPasswordSecondFlow from "./Components/Auth/ForgotPassword/ForgotPasswordSecondFlow";
import Maintenance from "./Components/Maintenance/Maintenance";
import * as Sentry from "@sentry/react";
import {ErrorPage} from "./Components/ErrorPage";
import MobileFullSizeImages from "./Components/Posts/Create/MobileFullSizeImages";
import UserVisitService from "./Services/UserVisitService";

Amplify.configure(awsconfig);

const ProtectedRoute = () => {
    const [status, setStatus] = useState('success');

    useEffect(() => {
        const checkBackendConnection = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/HealthCheck`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setStatus('success');
            } catch (error) {
                setStatus('error');
            }
        };

        checkBackendConnection();
    }, []);

    return (
            status === 'error'
                ? <Maintenance/>
                : <Outlet/>
    );
};

function App() {
    useEffect(() => {
        const getUserAndRecordVisit = async () => {
            await accountStore.getCurrentUser();
            if (accountStore.logged){
                await UserVisitService.recordUserVisit();
            }
        }
        getUserAndRecordVisit();
    }, []);

    return (
        <Routes>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>}>
                    <Route exact path="/post/:postId" element={<Post/>}>
                        <Route exact path="fuldbillede" element={<MobileFullSizeImages/>}/>
                    </Route>
                    <Route exact path="/search" element={<Search/>}/>
                    <Route exact path="/signup" element={<SignUpFlow/>}/>
                    <Route exact path="/log-ind" element={<Login/>}/>
                    <Route exact path="/glemt-adgangskode" element={<ForgotPassword/>}/>
                    <Route exact path="/glemt-adgangskode-verificerer" element={<ForgotPasswordSecondFlow/>}/>
                    <Route element={<Authentication/>}>
                        <Route exact path="/min-side" element={<MySite/>}/>
                        <Route exact path="/min-side/profil" element={<Profile/>}/>
                        <Route exact path="/min-side/min-indbakke" element={<Inbox/>}/>
                        <Route exact path="/min-side/my-posts" element={<UserPosts/>}/>

                        <Route exact path="/opret-annonce" element={<OpretAnnonce key={"opret-annonce"}/>}/>
                        <Route exact path="/rediger-annonce/:postId" element={<OpretAnnonce key={"rediger-annonce"}/>}/>
                    </Route>
                    <Route exact path="/" element={<FrontPage/>}/>
                    <Route exact path="/om-jagtbasen" element={<OmJagtbasen/>}/>
                    <Route exact path="/error/:id" element={<ErrorPage/>}/>
                    {/* If nothing matches, this catches */}
                    <Route path="/*" element={<BadPath/>}/>
                </Route>
                <Route path="/EmailNotification/:unSubscribeToken/:id" element={<UnsubscribePage/>}/>
                <Route exact path="/regler" element={<Rules/>}/>
                <Route exact path="/sikker-salg" element={<SafeSale/>}/>
                <Route exact path="/sikker-handel" element={<SafeTrade/>}/>
                <Route exact path="/kvittering-betaling" element={<ReceiptAndPayment/>}/>
                <Route exact path="/snydet-af-saelger" element={<ScamInfo/>}/>
                <Route exact path="/vilkaar-betingelser" element={<Terms/>}/>
                <Route exact path="/kontakt-jagtbasen" element={<KontaktJagtbasen/>}/>
                <Route exact path="/kontakt-jagtbasen-firma" element={<FirmaKontakt/>}/>
                <Route exact path="/gdpr-cookies" element={<GDPRPersonData/>}/>
                <Route exact path="/handel-med-vaaben" element={<TradeWithWeapon/>}/>
                <Route exact path="/cookie-deklaration" element={<CookieSettings/>}/>
            </Route>
        </Routes>
    );
}

export default observer(Sentry.withProfiler(App));
