import {Outlet, useLocation, Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {accountStore} from "../../Stores/AccountStore";

/**
 * If the user tries to access a screen that requires authentication,
 * this component will take care of handling the redirection properly.
 */
export const Authentication = observer(() =>  {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            await accountStore.getCurrentUser();
            setIsLoading(false);
        };

        if (!accountStore.logged) {
            loadUser();
        } else {
            setIsLoading(false)
        }
    }, [accountStore.logged]);

    if (isLoading) {
        return null;
    }

    if (accountStore.logged) {
        return <Outlet/>;
    }

    return <Navigate to={'/log-ind'} replace state={{from: location}}/>;
});
