import * as React from "react";
import {Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import postsService from "../../Services/PostsService";

export const LinkSanitizerWrapper = ({text, postId}) => {
    // Regular expression to match URLs
    const urlRegex = /(\bhttps:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]+[-A-Z0-9+&@#/%=~_|])/ig;

    const parts = text.split(urlRegex)

    return parts.map((part, index) => {
        // Check if the part is a URL
        if (urlRegex.test(part)) {
            return (
                <React.Fragment key={index}><LinkDialog url={part} postId={postId}/></React.Fragment>
            );
        }
        return <React.Fragment key={index}><Typography mt={0} sx={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>{part}</Typography></React.Fragment>
    });
}

const LinkDialog = ({url, postId}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAndOpenURL = (url) => {
        setOpen(false);
        postsService.sendRedirectionInformation(postId);
        window.open(url, '_blank').focus();
    };

    return (
        <React.Fragment>
            <Typography color="primary" mt={0}  sx={{cursor: 'pointer'}} onClick={handleClickOpen}>{url}</Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Ekstern link
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vi er ved at omdirigere dig til en ekstern hjemmeside, som vi ikke har kontrol over. Vær opmærksom på, at du selv er ansvarlig for din videre færd på denne side.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annullere</Button>
                    <Button onClick={ () => handleCloseAndOpenURL(url)} >
                        Fortsæt
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

}