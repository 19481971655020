import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import PostsService from "../../Services/PostsService";
import useSnackbar from "../Snackbar/SnackbarHook";
import {useNavigate} from "react-router-dom";

export default function DeletePostConfirmation({deleteDialogOpen, setDeleteDialogOpen, postId, fetchDataHandle}) {
    const [error, setError] = React.useState(false);
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const handleClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const reason = formJson.reason;

        if (!!reason) {
            try {
                const success = await PostsService.deletePost(postId, reason);
                if(!success){
                    snackbar.handleOpen("Fejl ved sletning", "error");
                } else{
                    snackbar.handleOpen("Annoncen blev slettet", "success");
                    if(fetchDataHandle === undefined){
                        navigate("/");
                    } else {
                        fetchDataHandle();
                    }
                }
                handleClose();
            } catch (error) {
                snackbar.handleOpen("Fejl ved sletning", "error");
                handleClose();
            }
        } else {
            setError(true);
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleFormSubmit,
                }}
            >
                <DialogTitle>Slet annonce?</DialogTitle>
                <DialogContent>
                        <Stack spacing={2}>
                            <Typography>Du er i gang med at fjerne din annonce, så den ikke kan findes på
                                Jagtbasen.</Typography>
                            <Typography>Når du sletter en annonce, mister du al dialog med købere i 'Beskeder'. Hvis du
                                er ved at handle, anbefaler vi, at du i stedet deaktivere annoncen.</Typography>
                        </Stack>
                    <FormControl sx={{marginTop: 2}} error={error}>
                        <FormLabel id="reasonLabel" sx={{fontWeight: 'bold', '&.Mui-error': {color: 'primary.main'}}}>Fik du varen
                            solgt?</FormLabel>
                        <RadioGroup
                            aria-labelledby="reason"
                            name="reason"
                        >
                            <FormControlLabel value="1" control={<Radio/>} label="Varen blev solgt på Jagtbasen"/>
                            <FormControlLabel value="2" control={<Radio/>} label="Varen blev solgt et andet sted"/>
                            <FormControlLabel value="3" control={<Radio/>} label="Varen blev ikke solgt"/>
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuller</Button>
                    <Button type="submit">Slet annonce</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
