import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Skeleton, useMediaQuery, useTheme} from "@mui/material";


function Skeletons() {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    // Number of elements to load horizontally
    let numberOfElements = 1;
    if (sm) numberOfElements = 2;
    if (md) numberOfElements = 3;
    if (lg) numberOfElements = 4;

    const skeletons = [];

    for (let i = 0; i < numberOfElements; i++)
        skeletons.push(skeleton(i))

    return <React.Fragment>
        <Grid container spacing={3}>
            {skeletons}
        </Grid>
    </React.Fragment>
}

const skeleton = (key) => {
    return <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
        <Skeleton variant="rectangular" height={150}/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
    </Grid>
}

export default observer(Skeletons);