import {reportException} from "../Utilities/sentry";

class LotteryService {
    async getActiveLotteries() {
        const requestOptions = {method: "GET"};

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Lottery/active-lotteries",
                requestOptions
            );

            // If an error occurred, return null
            if (!response.ok) return;

            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
        }
    }
}

export default new LotteryService();