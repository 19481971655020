import React, { useEffect, useState } from "react";

const ConstantsContext = React.createContext({});

export const ConstantsContextProvider = ({children}) => {
    // Default to 28 days but update with API call
    const [activeDays, setActiveDays] = useState(28);

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL + "/api/Posts/active-days",
            {method: "GET"}
        )
            .then(response => response.json())
            .then(data => {
                if(data && data != activeDays) {
                    setActiveDays(data);
                }
            });
    }, []);

    return (
        <ConstantsContext.Provider
            value={{
                activeDays: activeDays,
            }}
        >
            {children}
        </ConstantsContext.Provider>
    );
}

export const useConstantsContext = () => {
    return React.useContext(ConstantsContext);
}