export function log(obj) {
    if (typeof obj === "string") {
        console.log(obj);
        return;
    }

    console.log(JSON.stringify(obj, null, 2));
}

// Function to capitalize the first letter
export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}