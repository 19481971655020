import {Box} from "@mui/material";

export const WhiteOverlay = () => {
    return <Box sx={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        opacity: "0.6",
        zIndex: "1",
    }}/>
}