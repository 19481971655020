import { ClothesIcon } from "../ClothesIcon";
import FootwearIcon from "../FootwearIcon";
import { WeaponIcon } from "../WeaponIcon";
import BinocularIcon from "../BinocularIcon";
import DecoyIcon from "../DecoyIcon";
import { CareIcon } from "../CareIcon";
import { WildCareIcon } from "../WildCareIcon";
import { AccessoriesIcon } from "../AccessoriesIcon";
import ReloadIcon from "../ReloadIcon";
import AmmunitionIcon from "../AmmunitionIcon";
import BowIcon from "../BowIcon";
import OpticIcon from "../OpticIcon";
import DogIcon from "../DogIcon";
import {CameraIcon} from "../CameraIcon";
import OutdoorIcon from "../OutdoorIcon";
import TravelIcon from "../TravelIcon";
import CourseIcon from "../CourseIcon";

export function IconFactory(props) {
  const selection = props.icon || "Andet";
  const icons =  {
    'Jagttøj' : <ClothesIcon {...props}/>,
    'Fodtøj' : <FootwearIcon {...props}/>,
    'Våben' : <WeaponIcon {...props}/>,
    'Optik og kikkert': <BinocularIcon {...props}/>,
    'Lyddæmpere' : <DecoyIcon {...props}/>,
    'Montager, ringe og baser': <CareIcon {...props}/>,
    'Vildtkamera': <CameraIcon {...props}/>,
    'Våbentilbehør': <AccessoriesIcon {...props}/>,
    'Ammunition': <AmmunitionIcon {...props}/>,
    'Genladning': <ReloadIcon {...props}/>,
    'Buejagt': <BowIcon {...props}/>,
    'Outdoor': <OutdoorIcon {...props}/>,
    'Jagttilbehør': <OpticIcon {...props}/>,
    'Jagtrejser': <TravelIcon {...props}/>,
    'Jagtkurser': <CourseIcon {...props}/>,
    'Hunde': <DogIcon {...props}/>,
    'Andet': <WildCareIcon {...props}/>

  }
  return (
    <>
      {icons[selection]}
    </>
  )
}