import { reportException, reportMessage } from "../Utilities/sentry";
import accountService from "./AccountService";

class BannerService{
    async getBanners(){
        const requestOptions = {method: "GET"};
        const url = process.env.REACT_APP_API_URL + "/api/Banners"

        try{
            const response = await fetch(url, requestOptions);

            if(!response.ok) {
                reportMessage("Failed to fetch banners", {notify: true, options: {url: url}});
                return;
            }

            return await response.json();
        }catch(e){
            reportException(e, {notify: true, options: {url: url}});
        }
    }

    async reportClick(bannerId){
        const jwt = await accountService.getCognitoJwt();

        const requestOptions = {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
        };

        try{
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Banners/add-click?id=" + bannerId,
                requestOptions
            );

            if(!response.ok) {
                reportMessage("Failed to add click");
            }
        }catch(e){
            reportException(e);
        }
    }
}

export default new BannerService();