import React, {Fragment} from "react";
import {Box, Typography} from "@mui/material";
import {MobileContainer} from "./Global/Global";
import {useMediaContext} from "../MediaContext";
import Space from "./Basic/Space";
import {useParams, useSearchParams} from "react-router-dom";

export const ErrorPage = () => {
    let params = useParams();
    const id = params.id;

    const messages = {
        paymentHandling: {
            title: "Noget gik galt",
            message: "Håndtering af dit køb fejlede. \nKontakt venligst kundeservice."
        }
    }

    return <Fragment>
        <MobileContainer>
            <Box>
                <Space height="25px"/>
                <Box sx={{display: "flex"}}>
                    <Box>
                        <Typography variant="h4">
                            {messages[id]?.title ?? "Beklager, siden findes ikke"}
                        </Typography>
                        <Typography variant="h5">
                            {messages[id]?.message ?? "Tjek at linket er korrekt og prøv igen"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </MobileContainer>
    </Fragment>
}
