import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/grid";
import "./styles.css";

// import required modules
import {FreeMode, Navigation, Pagination, Zoom} from "swiper";
import {observer} from "mobx-react";
import {Box} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MobileFullSizeImages from "./Create/MobileFullSizeImages";

const MobileGallery = ({gallery}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFullImage = location.pathname.includes('fuldbillede');
  const [fullImage, setFullImage] = useState({index : 0, isOpen: false});
    const handleImageClick = (imageIndex) => {
        setFullImage({index: imageIndex, isOpen: true});
        navigate("fuldbillede");
    }
    return (
        <Box>
            <Swiper style={{
                "--swiper-navigation-color": "#000000",
                "--swiper-pagination-color": "#000000",
            }}
                    zoom={true}
                    spaceBetween={10}
                    navigation={true}
                    pagination={true}
                    modules={[FreeMode, Navigation, Pagination, Zoom]}
                    className="mySwiper2">
                {gallery.map((image,index) =>
                    <SwiperSlide key={`swiperslide-${image.ImageId}`}>
                        <div className="swiper-zoom-container">
                          <img alt={"Annonce billeder"} src={image.Url}
                               onClick={()=>{handleImageClick(index)}}
                               style={{cursor:'pointer', objectFit:'cover'}}
                          />
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
          {
            isFullImage && <MobileFullSizeImages fullImage={fullImage} setFullImage={setFullImage} gallery={gallery}/>
          }

        </Box>
    );
};

export default observer(MobileGallery);