import React, {Fragment, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {categoryStore} from "../../../Stores/CategoryStore";
import css from "./CreatePost.module.css";
import {
    Box,
    Button,
    Checkbox, FormControl, InputLabel,
    Link, MenuItem, Radio, Select,
    styled,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {MobileContainer, SizedBox} from "../../Global/Global";
import DropDown from "../../Form/DropDown";
import {useForm} from "react-hook-form";
import InputText from "../../Form/InputText";
import InputTextArea from "../../Form/InputTextArea";
import InputZip from "../../Form/InputZip";
import {zipToCity} from "../../../Constants/Constants";
import InputPrice from "../../Form/InputPrice";
import PostService from "../../../Services/PostsService";
import InputFiles from "../../Form/InputFiles";
import {FaTrash, FaCheck} from "react-icons/fa";
import Compressor from "compressorjs";
import {compressAccurately} from "image-conversion";
import {accountStore} from "../../../Stores/AccountStore";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import useSnackbar from "../../Snackbar/SnackbarHook";
import Skeleton from "@mui/material/Skeleton";
import accountService from "../../../Services/AccountService";
import Space from "../../Basic/Space";
import Divider from "../../Basic/Divider";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Column from "../../Basic/Column";
import Row, {CenterRow} from "../../Basic/Row";
import {useMediaContext} from "../../../MediaContext";
import {reportException} from "../../../Utilities/sentry";
import {useNavigate} from "react-router-dom";
import { useConstantsContext } from "../../../Contexts/ConstantsContext";
import PostsService from "../../../Services/PostsService";
import {getUnixTime} from "../../../Utilities/dateUtils";
import CategorySelector from "./CategorySelector";
import {Section} from "./Section";
import {topBarHeight} from "../../Bars/Mobile/TopBarMobile";
import PictureRender from "../../Shared/Pictures/PictureRender";
import {oreToKroner, roundUpToNearestHundred} from "../../../Utilities/postUtils";

const conditions = {
    Perfekt: "Perfect",
    God: "Good",
    Okay: "Decent",
    Defekt: "Defect",
};

const SkeletonTextField = () => {
    return (
        <Skeleton
            style={{
                width: "100%",
                maxWidth: "20rem",

                borderRadius: "4px",
            }}
            variant="rectangular"
        >
            <TextField fullWidth/>
        </Skeleton>
    );
};

const SkeletonZipField = () => {
    return (
        <div style={{display: "flex"}}>
            <Skeleton variant="rectangular" sx={{borderRadius: "4px"}}>
                <TextField fullWidth sx={{maxWidth: "5rem"}}/>
            </Skeleton>
            <SizedBox width="15px"/>
            <Skeleton variant="rectangular" sx={{borderRadius: "4px"}}>
                <TextField fullWidth sx={{maxWidth: "15rem"}}/>
            </Skeleton>
        </div>
    );
};

const SkeletonPriceField = () => {
    return (
        <Skeleton variant="rectangular" sx={{borderRadius: "4px"}}>
            <TextField fullWidth sx={{maxWidth: "10rem"}}/>
        </Skeleton>
    );
};

const SkeletonImageButton = () => {
    return (
        <Skeleton variant="rectangular" sx={{borderRadius: "4px"}}>
            <Button variant="contained" sx={{textTransform: "unset"}}>
                <label>Tilføj billeder</label>
            </Button>
        </Skeleton>
    )
}

const SkeletonSmallImage = () => <Skeleton
    variant="rectangular"
    style={{
        height: "8rem",
        width: "8rem",
        padding: "0.25rem",
        borderRadius: "4px",
        marginRight: "0.75rem",
        marginBottom: "0.75rem",
    }}
/>

const SkeletonLargeImage = () => <Skeleton variant="rectangular" sx={{
    borderRadius: "4px",
    height: "11rem",
    width: "11rem",
    minHeight: "11rem",
    minWidth: "11rem",
    padding: "0.25rem"
}}/>

const SkeletonImages = ({isUnderSm}) => (
    <div className={css.row} style={isUnderSm ? {display: "block"} : {}}>
        <SkeletonLargeImage/>
        <Space width="0.75rem" height="0.75rem"/>
        <div
            className={css.imgsContainer}
            style={isUnderSm ? {maxWidth: "20rem"} : {}}
        >
            <SkeletonSmallImage/>
            <SkeletonSmallImage/>
        </div>
    </div>
);

const EditSkeleton = ({isUnderSm}) => (
    <React.Fragment>
        <MobileContainer>
            <h1>Rediger annonce</h1>
        </MobileContainer>
        <Section title="Fortæl om varen">
            <div style={{padding: "20px"}}>
                <div className={css.row} style={isUnderSm ? {display: "block"} : {}}>
                    {/* Column Left */}
                    <div className={css.column}>
                        <SkeletonTextField/>
                        <SizedBox height="15px"/>
                        <SkeletonTextField/>
                        <SizedBox height="15px"/>
                        <SkeletonTextField/>
                        <SizedBox height="15px"/>
                        <SkeletonTextField/>
                    </div>

                    <SizedBox width="15px" height="15px"/>

                    {/* Column Right */}
                    <div className={css.column}>
                        <SkeletonTextField/>
                        <SizedBox height="15px"/>
                        <SkeletonZipField/>
                        <SizedBox height="15px"/>
                        <SkeletonPriceField/>
                    </div>
                </div>
                <Space height="20px"/>
                <SkeletonImageButton/>
                <SizedBox height="15px"/>
                <SkeletonImages isUnderSm={isUnderSm}/>
            </div>
        </Section>
    </React.Fragment>
);

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    fontSize: "1em",
    verticalAlign: "middle",
    paddingBottom: "3px",
    paddingLeft: "4px",
    cursor: "pointer",
}));

const FloatingTag = ({discount, bgColor}) => {
    return (
        <Box sx={{
            // The price card itself
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            minHeight: "31px",
            padding: "5px 15px 5px 25px",
            position: "absolute",
            top: "-20px",
            right: "-60px",
            borderRadius: "10px",
            background: "#fff",
            boxShadow: "4px 4px 2px -2px rgba(20,102,20,.3)",
            transform: "rotate(-30deg)",
            zIndex: "1",

            // The triangle
            "&::before": {
                content: "''",
                width: "34px",
                height: "34px",
                transform: " rotate(-45deg)",
                position: "absolute",
                left: "-10px",
                borderRadius: "10px",
                background: "#fff",
            },

            // The cut-out circle
            "&::after": {
                content: "''",
                width: "12px",
                height: "12px",
                borderRadius: "100%",
                position: "absolute",
                background: bgColor,
                left: "0px",
                boxShadow: "inset 2px 2px 2px rgba(20,102,20,.3)",
            }
        }}>
            {/* The content of the price card */}
            <Row sx={{
                alignItems: "center",
                zIndex: "1",
            }}>
                <Column>
                    <Typography sx={{
                        color: "#545454",
                        fontSize: "22px",
                        lineHeight: "0.9",
                    }}>
                        Spar
                    </Typography>
                    <Typography sx={{
                        lineHeight: "0.9",
                        fontSize: "30px",
                        color: "#545454",
                        height: "fit-content",
                        fontWeight: "bold",
                    }}>{discount}%</Typography>
                </Column>
            </Row>
        </Box>
    );

}

// Used to show the different post tiers: basic, premium etc
const TierCard = ({
    tier,
    selected,
    onClick,
    sx
}) => {
    const theme = useTheme();
    const showDiscount = tier.Discount > 0;

    return <Box sx={{position: "relative", ...sx}}>
        {/* Background box for when opacity < 1 */}
        <Box
            sx={{
                backgroundColor: theme.palette.grey["100"],
                height: "100%",
                width: "100%",
                position: "absolute",
                borderRadius: "5px",
                top: "0px",
            }}>
        </Box>

        {/* Actual TierCard */}
        <Box
            style={{
                border: "1px solid black",
                borderRadius: "5px",
                textAlign: "center",
                width: "250px",
                alignSelf: "end",
                backgroundColor: "white",
                opacity: tier.Disabled ? "0.5" : "1",
                pointerEvents: tier.Disabled ? "none" : "auto",
                position: "relative",
                cursor: "pointer",
                borderColor: theme.palette.grey["500"],
            }}
            sx={selected
                ? {boxShadow: `0 0 5px 6px ${tier.Color || "#9e9e9e"}`}
                : {}
            }
            onClick={tier.Disabled ? () => {} : onClick}
        >
            {/* Colored top box */}
            <Box
                sx={{
                    backgroundColor: tier.Color || "white",
                    padding: "10px",
                    borderRadius: "5px 5px 0 0",
                }}
            >
                <Typography sx={{fontWeight: "bold"}}>{tier.Name.toUpperCase()}</Typography>
                <Space height={"5px"}/>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    verticalAlign: "top",
                }}>
                    <Typography
                        sx={{fontSize: "50px", lineHeight: "50px"}}
                    >{tier.Price}</Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: "50px",
                        position: "relative",
                        top: "12px",
                    }}>kr.</Typography>
                </Box>
                {showDiscount && (
                    <React.Fragment>
                        <Space height="2px"/>
                        <Typography sx={{
                            fontStyle: "italic",
                        }}>
                            Normalpris: {tier.OriginalPrice} kr.</Typography>
                    </React.Fragment>
                )}
                <Space height={"5px"}/>
                <Column>
                    <Row sx={{justifyContent: "center", height: "45px"}}>
                        <Typography sx={{fontSize: "35px"}}>
                            {tier.Boost}
                        </Typography>
                        <Typography sx={{fontSize: "40px"}}>
                            ×
                        </Typography>
                    </Row>
                    <Typography>
                        så mange visninger
                    </Typography>
                </Column>
            </Box>

             {/* Benefits */}
             {tier.Features.map((feature, index) => {
                 return <Box key={`benefit-${tier.Name}-${feature.Id}`}>

                     {index === 0 && <Divider />}
                     <Typography style={{ padding: "15px 30px" }}>
                         {feature.Description}
                     </Typography>
                     <Divider />
                 </Box>
             })}

           <Box sx={{
               minHeight: "100px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center"
           }}>
               <Button
                   sx={{
                       margin: "20px",
                       textTransform: "unset",
                   }}
                   color="jagtred"
                   onClick={tier.Disabled ? () => {} : onClick}
                   variant={selected ? "contained" : "outlined"}
               >
                   {tier.Paid ? "Købt" : `Vælg ${tier.Name.toLowerCase()}`}
               </Button>
           </Box>
        </Box>

        {/* Floating tag */}
        {showDiscount && (
            <FloatingTag
                discount={tier.Discount}
                bgColor={tier.Color}
            />
        )}
    </Box>
}

const LastTierCard = ({tier, selected, onClick, sx}) => {
    const theme = useTheme();

    return <Box
        data-testid="last-tier"
        sx={{
            minHeight: "40px",
            margin: "10px 7px",
            border: "1px solid black",
            cursor: "pointer",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            backgroundColor: "white",
            borderColor: theme.palette.grey["500"],
            boxShadow: selected ? `0px 0px 5px 6px ${"#f5d7b3"}` : undefined,
            opacity: tier.Disabled ? "0.5" : "1",
            pointerEvents: tier.Disabled ? "none" : "auto",
            ...sx
        }}
        onClick={onClick}
    >
        {/* If the last tiers does not have any features */}
        {tier.Features.length < 1 && (
            <Typography >
                Nej tak, jeg vælger {tier.Name}annonce uden tilvalg til {tier.Price} kr.
            </Typography>
        )}

        {/* If the last tier has features */}
        {tier.Features.length > 0 && (
            <Column>
                <Typography>
                    {"Nej tak, jeg vælger " + tier.Name + "annonce til " + tier.Price + " kr."}
                </Typography>

                {tier.Features.map((feature, index) => (
                    <Typography sx={{fontSize: "14px"}} key={index}>
                        {"• " + feature.Description}
                    </Typography>
                ))}
            </Column>
        )}
    </Box>
}

// Used to show the different tiers: basic, premium etc. on mobile
const TierRow = ({tier, checked, onClick, sx}) => {
    const theme = useTheme();
    const showDiscount = tier.Discount > 0;

    return <Column
        sx={{
            cursor: "pointer",
            backgroundColor: tier.Color,
        }}
        onClick={tier.Disabled ? () => {} : onClick}
    >
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                opacity: tier.Disabled ? "0.5" : "1",
                pointerEvents: tier.Disabled ? "none" : "auto",
            }}
        >
            <Box sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <Checkbox
                    sx={{height: "fit-content"}}
                    checked={checked}
                />
                <Space width="5px"/>
                <Box>
                    <Typography sx={{fontWeight: "bold"}}>
                        {tier.Name.toUpperFirst()}
                    </Typography>

                    {tier.Boost > 1 && (
                        <Typography
                            sx={{color: theme.palette.grey["700"]}}
                        >
                            {"• " + tier.Boost + "x visninger"}
                        </Typography>
                    )}

                    {tier.Features.map((feature, index) => {
                        return <Typography
                            sx={{color: theme.palette.grey["700"]}}
                        >
                            {"• " + feature.Description}
                        </Typography>
                    }, "")}

                </Box>
            </Box>
            <Space width="25px"/>
            <Box>
                {showDiscount && (
                    <Typography sx={{
                        textDecoration: "line-through",
                    }}>
                        {tier.OriginalPrice} kr.
                    </Typography>
                )}
                <Typography
                    sx={{
                        whiteSpace: "nowrap",
                        fontWeight: "bold"
                    }}>{tier.Paid ? "Betalt" : `${tier.Price} kr.`}
                </Typography>
            </Box>
        </Box>
    </Column>
}

const PaymentItem = ({children, onClick}) => {
    return <Box
        sx={{
            borderRadius: "5px",
            border: "1px solid #e0e0e0",
            width: "calc(100% - 20px)",
            margin: "10px 10px 0 10px",
            padding: "10px 20px",
            boxSizing: "border-box",
            bgcolor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
        }}
        onClick={onClick}
    >
        {children}
    </Box>
}

const findCategoryById = (id) => {
    return categoryStore.categories.find((category) => category.CategoryId == id)
}

const findSubcategoryByName = (category, name) => {
    return category.Subcategories.find((subcategory) => subcategory.Name == name)
}

const ErrorText = ({children}) => {
    const theme = useTheme();

    return <Typography sx={{
        padding: "5px 0px 0px 20px",
        color: theme.palette.error.main
    }}>
        {children}
    </Typography>
}

function CreatePost() {
    const theme = useTheme();
    const param = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const mediaContext = useMediaContext();
    const constantsContext = useConstantsContext();
    const isMobile = mediaContext.isMobile;
    const infoSectionRef = useRef(null);
    const isUnderImages = useMediaQuery('(max-width:885px)');
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        register,
        setValue,
        formState: {errors},
        handleSubmit,
        unregister,
    } = useForm();

    const [selectedTier, _setSelectedTier] = useState();

    const setSelectedTier = (id, tiers = shownTiers) => {
        const tier = tiers?.find(tier => tier.Id == id);

        if (id == null) {
            _setSelectedTier(null);
        } else {
            _setSelectedTier({
                id: id,
                name: tier?.Name,
                price: tier?.Price,
                paid: tier?.Paid
            });
        }

        setValue("tier", id, {shouldValidate: id != null});
    }
    const [shownTiers, setShownTiers] = useState(); // What tier options to show and with what parameters
    const lastTier = shownTiers?.at(-1);

    const [selectedCategory, _setSelectedCategory] = useState();  // A json object with all the info from the backend
    const [selectedSubcategory, _setSelectedSubcategory] = useState();  // A json object with all the info from the backend

    const [subcategories, setSubcategories] = useState(); // Key value pairs of subcategory name and id

    // The number of days the users wants the post to be active
    const [daysActive, setDaysActive] = useState(90);

    // Use ignoreSubcat when setting category and subcategory simultaneously
    const onCategoryClick = (id, ignoreSubcat = false) => {
        setPopupIsOpen(false)

        // Find the category object from the id
        const category = findCategoryById(id)

        // If the selected category requires mit id validation, open the popup
        if (category.MitIdRequired) {
            if (!accountStore.user.mitIdValidated) {
                setPopupIsOpen(true)
            }
        }

        setSelectedCategory(category, ignoreSubcat);
        return category
    }

    const onSubcategoryClick = (category, name) => {
        const subcategory = findSubcategoryByName(category, name)
        setSelectedSubcategory(subcategory);
    }

    // Use ignoreSubcategory when setting category and subcategory simultaneously
    const setSelectedCategory = (category, ignoreSubcat = false) => {
        const subcategories = category.Subcategories.reduce((obj, subcategory) => {
            obj[subcategory.Name] = subcategory.SubcategoryId;
            return obj;
        }, {});

        if (!ignoreSubcat) {
            if (category.Subcategories.length == 1) {
                // If there is only one subcategory, select it
                setSelectedSubcategory(category.Subcategories[0]);
            } else {
                // Else, do not select any subcategory
                setSelectedSubcategory(null);
            }
        }

        _setSelectedCategory(category);
        setSubcategories(subcategories);
    }

    const setSelectedSubcategory = (subcategory) => {
        let newTiers = null;

        if (subcategory != null) {

            if (subcategory.Tiers.length == 0) {
                return;
            }

            setShownTiers(getDefaultTiers(subcategory))
        }
        setSelectedTier(newTiers?.[0]?.["Id"], newTiers);
        _setSelectedSubcategory(subcategory);
    }

    const setSelectedCategoryAndSubcategory = (categoryId, subcategoryName) => {
        const category = onCategoryClick(categoryId,  true)
        onSubcategoryClick(category, subcategoryName)
    }

    const scrollToInfoSection = () => {
        // Compensate for the height of the top bar
        const offset = isMobile ? topBarHeight + 10 : 10
        const element = infoSectionRef.current.getBoundingClientRect().top + window.scrollY;
        const position = element - offset;

        // Scroll to the calculated position
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    }

    const [images, setImages] = useState([]); // Can contain both URLs and file objects
    const [isUploading, setIsUploading] = useState(false); // While uploading a post
    const [zip, setZip] = useState(""); // Only for "opret-annonce"
    const [oldPost, setOldPost] = useState({}); // Only for "rediger-annonce"
    const [isFetching, setIsFetching] = useState(true); // While fetching data from server

    const [paymentType, setPaymentType] = useState(""); // Betalingskort or Mobilepay
    const handlePaymentChange = (type) => {
        setPaymentType(type);
        setValue("payment", type, {shouldValidate: true})
    }

    const [popupIsOpen, setPopupIsOpen] = useState(false);
    useEffect(() => {
        const validatedParam = searchParams.get("validated");
        if (!!accountStore.user.mitIdValidated) {
            if (!!validatedParam) {
                const isValidated = accountStore.user.mitIdValidated;
                if (isValidated) {
                    if (validatedParam === "true") {
                        snackbar.handleOpen(
                            "MitID validering gennemført", "success"
                        );
                    }
                    if (validatedParam === "validated") {
                        snackbar.handleOpen(
                            "Allerede MitID valideret", "success"
                        );
                    }
                } else {
                    snackbar.handleOpen(
                        "MitID validering mislykkedes", "error"
                    );
                }
            }
        }
    }, [accountStore.user.mitIdValidated]);

    const path = location.pathname; // E.g. "/rediger-annonce/1"
    const isEdit = path.split("/")[1] == "rediger-annonce";

    useEffect(() => {
        // Fetch user zip code for "opret-annonce"
        const fetchZip = async () => {
            const zip = await accountService.getUserZipCode()
            setZip(zip ?? "");
        }

        const fetchPost = async () => {
            const fetchedPost = await PostService.getPostForEdit(param.postId);

            // If the post could not be fetched
            if (fetchedPost == null) {
                return setOldPost(null);
            }

            const category = findCategoryById(fetchedPost.Subcategory.CategoryId);
            const subcategory = findSubcategoryByName(category, fetchedPost.Subcategory.Name);

            setSelectedCategory(category);
            setSelectedSubcategory(subcategory);

            let shownTiers;

            // When a post is first created, or when it is expired, the default tiers are shown.
            // However, if the post is live, the tiers are adjusted based on the post's
            // current tier and the remaining time of it
            const showDefaultTiers = fetchedPost.Expired;

            if (showDefaultTiers) {
                shownTiers = getDefaultTiers(subcategory)
            }

            if (!showDefaultTiers) {
                shownTiers = getLiveTiers(subcategory, fetchedPost)
            }

            setShownTiers(shownTiers);

            // Sort images based on order
            fetchedPost.Images.sort((a, b) => {
                if (a.Order < b.Order) return -1;
                else return 1;
            });

            const urls = fetchedPost.Images.map((img) => {
                return img.Url;
            });

            setImages(urls);
            setOldPost(fetchedPost);

            // If the post is expired or has no tier, select the most premium
            // tier as default, otherwise use the posts existing tier
            const tierId = fetchedPost.Expired || fetchedPost.Tier == null
                ? shownTiers?.[0]?.Id
                : fetchedPost.Tier.Id;

            setSelectedTier(tierId, shownTiers);

            return shownTiers;
        };

        const effect = async () => {
            await accountStore.getCurrentUser();
            let tiers;

            if (!isEdit) {
                await fetchZip();
            }

            if (isEdit) {
                tiers = await fetchPost();
            }

            // If the user navigates back from the payment page,
            // we need to remember the selected tier.
            const tier = searchParams.get("tier");
            if (tier != null) {
                setSelectedTier(tier, tiers);
            }

            setIsFetching(false);
        }

        // Wait for the category store to be initialized
        if (!categoryStore.isInitialized){
            return;
        }

        effect();

    }, [param.postId, categoryStore.isInitialized]);

    // With "default" I mean when the post is first created or expired - then the prices are fixed
    const getDefaultTiers = (subcategory) => {
        return subcategory.Tiers.reduce((acc, tier) => {

            const priceNow = Math.max(0, oreToKroner(tier.PricePerDay * daysActive));
            const priceBefore = Math.max(0, oreToKroner(tier.OriginalPricePerDay * daysActive));
            const discount = 100 - Math.ceil((priceNow / priceBefore) * 100);

            acc.push({
                Disabled: false,  // No tiers are disabled.
                Paid: false,      // No tiers are marked as paid.
                Price: priceNow,
                OriginalPrice: priceBefore,
                ...tier,           // Include all existing properties of the tier.
                // Overwrite certain properties if necessary
                Discount: discount,
            });
            return acc;
        }, []);
    }

    // With "live" I mean when the post is created and not expired - then the prices are dynamic
    const getLiveTiers = (subcategory, post) => {
        // Subtract the cost of any previously purchased tier
        let dailyDeductible = 0;
        let originalDailyDeductible = 0;

        return subcategory.Tiers.reduce((acc, tier) => {
            let features = tier.Features;
            let disabled = false;
            let paid = false;

            if (post.Tier != null) {
                disabled = tier.Priority > post.Tier.Priority;
                paid = tier.Id === post.Tier.Id;

                // Copy the actual features of the post, in case they have changed since purchase
                if (tier.Id == post.Tier.Id) {
                    features = post.Tier.Features;
                }

                dailyDeductible = post.Tier.PricePerDay;
                originalDailyDeductible = post.Tier.OriginalPricePerDay;
            }

            const priceNow = Math.max(0, oreToKroner(( tier.PricePerDay - dailyDeductible) * post.DaysLeft));
            const priceBefore = Math.max(0, oreToKroner((tier.OriginalPricePerDay - originalDailyDeductible) * post.DaysLeft));
            const discount = 100 - Math.ceil((priceNow / priceBefore) * 100);

            acc.push({
                Disabled: disabled,
                Paid: paid,
                Price: priceNow,
                ...tier,  // Copy all the tier properties
                // Overwrite certain properties if necessary
                Features: features,
                Discount: disabled || paid ? 0 : discount,
                OriginalPrice: disabled || paid ? 0 : priceBefore,
            });
            return acc;
        }, []);
    }

    useEffect(() => {
        if (selectedSubcategory) {
            setShownTiers(getDefaultTiers(selectedSubcategory))
        }
    }, [daysActive])

    useEffect(() => {
        // Register post tier field manually
        register(
            "tier",
            {
                validate: async (tier) => {
                    // If no subcategory is selected there is nothing to validate
                    if (selectedSubcategory == null) {
                        return true;
                    }

                    if (tier == null)
                        return "Du skal vælge en pakke";
                }
            }
        );
    }, [selectedSubcategory]);

    useEffect(() => {
        // Register payment field manually
        register(
            "payment",
            {
                validate: async (payment) => {
                    // If a paid tier is selected, a payment method must be selected
                    // unless the selected tier has already been bought
                    if (selectedTier == null || (selectedTier.price > 0  && payment == null && selectedTier.paid == false)) {
                        return "Du skal vælge en betalingsmetode";
                    }
                }
            }
        );
    }, [selectedTier, shownTiers]);

    const onSubmit = async (event) => {
        const post = {};
        let s3links = [];
        const errorMessage = isEdit
            ? "Redigering af annoncen fejlede"
            : "Oprettelse af annoncen fejlede";

        // If the category requires MitID the user has to be validated
        if (selectedCategory.MitIdRequired) {
            if(!accountStore.user.mitIdValidated) {
                snackbar.handleOpen("Mangler MitID validering", "error");
                return setIsUploading(false);
            }
        }

        setIsUploading(true);

        try {
            /*
              Upload the images to AWS S3
              Old images are re-uploaded to get new Urls
              This is because all old URLs are deleted from AWS upon updates in the backend
            */
            for (const [index, image] of images.entries()) {
                let blob = image; // Image to upload

                // If old image
                if (typeof image === "string") {
                    // Download image
                    const response = await fetch(image, { cache: 'no-store' });
                    blob = await response.blob();
                }

                // Get secure URL for S3 upload
                const uploadLink = await PostService.getSignedS3Url();

                // Upload image to S3
                const downloadLink = await PostService.postS3Image(uploadLink, blob);

                s3links.push({
                    url: downloadLink,
                    order: index + 1,
                });
            }
        } catch (e) {
            console.log(e);
            setIsUploading(false);
            reportException(e, {notify: true});
            return snackbar.handleOpen(errorMessage, "error");
        }

        // Transfer information from form to post object
        post.heading = event.title;
        post.price = parseInt(event.price.replace(/\./g, ""));
        post.body = event.description;
        post.zip = event.zipcode;
        post.condition = event.condition;
        post.images = s3links;
        post.tierId = selectedTier.id;
        post.subcategoryId = selectedSubcategory.SubcategoryId;
        post.desiredDaysActive = daysActive;

        // The user id will be retrieved from the token in the backend
        post.userId = "";

        // Send request to backend
        try {
            let response;

            // "/rediger-annonce"
            if (isEdit) {
                post.postId = oldPost.PostId;
                response = await PostsService.updatePost(post, paymentType);
            }

            // "/opret-annonce"
            if (!isEdit) {
                response = await PostsService.createPost(post, paymentType);
            }

            const url = new URL(response.url);
            const onPage = url.hostname.includes("jagtbasen.dk") || url.hostname.includes("localhost");

            // If the user is redirected on jagtbasen
            if (onPage) {
                navigate(url.pathname + url.search);
            }

            // If the user is redirected to another domain
            if (!onPage) {
                window.location.href = url.href;
            }
        } catch (e) {
            console.log(e);
            reportException(e, {notify: true});
            snackbar.handleOpen(errorMessage, "error");
        }

        setIsUploading(false);
    };

    // Return null if an exception happened
    const processImage = async (image) => {
        try {
            image = await compressAndConvert(image);

            return image;
        } catch (e) {
            reportException(e);
            snackbar.handleOpen("Fejl med billede", "error");
        }
    };

    const compressAndConvert = async (image) => {
        // Scale the image to a max width/height of 1000 pixel
        image = await new Promise((resolve, reject) => {
            // Try catch is only there for SonarLint
            try {
                new Compressor(image, {
                    quality: 1,
                    maxHeight: 1000,
                    maxWidth: 1000,
                    convertSize: 0,
                    convertTypes: "image/*",
                    success: resolve,
                    error: reject,
                });
            } catch (e) {
                reportException(e);
            }
        });

        // Make sure the image in no bigger than 300 KB
        return await compressAccurately(image, {
            size: 300,
            accuracy: 0.95,
            type: "image/jpeg",
        });
    };

    // If the old post could not be fetched, show error message
    if (isEdit && !isFetching && oldPost == null) {
        return <Fragment>
            <MobileContainer>
                <Space height="10px"/>
                <Typography variant="h4">Beklager, annoncen kunne ikke findes</Typography>
                <Typography variant="h5">Prøv igen senere</Typography>
            </MobileContainer>
        </Fragment>
    }

    if (isEdit && isFetching) {
        return <EditSkeleton isUnderMobile={isMobile}/>;
    }

    return (
        <React.Fragment>
            <MobileContainer>
                {!isEdit
                    ? <h1>Opret annonce</h1>
                    : !oldPost.Paid
                        ? <h1>Opret annonce</h1>
                        : <h1>Rediger annonce</h1>
                }
            </MobileContainer>
            {/* Inspired by https://www.youtube.com/watch?v=bU_eq8qyjic&t=5s */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Prevent the "Enter" key from triggering form submission in single-line fields
                        const tagName = event.target.tagName.toLowerCase();
                        const isTextArea = tagName === 'textarea';


                        if (!isTextArea) {
                            event.preventDefault();
                        }
                    }
                }}
            >
                {!isEdit && (
                    <Section title="Find kategori">
                        <CategorySelector onClick={(item) => {
                            setSelectedCategoryAndSubcategory(item.category.id, item.subcategory.name)
                            scrollToInfoSection()
                        }}/>
                    </Section>
                )}
                <Section ref={infoSectionRef} title="Fortæl om varen">
                    <Box sx={{padding: "20px"}}>
                        {/* Row */}
                        <Box
                            className={css.row}
                            style={isMobile ? {display: "block"} : {}}
                        >
                            {/* Column Left */}
                            <div className={css.column}>
                                <InputText
                                    id="title"
                                    defaultValue={isEdit ? oldPost.Heading : ""}
                                    label="Overskrift"
                                    disabled={isUploading}
                                    register={register}
                                    options={{
                                        required: "Påkrævet",
                                        validate: (text) => {
                                            if (text.length > 45) return `For langt: ${text.length}/45`;
                                        },
                                    }}
                                    errors={errors}
                                />

                                <SizedBox height="15px"/>

                                <Box className={css.myselect}>
                                    <DropDown
                                        id="category"
                                        label="Kategori"
                                        items={categoryStore.categoryNameToId}
                                        register={register}
                                        disabled={isUploading || isEdit}
                                        selection={selectedCategory?.Name ?? ""}
                                        unregister={unregister}
                                        options={{required: "Påkrævet"}}
                                        errors={errors}
                                        callback={(item) => {
                                            onCategoryClick(item.value)
                                        }}
                                    />
                                    {popupIsOpen && (
                                        <div className={css.mypopup}>
                                            <Typography>
                                                {"OBS: For at oprette våbenannoncer kræves "}
                                                <Link color="inherit"
                                                      href={`${process.env.REACT_APP_API_URL}/api/MitID/${accountStore.user.id}?redirect=opret-annonce`}>
                                                    MitID validering
                                                </Link>
                                            </Typography>
                                        </div>
                                    )}
                                </Box>
                                <SizedBox height="15px"/>

                                {/* Do not show sub category before main category is selected */}
                                {/* Do not show sub category when there are none */}
                                {selectedCategory != null && selectedCategory.Subcategories.length > 1 && (
                                    <Fragment>
                                        <DropDown
                                            id="subcategory"
                                            label="Underkategori"
                                            disabled={isUploading || isEdit || popupIsOpen}
                                            selection={selectedSubcategory?.Name ?? ""}
                                            items={subcategories}
                                            register={register}
                                            unregister={unregister}
                                            options={{
                                                validate: (value) => {
                                                    if (!Object.values(subcategories).includes(parseInt(value)))
                                                        return "Påkrævet";
                                                },
                                            }}
                                            errors={errors}
                                            callback={(item) => {
                                                onSubcategoryClick(selectedCategory, item.key)
                                            }}
                                            active={selectedCategory?.Subcategories.length > 1 ?? false}
                                        />

                                        <SizedBox height="15px"/>
                                    </Fragment>
                                )}

                                <DropDown
                                    id="condition"
                                    label="Stand"
                                    disabled={isUploading}
                                    items={conditions}
                                    register={register}
                                    unregister={unregister}
                                    options={{
                                        required: "Påkrævet",
                                    }}
                                    errors={errors}
                                    defaultValue={isEdit ? oldPost.Condition : ""}
                                />
                            </div>

                            <SizedBox width="15px" height="15px"/>

                            {/* Column right */}
                            <Box className={css.column}>
                                <InputTextArea
                                    id="description"
                                    label="Beskrivelse"
                                    disabled={isUploading}
                                    register={register}
                                    options={{
                                        required: "Påkrævet",
                                        validate: (text) => {
                                            if (text.length > 1000) return `For langt: ${text.length}/1000`;
                                        },
                                    }}
                                    errors={errors}
                                    defaultValue={isEdit ? oldPost.Body : ""}
                                />

                                <SizedBox height="15px"/>

                                {!isFetching
                                    ? <InputZip
                                        id="zipcode"
                                        label="Postnr."
                                        register={register}
                                        disabled={isUploading}
                                        options={{
                                            required: "Påkrævet",
                                            validate: (zip) => {
                                                if (zipToCity["DK"][zip] == null) return "Ugyldigt";
                                            },
                                        }}
                                        errors={errors}
                                        defaultValue={isEdit ? oldPost.Zip : zip}
                                    />
                                    : <SkeletonZipField/>
                                }

                                <SizedBox height="15px"/>

                                <InputPrice
                                    id="price"
                                    label="Pris"
                                    disabled={isUploading}
                                    register={register}
                                    options={{
                                        required: "Påkrævet",
                                    }}
                                    errors={errors}
                                    defaultValue={isEdit ? oldPost.Price.toString() : ""}
                                />
                            </Box>
                        </Box>

                        <Space height="20px"/>

                        {/* IMAGES */}
                        <InputFiles
                            id="images"
                            disable={images.length >= 8 || isUploading}
                            register={register}
                            maxSize={50}
                            options={{
                                validate: (_) => {
                                    if (images.length < 1) return "Påkrævet";
                                },
                            }}
                            errors={errors}
                            callback={async (files) => {
                                // Compress images
                                files = await Promise.all(
                                    Array.from(files).map(async (file) => {
                                        return await processImage(file);
                                    })
                                );

                                // Remove null - could not get .filter to work for some reason
                                const urls = [];
                                let removedFile = false;
                                files.forEach((file) => {
                                    if (file != null) urls.push(URL.createObjectURL(file));
                                    else removedFile = true;
                                });

                                // If a file was removed, notify user
                                if (removedFile) snackbar.handleOpen("Fejl med billede", "error");

                                // Push new images to existing
                                let copy = [...images];
                                copy.push(...urls);
                                copy = copy.slice(0, 8);
                                setImages(copy);
                            }}
                        />

                        {/*  Image previews */}
                        {images.length > 0 && (
                            <Box
                                className={css.row}
                                style={isMobile ? {display: "block"} : {}}
                            >
                                {/* Primary image column */}
                                <div>
                                    <div className={`${css.imgBox} ${css.primaryImgBox}`}>
                                        <img
                                            src={
                                                typeof images[0] === "string"
                                                    ? images[0]
                                                    : URL.createObjectURL(images[0])
                                            }
                                            className={`${css.img} ${css.primaryImg}`}
                                        />
                                    </div>
                                    <div className={css.primaryText}>
                                        <b>Primært billede.</b>
                                        <br/> Vises i søgeresultatet på Jagtbasen
                                    </div>

                                    {isMobile && (
                                        <Space height={"20px"}/>
                                    )}
                                </div>

                                {/* All images column */}
                                <div
                                    className={css.imgsContainer}
                                    style={isMobile ? {maxWidth: "20rem"} : {}}
                                >
                                    {images.map((image, index) => (
                                        <div
                                            className={`${css.imgBox} ${index == 0 && css.firstImgBox}`}
                                            key={`create-post-${image}`}
                                        >
                                            <img
                                                src={
                                                    typeof image === "string"
                                                        ? image
                                                        : URL.createObjectURL(image)
                                                }
                                                className={css.img}
                                            />

                                            {/* Image buttons like "delete" */}
                                            <div className={css.imgButtons}>
                                                {/* Hide check button on first image */}
                                                {index != 0 && (
                                                    <FaCheck
                                                        className={css.imgButton}
                                                        onClick={(_) => {
                                                            // Disable when loading
                                                            if (isUploading) return;

                                                            let copy = [...images];
                                                            const tmp = copy[0];
                                                            copy[0] = copy[index];
                                                            copy[index] = tmp;
                                                            setImages(copy);
                                                        }}
                                                        color="white"
                                                        style={{
                                                            top: "6rem",
                                                            left: "0.5rem",
                                                            backgroundColor: "green",
                                                        }}
                                                    />
                                                )}
                                                <FaTrash
                                                    className={css.imgButton}
                                                    onClick={(_) => {
                                                        // Disable when loading
                                                        if (isUploading) return;

                                                        let copy = [...images];
                                                        copy.splice(index, 1);
                                                        setImages(copy);
                                                    }}
                                                    color="white"
                                                    style={{
                                                        top: "6rem",
                                                        left: "6rem",
                                                        backgroundColor: "red",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Box>
                        )}
                    </Box>
                </Section>

                {selectedSubcategory != null  && (
                    <Section title="Sælg varen">
                        {((!isEdit || oldPost.Expired) && accountStore.user.isBusinessUser) && (
                            <Box style={isMobile ? {padding: "20px 0 20px 20px"} : {padding: "20px 0 0 27px"}}>
                                <FormControl >
                                    <InputLabel id="days-active-label">Dage</InputLabel>
                                    <Select
                                        sx={{
                                            minWidth: "100px",
                                            backgroundColor: "white"
                                        }}
                                        label={"Dage"}
                                        labelId="days-active-label"
                                        value={daysActive}
                                        onChange={(event) => setDaysActive(event.target.value)}
                                    >
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={180}>180</MenuItem>
                                        <MenuItem value={365}>365</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {/* If there is less than one hour before the post expires */}
                        {(isEdit && !oldPost.Expired && oldPost.SecondsLeft < 3600) && (
                            <Typography sx={{
                                padding: isMobile ? "15px 20px" : "20px 20px 20px 20px",
                                color: theme.palette.grey[700]
                            }}>
                                Du kan ikke ændre pakken, da annoncen udløber om under en time.
                            </Typography>
                        )}

                        {/* If the post has at least one hour left before expiring */}
                        {(!isEdit || oldPost.Expired || oldPost.SecondsLeft >= 3600) && (
                            <Fragment>
                                {errors["tier"] && (
                                    <ErrorText>
                                        {errors["tier"].message}
                                    </ErrorText>
                                )}

                                {/* Mobile sælg varen */}
                                {isMobile && (
                                    <Box>
                                        {shownTiers.map((tier) => (
                                            <TierRow
                                                key={tier.Name}
                                                tier={tier}
                                                checked={selectedTier?.id === tier.Id}
                                                onClick={() => setSelectedTier(tier.Id)}
                                            />
                                        ))}
                                    </Box>
                                )}

                                {/* Web sælg varen */}
                                {!isMobile && (
                                    <div style={{
                                        display: "flex",
                                        padding: "20px",
                                        alignItems: "end",
                                        width: "fit-content"
                                    }}>
                                        <Column>
                                            <Row sx={{alignItems: "end"}}>
                                                {shownTiers.map((tier, index) => {

                                                    // Do not show the last tier here
                                                    if (index == shownTiers.length - 1) {
                                                        return
                                                    }

                                                    return <Fragment key={tier.Name}>
                                                        <Space width={"7px"}/>
                                                        <TierCard
                                                            tier={tier}
                                                            selected={selectedTier?.id == tier.Id}
                                                            onClick={() => setSelectedTier(tier.Id)}
                                                        />
                                                        <Space width={"7px"}/>
                                                    </Fragment>
                                                })}
                                            </Row>

                                            <Space height={"5px"}/>

                                            {/* The last tier is shown differently */}
                                            <LastTierCard
                                                tier={lastTier}
                                                selected={selectedTier?.id === lastTier.Id}
                                                onClick={() => setSelectedTier(lastTier.Id)}
                                            />

                                        </Column>
                                    </div>
                                )}

                                <Typography sx={{
                                    padding: isMobile ? "10px 20px" : "0px 20px 20px 20px",
                                    color: theme.palette.grey[700]
                                }}>
                                    {(isEdit && !oldPost.Expired)
                                        ? `Bemærk, annoncen udløber om under ${oldPost.DaysLeft > 1 ? `${oldPost.DaysLeft} dage.` : `${Math.ceil((oldPost.EndDate - getUnixTime()) / (60 * 60))} timer.`}`
                                        : `Bemærk, din annonce er aktiv i ${daysActive} dage. Din adresse vil ikke blive vist.`
                                    }

                                </Typography>
                            </Fragment>
                        )}
                    </Section>
                )}

                {selectedTier != null && selectedTier.price > 0 && selectedTier.paid == false && (
                    <Section title="Vælg betalingsform">
                        <Box sx={{padding: "10px 10px"}}>
                            {errors.payment && (
                                <ErrorText>
                                    {errors.payment.message}
                                </ErrorText>
                            )}
                            <PaymentItem onClick={() => handlePaymentChange('CreditCard')}>
                                <CenterRow>
                                    <Radio checked={paymentType === 'CreditCard'}/>
                                    <Typography>Betalingskort</Typography>
                                </CenterRow>
                                {mediaContext.isUnderSm
                                    ? <PictureRender image="payment-credit-card" size={117}/>
                                    : <PictureRender image="payment-credit-card" size={206}/>
                                }
                            </PaymentItem>

                            <PaymentItem onClick={() => handlePaymentChange('MobilePay')}>
                                <CenterRow>
                                    <Radio checked={paymentType === 'MobilePay'}/>
                                    <Typography>MobilePay</Typography>
                                </CenterRow>
                                {mediaContext.isUnderSm
                                    ? <PictureRender image="payment-mobile-pay-filled" size={40}/>
                                    : <PictureRender image="payment-mobile-pay-filled" size={56}/>
                                }
                            </PaymentItem>

                            <Space height="10px"/>
                        </Box>
                    </Section>
                )}

                <SizedBox height="15px"/>

                {/* Load animation */}
                {isUploading && <MobileContainer>
                    <CircularProgress size="2rem"/>
                </MobileContainer>}

                <Space height="10px"/>

                {/* Button when not loading */}
                {!isUploading && (
                    <MobileContainer>
                        <Button
                            className={css.button}
                            variant="contained"
                            color="jagtred"
                            type="submit"
                            id="submit-post"
                        >
                                {!isEdit
                                    ? "Opret annonce"
                                    : !oldPost.Paid
                                        ? "Opret annonce"
                                        : oldPost.Expired
                                            ? "Genindryk annonce"
                                            : "Gem ændringer"
                                }
                        </Button>
                    </MobileContainer>
                )}
            </form>
        </React.Fragment>
    );
}

export default observer(CreatePost);
