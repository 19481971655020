import {reportException} from "./Utilities/sentry";

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);


// Function to register the service worker
export function register() {
    // Check if the browser supports service workers
    if ('serviceWorker' in navigator) {
        // Wait for the window to finish loading
        window.addEventListener('load', () => {
            // Define the URL for our service worker file
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl);
            } else {
                // Is not localhost. Just register service worker
                // Attempt to register the service worker using the specified URL
                navigator.serviceWorker
                    .register(swUrl)
                    .then((registration) => {
                        // Log a success message along with the registration object
                        if(isLocalhost){
                            console.log('Service Worker registered:', registration);
                        }

                        // If cache cleared and service-worker activated reload browser.
                        navigator.serviceWorker.addEventListener('message', event => {
                            if (event.data && event.data.type === 'CACHE_CLEARED') {
                                window.location.reload();
                            }
                        });
                    })
                    .catch((error) => {
                        reportException(error);
                        // If there was a problem, log an error message
                        if(isLocalhost){
                            console.error('Service Worker registration failed:', error);
                        }
                    });
            }
        });
    }
}

function checkValidServiceWorker(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                // Attempt to register the service worker using the specified URL
                navigator.serviceWorker
                    .register(swUrl)
                    .then((registration) => {
                        // Log a success message along with the registration object
                        if(isLocalhost){
                            console.log('Service Worker registered:', registration);
                        }

                        // If cache cleared and service-worker activated reload browser.
                        navigator.serviceWorker.addEventListener('message', event => {
                            if (event.data && event.data.type === 'CACHE_CLEARED') {
                                window.location.reload();
                            }
                        });
                    })
                    .catch((error) => {
                        reportException(error);
                        // If there was a problem, log an error message
                        if(isLocalhost){
                            console.error('Service Worker registration failed:', error);
                        }
                    });
            }
        })
        .catch(() => {
            console.log('No internet connection found. App is running in offline mode.');
        });
}

// Function to unregister the service worker
export function unregister() {
    // Check if the browser supports service workers
    if ('serviceWorker' in navigator) {
        // Wait until the service worker is ready
        navigator.serviceWorker.ready
            .then((registration) => {
                // Unregister the service worker
                registration.unregister();
            })
            .catch((error) => {
                reportException(error);
                // If there was a problem, log an error message
                console.error(error.message);
            });
    }
}
