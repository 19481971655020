import {observer} from "mobx-react";
import {
    Button, Checkbox, FormControl,
    Grid,
    Grow,
    InputLabel, List, ListItem, ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {accountStore} from "../../../Stores/AccountStore";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import {useEffect} from "react";

const HuntingTypesBlock = ({ register, errors, submitForm, id, name, label, chosenHuntType, setChosenHuntType }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    //const [chosenHuntType, setChosenHuntType] = React.useState([]);
    const huntTypes = [
        "Fasanjagt", "Andejagt", "Bukkejagt", "Hjortejagt",
        "Drivjagt", "Udlandsjagt", "Vildsvinejagt", "Gåsejagt", "Rævejagt",
        "Grej & udstyr", "Reguleringsjagt", "Buejagt", "Havjagt", "UVjagt"
    ];

    const handleChosenHuntType = (event) => {
        const {
            target: {value},
        } = event;
        setChosenHuntType(
            // On, autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const selectChosenHuntingTypes = () => {
        let choices = [];
        if (Array.isArray(accountStore.user.huntingChoices))
            choices = accountStore.user.huntingChoices.map((choice) => {
                return choice.hunting.name;
            })
        else
            return
        if (choices.length > 0) {
            setChosenHuntType(choices);
        }
    };

    useEffect(() => {
        selectChosenHuntingTypes()
    }, [accountStore.user.huntingChoices]);

    const handleBlocks = (event, name) => {
        let isActive = !active
        setActive(isActive)
        const eventButtonContent = event.target.innerText;
        if(!!eventButtonContent) {
            if (eventButtonContent.trim() === "GEM") {
                submitForm();
            }
        }
    };

    const [active, setActive] = React.useState(false);
    return (
        <Box>
            {active ?
                <Grow in={active}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} p={2} justifyContent="space-between"
                           alignItems="center">
                        <FormControl fullWidth sx={{maxWidth: "100%"}}>
                            <InputLabel id="demo-multiple-checkbox-label">Jagttype</InputLabel>
                            <Select
                                labelId="huntingType-label"
                                id="huntingType"
                                multiple
                                value={chosenHuntType}
                                onChange={handleChosenHuntType}
                                input={<OutlinedInput label="Jagttype"/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {huntTypes.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={chosenHuntType.indexOf(name) > -1}/>
                                        <ListItemText primary={name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack direction={{xs: 'row', sm: 'column'}} spacing={1}
                               width={matches ? "100%" : undefined} justifyContent="space-between"
                               alignItems="center">
                            <Button name={name + "Cancel"}
                                    variant="contained"
                                    onClick={(event) => handleBlocks(event, name)}
                                    fullWidth={!matches}
                                    color="jagtred">
                                Annuller
                            </Button>
                            <Button name={name + "Save"}
                                    onClick={(event) => handleBlocks(event, name)}
                                    fullWidth={!matches}
                                    variant="contained"
                                    color="jagtyellow">
                                Gem
                            </Button>
                        </Stack>
                    </Stack>
                </Grow>
                :
                matches ?
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "3em",
                        padding: 3
                    }}>
                        <Stack spacing={1}>
                            <Typography sx={{fontWeight: 'bold'}}> {label} </Typography>
                            <List disablePadding>
                                {chosenHuntType.map((hunttype) =>
                                    <ListItem key={hunttype} disablePadding>
                                        <ListItemText primary={hunttype} />
                                    </ListItem>
                                )}
                            </List>
                        </Stack>
                        <IconButton color="jagtred" name={name + "Edit"} onClick={(event) => handleBlocks(event, name)}> <EditIcon/>
                        </IconButton>
                    </Box>
                    :
                    <Grid container direction="row" justifyContent="space-around" alignItems="center" sx={{minHeight: "5em", padding: 3}}>
                        <Grid item sm={3}>
                        <Typography sx={{fontWeight: 'bold'}}> {label} </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <List id="HuntingTypeList">
                                {chosenHuntType.map((hunttype) =>
                                    <ListItem key={hunttype} disablePadding>
                                        <ListItemText primary={hunttype} />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                        <Grid item sm={3} sx={{textAlign: "right"}}>
                        <IconButton color="jagtred" name={name + "Edit"}
                                    onClick={(event) => handleBlocks(event, name)}><EditIcon/></IconButton>
                        </Grid>
                    </Grid>
            }
        </Box>
    )

}

export default observer(HuntingTypesBlock);