class ReportDto{
  ReportDTO(postId,categoryId,userId,reportMessage){
    return {
      reportId: 0,
      postId: postId,
      reportCategoryId: categoryId,
      userId: userId,
      reportMessage: reportMessage,
      reportTime: 12334,
      post: null,
      reportCategory: null
    };
  }
}
export default new ReportDto();

