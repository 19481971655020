import {Auth} from "aws-amplify";
import {reportException, reportMessage} from "../Utilities/sentry";

class AccountService {
    async getSession() {
        try {
            return await Auth.currentSession();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getUserInfo() {
        try {
            const user = await Auth.currentUserInfo();
            return user;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    async loginCognito(username, password) {
        try {
            const cognitoUser = await Auth.signIn(username, password);
            return cognitoUser;
        } catch (err) {
            throw err;
        }

    }

    async getCognitoJwt() {
        try {
            let session = await Auth.currentSession();
            const idToken = session.getIdToken();
            return idToken.getJwtToken();
        } catch (err) {
            return err;
        }
    }

    async getCognitoJwtOrNull() {
        try {
            let session = await Auth.currentSession();
            const idToken = session.getIdToken();
            return idToken.getJwtToken();
        } catch (err) {
            return null;
        }
    }

    async retryGetJwtToken(retries = 2, delay = 1000) {
        for (let i = 0; i < retries; i++) {
            const jwt = await this.getCognitoJwtOrNull();
            if (jwt !== null) {
                return jwt;
            }
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }

    async logOutCognito() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
            throw error;
        }
    }

    async checkUserAtJagtbasenDB(id) {
        try {
            const jwt = await this.getCognitoJwt()
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            }
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/Users/" + id + "/check", requestOptions);
            return await response.json();
        } catch (error) {
            console.log('User is not found', error);
            return false;
        }
    }

    async getUserAtJagtbasenDB() {
        try {
            const jwt = await this.getCognitoJwt()
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            }

            const response = await fetch(process.env.REACT_APP_API_URL + "/api/Users/",requestOptions);
            if(response.status !== 200){
                return false;
            }
            return await response.json();
        } catch (error) {
            console.log('User is not found', error);
            return false;
        }
    }

    async createUserOnJagtbasenDB(user) {
        const jwt = await this.getCognitoJwt()
        const huntingType = {
            "Fasanjagt":1,
            "Andejagt":2,
            "Bukkejagt":3,
            "Hjortejagt":4,
            "Drivjagt":5,
            "Udlandsjagt":6,
            "Vildsvinejagt":7,
            "Gåsejagt":8,
            "Rævejagt":9,
            "Grej & udstyr":10,
            "Reguleringsjagt":11,
            "Buejagt":12,
            "Havjagt":13,
            "UVjagt":14
        }

        const ShootingHand = {
            right: 0,
            left: 1,
            both: 2
        };

        let choices = user.huntingType.map((choice) => {
            return  { "userId" : user.id, "huntingId" : huntingType[choice] }
        })

        const userDTO = user.isBusiness ? JSON.stringify({
            "id": user.id,
            "email": user.email,
            "firstname": user.firstName,
            "lastname": user.lastName,
            "birthtime": user.birthDate.unix(),
            "street": user.address,
            "zip": user.zip,
            "phone": user.phone,
            "ShootingHand": ShootingHand[user.favoriteHand],
            "marketing": user.marketing === true,
            "HuntingChoices": choices,
            "isBusinessUser": user.isBusiness,
            "businessUser": {id: user.id, vatNumber: parseInt(user.vatNumber)}
        }) :  JSON.stringify({
            "id": user.id,
            "email": user.email,
            "firstname": user.firstName,
            "lastname": user.lastName,
            "birthtime": user.birthDate.unix(),
            "street": user.address,
            "zip": user.zip,
            "phone": user.phone,
            "ShootingHand": ShootingHand[user.favoriteHand],
            "marketing": user.marketing === true,
            "HuntingChoices": choices,
            "isBusinessUser": user.isBusiness
        });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append( 'Authorization', `Bearer ${jwt}`)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: userDTO,
            redirect: 'follow'
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/api/Users", requestOptions);
        if (!response.ok) {
            reportMessage("Received status != 200 during creat user on jagtbasen", {notify: true, options: {
                status: response.status,
                statusText: response.statusText,
                response: response.clone().text(),
                post: userDTO,
            }});
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();

    }

    async updateUserOnJagtbasenDB(user) {
        const jwt = await this.getCognitoJwt()
        const huntingType = {
            "Fasanjagt":1,
            "Andejagt":2,
            "Bukkejagt":3,
            "Hjortejagt":4,
            "Drivjagt":5,
            "Udlandsjagt":6,
            "Vildsvinejagt":7,
            "Gåsejagt":8,
            "Rævejagt":9,
            "Grej & udstyr":10,
            "Reguleringsjagt":11,
            "Buejagt":12,
            "Havjagt":13,
            "UVjagt":14
        }

        const ShootingHand = {
            right: 0,
            left: 1,
            both: 2
        };

        let choices = user.huntingType.map((choice) => {
            return  { "userId" : user.id, "huntingId" : huntingType[choice] }
        })

        const userDTO = JSON.stringify({
            "id": user.id,
            "email": user.email,
            "firstname": user.firstName,
            "lastname": user.lastName,
            "birthtime": user.birthDate.unix(),
            "street": user.address,
            "zip": user.zip,
            "phone": user.phone,
            "ShootingHand": ShootingHand[user.favoriteHand],
            "marketing": user.marketing === true,
            "HuntingChoices": choices
        });
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append( 'Authorization', `Bearer ${jwt}`)

        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: userDTO,
            redirect: 'follow'
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/api/Users", requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    }

    async updateUserEmailOnJagtbasenDB(id, email) {
        const jwt = await this.getCognitoJwt()
        const requestBody = JSON.stringify({id: id, email: email});

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append( 'Authorization', `Bearer ${jwt}`)

        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: requestBody,
            redirect: 'follow'
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/api/Users/email", requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    }

    // Retrieves the user's zip code from the backend
    // Returns null if the request fails
    async getUserZipCode() {
        const jwt = await this.getCognitoJwt()
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }

        try{
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Users/zip",
                requestOptions
            );
            if (!response.ok) return null;
            else return await response.json();
        } catch(e) {
            reportException(e);
            console.error(e);
        }
    }

    async forgotPassword(email){
        try {
            const result = await Auth.forgotPassword(email);
        } catch (e) {
            reportException(e);
            throw e;
        }

    }
    async resetPassword(username, code, password){
        try{
            const result = await Auth.forgotPasswordSubmit(username, code, password);
        } catch (e) {
            throw e;
        }
    }

    async uploadUserHuntingTypes(huntingTypes) {
        const jwt = await this.getCognitoJwt()
        const requestBody = JSON.stringify(huntingTypes);
        const requestOptions = {
            method: 'POST',
            body: requestBody,
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Users/huntingform",
                requestOptions
            );
            if (!response.ok){
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
    }
}

export default new AccountService();