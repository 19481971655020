import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import FormHelperText from "@mui/material/FormHelperText";
import {useEffect, useState} from "react";

const SelectLocked = styled(Select)(({theme}) => ({
    ".MuiSelect-select": {
        backgroundColor: theme.palette.grey["300"],
    },
}));

const DropDown = ({
    items,
    register,
    unregister,
    errors,
    id,
    maxWidth,
    label,
    options,
    active = true,
    callback = () => {
    },
    selection,
    disabled = false,
    defaultValue = "",
}) => {
    const [fieldProps, setFieldProps] = useState({ onChange: () => {}, onBlur: () => {}, name: '', ref: null });
    const menuItems = Array.from(Object.entries(items));
    const value = items[selection];

    // As there is both an active and an inactive state
    useEffect(() => {
        if (active && !disabled) {
            const { onChange, onBlur, name, ref } = register(id, options)
            setFieldProps({ onChange, onBlur, name, ref });
        }

        return () => {unregister(id);}
    }, [
        active, // If there are no subcategories, we need to unregister the field
        selection, items  // As the register function will otherwise reference old values in callbacks
    ]);

    if (active) {
        return (
            <FormControl
                style={!!maxWidth ? {maxWidth: maxWidth} : {maxWidth: "20rem"}}
                fullWidth
                error={!!errors[id]}
                disabled={disabled}
            >
                <InputLabel>{label}</InputLabel>
                <Select
                    sx={{backgroundColor: "white"}}
                    fullWidth
                    label={label}
                    value={selection != null && value == null ? "" : value}
                    defaultValue={defaultValue}
                    onChange={(event) => {
                        const value = event.target.value;
                        const key = Object.keys(items).find((key) => items[key] === value);
                        callback({key: key, value: value});
                        fieldProps.onChange(event);
                    }} // assign onChange event
                    onBlur={fieldProps.onBlur} // assign onBlur event
                    name={fieldProps.name} // assign name prop
                    ref={fieldProps.ref}
                >
                    {menuItems.map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {key}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText sx={{margin: "0"}}>{errors[id] && errors[id].message}</FormHelperText>
            </FormControl>
        );
    }

    if (!active) {
        return (
            <FormControl style={{maxWidth: "20rem"}} fullWidth>
                <InputLabel>{label}</InputLabel>
                <SelectLocked
                    fullWidth
                    label={label}
                    defaultValue={defaultValue}
                    disabled={true}
                />
            </FormControl>
        );
    }
};

export default observer(DropDown);
