import {Box, ButtonBase, Container, styled, Typography, useTheme} from "@mui/material";
import Column from "../../Basic/Column";
import React, { Fragment} from "react";
import Row from "../../Basic/Row";
import Space from "../../Basic/Space";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Skeleton from "@mui/material/Skeleton";
import {InfoBox, InReviewOverlay} from "./MyPosts";
import { NavLink, useNavigate } from "react-router-dom";

const StyledTitleBand = styled("div")(({theme}) => ({
    width: "100%",
    height: "50px",
    backgroundColor: theme.palette.grey.A200,
    display: "flex",
    alignItems: "flex-end",
}));

const TitleBand = ({title}) => {
    const theme = useTheme();

    return (
        <StyledTitleBand>
            <Typography
                sx={{fontFamily: theme.typography.h5, paddingLeft: "7px"}}
            >
                {title}
            </Typography>
        </StyledTitleBand>
    );
};

const NoPosts = () => {
    return <React.Fragment>
        <Container>
            <Space height="25px"/>
            <InfoBox>
                <Typography>
                    Du har ingen annoncer. Alle dine annoncer bliver vist på denne side, så snart du har oprettet
                    dem.
                </Typography>
            </InfoBox>
        </Container>
    </React.Fragment>
}

const PostRow = ({post, posts}) => {
    const theme = useTheme();

    // If user has no posts
    if (posts.length == 0)
        return <NoPosts/>

    return (
        <NavLink
            style={{
                textDecoration: "none",
                color: "inherit",
            }}
            to={"/post/" + post.PostId}
        >
            <Row
                sx={{display: "flex", alignItems: "center"}}
            >
                <Space width="5px"></Space>

                {/* IMAGE */}
                <img
                    style={{
                        height: "75px",
                        minHeight: "75px",
                        width: "75px",
                        minWidth: "75px",
                        objectFit: "cover",
                        borderRadius: "4px",
                    }}
                    alt="image"
                    src={post.Images[0].Url}
                />

                <Space width="15px"/>

                {/* TEXT */}
                <Box sx={{
                    position: "relative",
                    overflow: "hidden",
                    width: "100%",
                }}>
                    {/* Any status overlay */}
                    {post.Status == "InReview" && <InReviewOverlay/>}

                    <Column>
                        <Typography
                            align="left"
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {post.Brand != null ? post.Brand + ", " : ""}
                            {post.Heading}, {post.Body}
                        </Typography>
                        <Typography align="left">{post.Price} kr.</Typography>
                        <Row style={{alignItems: "center", fontSize: "12px"}}>
                            <Typography style={{fontSize: "1em"}}>
                                {}

                                {post.Expired
                                    ? "Udløbet"
                                    : `Udløber d. ${post.EndDateStr}`
                                }
                            </Typography>
                            <Space width="5px"/>
                            <Typography style={{fontSize: "1em"}}>|</Typography>
                            <Space width="5px"/>
                            <VisibilityIcon
                                style={{
                                    color: theme.palette.grey["700"],
                                    fontSize: "15px",
                                }}
                            />
                            <Space width="2px"/>
                            <Typography style={{fontSize: "1em"}}>{post.Visits}</Typography>
                            <Space width="5px"/>
                            {post.Tier != null &&
                                <Fragment>
                                    <Typography style={{fontSize: "1em"}}>|</Typography>
                                    <Space width="5px"/>
                                    <Typography sx={{fontSize: "1em", fontWeight: "bold"}}>
                                        {post.Tier.Name.charAt(0).toUpperCase() + post.Tier.Name.slice(1)}
                                    </Typography>
                                </Fragment>
                            }
                        </Row>
                    </Column>
                </Box>

                {/* ARROW */}
                <Space width="10px"/>
                <ArrowForwardIosIcon sx={{fontSize: "16px"}}/>
                <Space width="10px"/>
            </Row>
        </NavLink>
    );
};

const LoadingSkeleton = () => {
    // Generate loading rows
    let rows = [];
    for (let i = 0; i < 3; i++) {
        rows.push(
            <React.Fragment key={"user-posts-mobile-loading-" + i}>
                <Space height="5px"/>
                <Row style={{display: "flex", alignItems: "center"}}>
                    {/* IMAGE */}
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            borderRadius: "4px",
                            minWidth: "75px",
                            minHeight: "75px",
                        }}
                    />

                    {/* TEXT */}
                    <Space width="15px"/>
                    <Column>
                        <Skeleton variant="text" width={"70%"}/>
                        <Skeleton variant="text" width={"20%"}/>
                        <Skeleton variant="text" width={"30%"}/>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }

    return (
        <Column>
            <TitleBand title="Aktive annoncer"/>
            {rows}
        </Column>
    );
};

export const MyPostsMobile = ({
    activePosts,
    inactivePosts,
    loading,
    posts
}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const navigateToPost = (postId) => {
        navigate("/post/" + postId);
    };

    // While fetching posts
    if (loading) {
        return <LoadingSkeleton/>
    }

    // If user has no posts
    if (posts.length == 0) {
        return <NoPosts/>;
    }

    return <Fragment>
        <Column>
            {/* ACTIVE POSTS */}
            <TitleBand title="Aktive annoncer"/>
            {activePosts.map((post, index) => {
                return (
                    <ButtonBase
                        style={{width: "100%"}}
                        key={"user-posts-mobile-active" + post.PostId}
                    >
                        <Column
                            style={{
                                paddingTop: index == 0 ? "6px" : "3px",
                                paddingBottom:
                                    index == activePosts.length - 1 ? "6px" : "3px",
                                borderTop:
                                    index != 0 ? "1px solid " + theme.palette.grey.A200 : "",
                            }}
                            key={post.PostId}
                        >
                            <PostRow post={post} posts={posts}/>
                        </Column>
                    </ButtonBase>
                );
            })}

            {/* INACTIVE OR EXPIRED POSTS */}
            <TitleBand title="Inaktive annoncer"/>
            {inactivePosts.map((post, index) => {
                return (
                    <ButtonBase
                        style={{width: "100%"}}
                        key={"user-posts-mobile-expired" + post.PostId}
                    >
                        <Column
                            key={post.PostId}
                            style={{
                                paddingTop: index == 0 ? "6px" : "3px",
                                paddingBottom:
                                    index == inactivePosts.length - 1 ? "6px" : "3px",
                                backgroundColor: theme.palette.grey.A100,
                                borderTop:
                                    index != 0 ? "1px solid " + theme.palette.grey.A200 : "",
                            }}
                        >
                            <PostRow post={post} posts={posts}/>
                        </Column>
                    </ButtonBase>
                );
            })}
        </Column>
    </Fragment>
}