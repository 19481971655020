import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import {
    Drawer,
    useTheme,
    styled
} from "@mui/material";
import {accountStore} from "../../../Stores/AccountStore";
import useSnackbar from "../../Snackbar/SnackbarHook";
import Row from "../../Basic/Row";
import Column from "../../Basic/Column";
import Space from "../../Basic/Space";
import {IconFactory} from "../../Shared/Icons/Factory/IconFactory";
import {categoryStore} from "../../../Stores/CategoryStore";
import {observer} from "mobx-react-lite";
import {useState} from "react";
import SearchFilter from "../../Search/Filters/SearchFilter";
import PictureRender from "../../Shared/Pictures/PictureRender";

const StyledDrawer = styled(Drawer)(({theme}) => ({
    "& .MuiPaper-root": {
        height: "100%",
    }
}));

const StyledLinks = styled(Typography)(({theme}) => ({
    fontSize: "24px",
    lineHeight: "55px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "fit-content",
    "&:hover": {
        color: theme.palette.jagtred.main,
    }
}));

const MuiIconStyler = styled(Box)(({theme}) => ({
    height: "30px",
    "& .MuiSvgIcon-root": {
        height: "30px",
        maxHeight: "30px",
        width: "30px",
        color: "white",
        cursor: "pointer"
    }
}));

const DrawerItemSearch = ({category, navigate}) => {
    return <Column key={"category-search-" + category.CategoryId}>
        <Row
            sx={{
                display: "flex",
                alignItems: "center",
                height: "50px",
                border: "1px solid black",
                borderRadius: "10px",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "#eaeaea"
                }
            }}
            onClick={() => {
                navigate("/search/?page=1&category=" + category.Name);
            }}
        >
            <IconFactory
                icon={category.Name}
                color="#000000"
                style={{
                    height:"35px",
                    width:"35px",
                    paddingLeft: "13px",
                    paddingRight: "5px",
                    margin: 0
                }}
            />
            <Space width="10px"/>
            <Typography
                variant="body1"
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "20px"
                }}>
                {category.Name + "\n"}
            </Typography>
        </Row>
        <Space height="5px"/>
    </Column>;
}

const DrawerItemBurger = ({onClick, highlight = false, sx, children}) => {
    const theme = useTheme();

    return <Row
        onClick={onClick}
        sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            maxHeight: "50px",
            borderRadius: "10px",
            border: "1px solid black",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#eaeaea",
                color: "black"
            },
            fontSize: "20px",
            color: highlight ? "white" : "black",
            backgroundColor: highlight ? theme.palette.jagtred.main : "white",
            fontWeight: highlight ? "bold" : "normal",
            ...sx
        }}
    >
        {children}
    </Row>
}

export const topBarHeight = 56; // pixels

export const MobileTopBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const snackbar = useSnackbar();
    const [openSearch, setOpenSearch] = useState(false);
    const [openBurger, setOpenBurger] = useState(false);

    const navigateTo = (path, state) => {
        if(!!state){
            navigate(path, state);
        } else {
            navigate(path);
        }
        setOpenBurger(false);
        setOpenSearch(false);
    }

    return <Column>
        {/* Make room for top bar */}
        <Space height={topBarHeight + "px"}/>

        {/* Always keep the top bar fixed on top */}
        <Box sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1001,
        }}>
            <Box
                sx={{
                    backgroundColor: theme.palette.jagtred.main,
                    padding: "6px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {/* BURGER DRAWER */}
                <StyledDrawer
                    transitionDuration={400}
                    anchor="top"
                    open={openBurger}
                    onClose={() => setOpenBurger(false)}
                >
                    <Row sx={{display: "flex", justifyContent: "end", padding: "15px"}}>
                        <CloseIcon
                            sx={{fontSize: "26px", cursor: "pointer"}}
                            onClick={() => setOpenBurger(false)}
                        />
                    </Row>
                    <Box sx={{margin: "0 20px"}}>
                        <DrawerItemBurger
                            sx={{margin: "20px 0"}}
                            highlight={true}
                            onClick={() => navigateTo("/opret-annonce")}
                        >
                            + Opret annonce
                        </DrawerItemBurger>

                        <StyledLinks
                            onClick={() => navigateTo("/")}
                        >Hjem</StyledLinks>
                        <StyledLinks
                            onClick={() => navigateTo("/min-side/my-posts")}
                        >Mine annoncer</StyledLinks>
                        <StyledLinks
                            onClick={() => navigateTo("/min-side/min-indbakke")}
                        >Indbakke</StyledLinks>
                        <StyledLinks
                            onClick={() => navigateTo("/min-side/profil")}
                        >Min profil</StyledLinks>
                        <StyledLinks
                            onClick={() => navigateTo("/om-jagtbasen")}
                        >Om Jagtbasen</StyledLinks>
                        <StyledLinks
                            onClick={() => {
                                if (accountStore.logged) {
                                    accountStore.logOutCognito().then(r => console.log("logged out"));
                                    snackbar.handleOpen("Logget ud");
                                    navigateTo("/");
                                } else {
                                    navigateTo('/log-ind/', {state:{ from: location }})
                                }
                            }}
                        >
                            {accountStore.logged
                                ? "Log ud"
                                : "Log ind"
                            }
                        </StyledLinks>
                    </Box>
                </StyledDrawer>

                {/* SEARCH DRAWER */}
                <StyledDrawer
                    transitionDuration={400}
                    anchor="top"
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                >
                    <Row sx={{display: "flex", justifyContent: "end", padding: "15px"}}>
                        <CloseIcon
                            sx={{fontSize: "26px", cursor: "pointer"}}
                            onClick={() => setOpenSearch(false)}
                        />
                    </Row>
                    <Row>
                        <SearchFilter
                            callback={() => setOpenSearch(false)}
                            sx={{margin: "10px 20px 30px 20px"}}
                        />
                    </Row>

                    <Box sx={{margin: "0 20px"}}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>
                            Kategorier
                        </Typography>
                        <Space height="7px"/>
                        {categoryStore.categories.map((category) => (
                            <DrawerItemSearch key={"mobile-drawer-" + category.Name} category={category} navigate={navigateTo} />
                        ))}
                        <Space height="20px"/>
                    </Box>
                </StyledDrawer>

                <PictureRender image="logo-long-gold" size={150} onClick={() => {navigate("/")}}/>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <MuiIconStyler>
                        <SearchIcon onClick={() => setOpenSearch(true)}/>
                    </MuiIconStyler>
                    <Space width="15px"/>
                    <MuiIconStyler>
                        <MenuIcon onClick={() => setOpenBurger(true)}/>
                    </MuiIconStyler>
                </Box>
            </Box>
        </Box>
    </Column>
};

export default observer(MobileTopBar);
