import {useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import InputText from "../../Form/InputText";
import Box from "@mui/material/Box";
import PasswordField from "../../Form/PasswordField";
import CheckIcon from "@mui/icons-material/Check";
import * as React from "react";
import {Fragment, useEffect} from "react";
import {LoginContainer} from "../Login/Login";
import Container from "@mui/material/Container";
import {observer} from "mobx-react-lite";
import {accountStore} from "../../../Stores/AccountStore";
import * as Sentry from "@sentry/react";
import useSnackbar from "../../Snackbar/SnackbarHook";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";


const ForgotPasswordSecondFlow = () => {
    const {
        register,
        formState: {errors},
        handleSubmit,
        getValues,
        watch
    } = useForm();

    let userEmail = ""
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [searchParams] = useSearchParams();

    if (!!searchParams.get("username")) {
        userEmail = searchParams.get("username");
    }
    const onSubmit = async data => {
        data.email = userEmail;
            try {
                await accountStore.resetPassword(data.email, data.code, data.password);
                snackbar.handleOpen("Din adgangskode er ændret. Du kan nu logge ind med din nye adgangskode.", "success");
                navigate("/log-ind");
            } catch (e) {
                if (e.code === "CodeMismatchException") {
                    snackbar.handleOpen("Ugyldig bekræftelseskode angivet. Prøv venligst igen.", "error");
                } else if (e.code === "LimitExceededException") {
                    snackbar.handleOpen("Forsøgsgrænsen er overskredet. Prøv venligst efter et stykke tid.", "error");
                } else {
                    Sentry.captureException(e);
                    snackbar.handleOpen(e.message, "error");
                }
            }

    }
    const passwordRegex = /^(?=.*\d)(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?!.*[^a-zA-Z0-9_æøåÆØÅ!@#$%^&*]).{8,}$/;
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordvalid, setPasswordvalid] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const subscription = watch((data) => {
            if (data.password === data.confirmPassword && data.password.match(passwordRegex)) {
                setPasswordvalid(true);
            } else {
                setPasswordvalid(false);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    return (
        <Fragment>
            <LoginContainer>
                <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth="xs">
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography component="h1" variant="h4" sx={{mb: 3}}>Glemt adgangs
                            kode</Typography>
                        <Typography sx={{mb: 3}}>Indtast verifikationskoden som du modtog på mail, vælg og bekræft din nye adgangskode, og klik på SEND.</Typography>
                        <Stack spacing={2}>
                            <InputText
                                id="code"
                                maxWidth="100%"
                                label="Kode"
                                register={register}
                                options={{
                                    required: "Påkrævet",
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: "Indtast seks cifrede kode"
                                    }
                                }}
                                errors={errors}
                            />

                            <Box sx={{width: "100%", position: "relative"}}>
                                <PasswordField
                                    id="password"
                                    label="Adgangskode"
                                    showPassword={showPassword}
                                    handleClickShowPassword={handleClickShowPassword}
                                    handleMouseDownPassword={handleMouseDownPassword}
                                    register={register}
                                    errors={errors}
                                    options={{
                                        required: "Påkrævet",
                                        pattern: {
                                            value: passwordRegex,
                                            message: "Et stort bogstav, et tal og 8 karakter"
                                        }
                                    }}
                                />
                                {passwordvalid && (
                                    <CheckIcon
                                        color="success"
                                        fontSize="medium"
                                        sx={{
                                            position: "absolute",
                                            top: "20%",
                                            marginLeft: "5px"
                                        }}
                                    />
                                )}
                            </Box>
                            <Box sx={{width: "100%", position: "relative"}}>
                                <PasswordField
                                    id="confirmPassword"
                                    label="Bekræft adgangkode"
                                    showPassword={showPassword}
                                    handleClickShowPassword={handleClickShowPassword}
                                    handleMouseDownPassword={handleMouseDownPassword}
                                    register={register}
                                    errors={errors}
                                    options={{
                                        required: "Påkrævet",
                                        pattern: {
                                            value: passwordRegex,
                                            message: "Et stort bogstav, et tal og 8 karakter"
                                        },
                                        validate: (value) => {
                                            if (value !== getValues("password"))
                                                return "Adgangskode ikke ens";
                                        }
                                    }}
                                />
                                {passwordvalid && (
                                    <CheckIcon
                                        color="success"
                                        fontSize="medium"
                                        sx={{
                                            position: "absolute",
                                            top: "20%",
                                            marginLeft: "5px"
                                        }}
                                    />
                                )}
                            </Box>
                        </Stack>
                        <Button type="submit" sx={{float: "right", mt: 3}} color="info"
                                variant="contained">Send</Button>
                    </Box>
                </Container>
            </LoginContainer>
        </Fragment>
    )
}
export default observer(ForgotPasswordSecondFlow);