import {
    Box, CardActionArea,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {dateToString} from "../../../Utilities/dateUtils";
import {formatNumber} from "../../../Utilities/postUtils";
import {
    StyledTableBody,
    StyledTableSubTitle,
    StyledTableImage,
    WebTableSkeleton,
    StyledTableHeader, NavCell
} from "../../Shared/Shared";
import {zipToCity} from "../../../Constants/Constants";
import React from "react";
import BreadCrumbs from "../../BreadCrumbs/Breadcrumbs";
import Space from "../../Basic/Space";
import {FilterBarWeb} from "../Filters/FilterBar";
import {
    useInstantSearch,
} from "react-instantsearch-hooks-web";
import {CategoryFilterWeb} from "../Filters/CategoryFilter";
import {useSearchContext} from "../../../Contexts/SearchContext";
import {Paginate} from "./SearchResults";

const TableHeader = ({children}) => {
    return (
        <StyledTableHeader>
            <TableCell>Annonce</TableCell>
            <TableCell sx={{width: "100%"}}></TableCell>
            <TableCell>
                Dato
            </TableCell>
            <TableCell>
                Pris
            </TableCell>
            {children}
        </StyledTableHeader>
    );
};

const Header = () => {
    const [searchParams] = useSearchParams();
    const category = searchParams.get("category")?.split(" > ");
    const subcategory = category?.[category.length - 1];

    return <React.Fragment>
        <BreadCrumbs/>
        <Space height="10px"/>

        {/* h4 category title */}
        {!!category && (
            <React.Fragment>
                <Typography variant="h4">
                    {subcategory}
                </Typography>
                <Space height="10px"/>
            </React.Fragment>
        )}

        <FilterBarWeb/>
        <Space height="10px"/>
    </React.Fragment>
}

/**
 * Component to show single hit
 */
const Hit = ({hit}) => {
    const startDate = dateToString(new Date(hit.activationTime * 1000), true)
    const to = "/post/" + hit.objectID

    return <TableRow
        hover={true}
        key={"search-results-web-" + hit.objectID}
    >
        {/* Image */}
        <NavCell to={to}>
            <StyledTableImage src={hit.images[0].url} />
        </NavCell>

        {/* Body */}
        <NavCell to={to}>
            <StyledTableBody sx={{fontWeight: hit.highlight ? "bold" : undefined}}>
                {hit.brand != null ? hit.brand + "," : ""}{" "}
                {hit.heading}, {hit.body}
            </StyledTableBody>
            <StyledTableSubTitle>
                {hit.zip + " " + zipToCity["DK"][hit.zip]}
            </StyledTableSubTitle>
        </NavCell>

        <NavCell to={to} sx={{whiteSpace: "nowrap"}}>
            {startDate}
        </NavCell>
        <NavCell
            to={to}
            sx={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
                textAlign: "right"
            }}
        >
            {formatNumber({value: hit.price.toString()})} kr.
        </NavCell>
    </TableRow>
}

/**
 * Component to show all hits
 */
const Hits = () => {
    const searchContext = useSearchContext();
    const {status} = useInstantSearch()

    const hits = searchContext.data.hits ?? [];

    // If loading
    if (status == "loading" || status == "stalled")
        return <Box sx={{width: "100%"}}>
            <WebTableSkeleton>
                <TableHeader/>
            </WebTableSkeleton>
        </Box>

    // If there were no results
    if (hits.length == 0 || status == "error") {
        return <Box sx={{width: "100%", paddingTop: "30px"}}>
            <Typography variant="h4">Beklager, der var ingen resultater</Typography>
            <Typography variant="h5">Prøv at søge på noget andet eller ændre i filtrene</Typography>
        </Box>
    }

    // Show results
    return <Table size="small">
        <TableBody>
            <TableHeader/>
            {hits.map(hit => {
                return <Hit
                    key={"search-results-web-" + hit.objectID}
                    hit={hit}
                />
            })}
        </TableBody>
    </Table>
}

export const SearchResultsWeb = () => {
    return <Box sx={{display: "flex"}}>
        <CategoryFilterWeb/>
        <Box sx={{width: "100%"}}>
            <Header/>
            <Hits/>
            <Paginate/>
        </Box>
    </Box>
}
