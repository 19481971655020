import {
    Alert,
    Box,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Paper, Popover, Snackbar,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import PersonIcon from "@mui/icons-material/Person";
import {zipToCity} from "../../Constants/Constants";
import {ApprovedIcon} from "../Shared/Icons/ApprovedIcon";
import {timestampToDate} from "../../Utilities/dateUtils";
import React, {useState} from "react";
import {useMediaContext} from "../../MediaContext";
import {observer} from "mobx-react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import {accountStore} from "../../Stores/AccountStore";
import {AccountAvatar} from "../Shared/Shared";
import useSnackbar from "../Snackbar/SnackbarHook";
import ReportPostPopUp from "../Report/ReportPostPopUp";
import {useLocation} from "react-router-dom";
import Space from "../Basic/Space";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {lotteryStore} from "../../Stores/LotteryStore";
import {useNavigate} from "react-router-dom";

const Banner = () => {
    const navigate = useNavigate();

    return <img
        src="https://jagtbasen.s3.eu-north-1.amazonaws.com/resources/frontpage-banner-mobile.png"
        width="100%"
        alt="banner"
        style={{cursor: "pointer"}}
        onClick={() => {
            navigate("/opret-annonce");
        }}
    />
};

const ContactOwner = ({scrollToMessageArea, sx, post}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const snackbar = useSnackbar();
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
// Popover when share is clicked
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const openPopover = Boolean(popoverAnchorEl);
    const idPopover = openPopover ? "simple-popover" : undefined;
    const [numberPopoverAnchorEl, setNumberPopoverAnchorEl] = useState(null);
    const openNumberPopover = Boolean(numberPopoverAnchorEl);
    const idNumberPopover = openNumberPopover ? "simple-popover" : undefined;
    const [anmeldAnnonceOpen, setAnmeldAnnonceOpen] = useState(false);
    const [userEmail,setUserEmail] = useState('');
    const lotteryMode = Object.keys(lotteryStore.lotteries).length > 0;
    const handleNumberPopoverClick = (event) => {
        navigator.clipboard.writeText(post.User.Phone);
        setNumberPopoverAnchorEl(event.currentTarget);
        setTimeout(handlePopoverClose, 2000);
    };
    const handleNumberPopoverClose = () => {
        setNumberPopoverAnchorEl(null);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const handlePopoverClick = (event) => {
        navigator.clipboard.writeText(window.location.href);
        setPopoverAnchorEl(event.currentTarget);
        setTimeout(handlePopoverClose, 2000);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleAnmeldAnnonceClick = (event) =>{
        // If user is not signed in, redirect to login page
        if (!accountStore.logged) {
            snackbar.handleOpen("Denne handling kræver, at du er logget ind", "error");
            return;
        }
        setUserEmail(accountStore.user.email)
        setAnmeldAnnonceOpen(true);
    }
    const handleAnmeldAnnonceClose = (event) =>{
        setAnmeldAnnonceOpen(false);
    }
    const handleSubmit = (result) => {
        if (result){
            setOpenSnackBar(true)
            snackbar.handleOpen(result,"success")
        } else {
            setOpenSnackBar(true)
            snackbar.handleOpen("Fejl, prøve senere. Du kan evt. ring til os.","error")
        }
        handleAnmeldAnnonceClose()
    }

    //Contact Dialog
    const [dialogOpen, setDiaologOpen] = useState(false);
    const handleClickOpen = () => {
        if(accountStore.logged){
            setDiaologOpen(true);
        } else {
            snackbar.handleOpen("Denne handling kræver, at du er logget ind", "error")
        }
    };

    const handleClickClose = () => {
        setDiaologOpen(false);
    };

    const writeToPostOwner = () => {
        if(accountStore.logged) {
            scrollToMessageArea();
        } else {
            snackbar.handleOpen("Denne handling kræver, at du er logget ind", "error")
        }
    }

    if (accountStore.logged && accountStore.userInfo.attributes.sub === post.UserId) {
        return (
            <Stack spacing={1} sx={{textAlign: "center", ...sx}}>
                {/*  Din annonce */}
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px",
                    border: "1px solid",
                    borderColor: "grey.300",
                    borderRadius: "4px",
                }}>
                    <PersonIcon/>
                    <Space width="5px"/>
                    <Typography variant="subtitle2">DIN ANNONCE</Typography>
                </Box>

                {/* Læs beskeder */}
                <Box>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={scrollToMessageArea}
                        color="jagtred"
                    >
                        Se beskeder
                    </Button>
                </Box>
                <Space height="2px"/>
                {lotteryMode && <Banner/>}
            </Stack>
        )
    }

    const notLoggedInFeedback = () => {
        snackbar.handleOpen("Denne handling kræver, at du er logget ind", "error");
    }

    return (
        <Stack spacing={1} sx={{textAlign: "center"}}>
            <Box>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={writeToPostOwner}
                    color="jagtred"
                >
                    SKRIV TIL SÆLGER
                </Button>
            </Box>
            {isMobile ?
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center"
                       spacing={1}>
                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<CallIcon/>}
                        onClick={accountStore.logged ? undefined : notLoggedInFeedback}
                        href={accountStore.logged ? "tel:" + post.User.Phone : undefined}
                    >
                        RING
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<SmsIcon/>}
                        onClick={accountStore.logged ? undefined : notLoggedInFeedback}
                        href={accountStore.logged ? "sms:" + post.User.Phone : undefined}
                    >
                        SMS
                    </Button>
                </Stack>
                :
                <Box>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleClickOpen}
                    >
                        KONTAKT SÆLGEREN
                    </Button>
                </Box>
            }
            <Stack direction="row"
                   justifyContent="center"
                   alignItems="center"
                   spacing={1}>
                <Button variant="outlined"
                        aria-describedby={idPopover}
                        fullWidth
                        onClick={handlePopoverClick}
                        sx={{whiteSpace:"nowrap"}}
                        startIcon={<ShareIcon/>}>Del</Button>

                <Button variant="outlined"
                        aria-describedby={idPopover}
                        onClick={handleAnmeldAnnonceClick}
                        fullWidth
                        sx={{whiteSpace:"nowrap"}}
                        startIcon={<ErrorOutlineIcon/>}>Anmeld</Button>
                <ReportPostPopUp open={anmeldAnnonceOpen} handleClose={handleAnmeldAnnonceClose} submit={handleSubmit} postId={post.PostId} email={userEmail}/>
            </Stack>
            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{p: 2}}>Link kopireret</Typography>
            </Popover>

            <Space height="2px"/>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                p: 1,
                border: 1,
                borderRadius: 1,
                borderColor: "grey.500",
            }}>
                <Box sx={{m:1}}>
                    <AccountAvatar
                        radius="60px"
                        firstName={post.User.Firstname}
                        lastName={post.User.Lastname}/>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", textAlign: "left", ml: 1}}>
                    <Typography>
                        {post.User.Firstname + " " + post.User.Lastname}
                    </Typography>
                    <Typography>
                        {post.Zip + " " + zipToCity["DK"][post.Zip]}
                    </Typography>
                    {post.User.MitIdValidated &&
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <ApprovedIcon/>
                            <Space width="3px"/>
                            <Typography sx={{mr: 1}}>MitID valideret</Typography>
                        </Box>
                    }
                    <Typography sx={{fontSize: "14px"}}>
                        Bruger siden {timestampToDate(post.User.CreationTime)}
                    </Typography>
                </Box>
            </Box>
            <Dialog
                open={dialogOpen}
                onClose={handleClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Sælger Information"}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row"
                           justifyContent="center"
                           alignItems="center"
                           spacing={2}
                    >
                        <Typography>
                            Telefon
                        </Typography>
                        <Box sx={{border: 1, borderRadius: 1}}>
                            <Typography sx={{m: 0.5}}>
                                {post.User.Phone}
                            </Typography>
                        </Box>
                        <IconButton color="jagtred" aria-describedby={idNumberPopover}
                                    onClick={handleNumberPopoverClick}>
                            <ContentCopyIcon/>
                        </IconButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="jagtred" onClick={handleClickClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Popover
                id={idNumberPopover}
                open={openNumberPopover}
                anchorEl={numberPopoverAnchorEl}
                onClose={handleNumberPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{p: 2}}>Nummer kopireret</Typography>
            </Popover>
            <Space height="2px"/>
            {lotteryMode && <Banner/>}
        </Stack>
    )
}

export default observer(ContactOwner);