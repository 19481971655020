import accountService from "./AccountService";
import {reportException, reportMessage} from "../Utilities/sentry";

class ChatService {
    async fetchInbox(inboxId){
        const jwt = await accountService.getCognitoJwt()
        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        };
        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Chat/"+inboxId,
                requestOptions
            );
            if(!response.ok) return null
            return await response.json()
        } catch (e) {
            reportException(e);
            console.log(e);
        }
    }
    async fetchInboxes(postId){
        let jwt = null
        try {
             jwt = await accountService.getCognitoJwt()
        }catch (e){
            return jwt
        }
        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        };
        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Chat/chat/"+postId,
                requestOptions
            );
            if (!response.ok) return null
            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
        }
    }

    async fetchInboxesUser(){
        let jwt = null
        try {
            jwt = await accountService.getCognitoJwt()
        }catch (e){
            return jwt
        }
        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        };
        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Chat/userInboxes",
              requestOptions
            );
            if (!response.ok) return null
            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
        }
    }

    async sendMessage(inboxId,text,senderId){
        const jwt = await accountService.getCognitoJwt()
        const requestOptions = {
            method: 'POST',
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                inboxId: inboxId,
                messageId: 0,
                messageText: text,
                senderUserId: senderId,
                time: 0,
                inbox: null,
                user: null
            })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/Chat/", requestOptions);

            if (!response.ok) {
                reportMessage(
                    "Received status != 200 during send message", {options : {
                            status: response.status,
                            message: response.statusText,
                            response: response.clone().text(),
                            inboxId: JSON.stringify(inboxId),
                            text: JSON.stringify(text),
                            senderId: JSON.stringify(senderId)
                        }}
                );
            }

            return await response.json();
        }catch (e){
            reportException(e);
            console.error(e)
        }

    }
    async createInbox(postId,visitorUserId,messagestext){
        const jwt = await accountService.getCognitoJwt()
        const requestOptions = {
            method: 'POST',
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                inboxId: 0,
                postId: postId,
                visitorUserId: visitorUserId,
                messages : [{
                    inboxId : 0,
                    messageId : 0,
                    messageText : messagestext,
                    senderUserId:visitorUserId,
                    time: 0,
                    inbox: null,
                    user: null
                }],
                lastMessage: messagestext,
                time : 0,
                post: {  },
                user: null
            })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/Chat/inbox", requestOptions);

            if (!response.ok) {
                reportMessage(
                    "Received status != 200 during feedback creation", {options : {
                            status: response.status,
                            message: response.statusText,
                            response: response.clone().text(),
                            postId: JSON.stringify(postId),
                            visitorUserId: JSON.stringify(visitorUserId),
                            messagestext: JSON.stringify(messagestext)
                        }}
                );
            }

            return await response.json();
        }catch (e){
            reportException(e);
            console.error(e)
        }

    }

    async updateRead(inboxId){
        const jwt = await accountService.getCognitoJwt()
        const requestOptions = {
            method: 'PUT',
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/Chat/updateMessageRead/"+inboxId, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        }catch (e){
            reportException(e);
            console.error(e)
        }
    }
}

export default new ChatService();