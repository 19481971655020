import React from "react";

export const Banner = ({aspectRatio, style, ...props}) => {
    return <img
        style={{
            width: "100%",
            aspectRatio: aspectRatio,
            objectFit: "cover",
            ...style
        }}
        {...props}
    />
}