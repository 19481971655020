import React, {useEffect, useImperativeHandle, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useSnackbar from "../../Snackbar/SnackbarHook";
import postsService from "../../../Services/PostsService";
import {formatNumber, formatZip, isValidZip} from "../../../Utilities/postUtils";
import {Box, Button, Popover, Slider, TextField, Typography, useTheme} from "@mui/material";
import Row from "../../Basic/Row";
import Column from "../../Basic/Column";
import Space from "../../Basic/Space";
import {useSearchContext} from "../../../Contexts/SearchContext";
import {FilterBox} from "./FilterBox";
import {CustomPopper} from "../../Shared/Shared";

export const LocationFilterDesktop = React.forwardRef((props, ref) => {
    const snackbar = useSnackbar();
    const searchContext = useSearchContext();

    const [searchParams, setSearchParams] = useSearchParams();

    let latLngParam = searchParams.get("latLng");
    const radiusParam = searchParams.get("radius");

    const [zipCode, setZipCode] = useState("");
    const [radius, setRadius] = useState(radiusParam ?? "");

    useImperativeHandle(ref, () => {
        return {
            onNewLocation: onNewLocation
        }
    });

    useEffect(() => {
        setRadius(radiusParam ?? "")
    }, [radiusParam]);

    // Convert latLng to zipCode
    useEffect(() => {
        if (latLngParam == null) {
            setZipCode("");
            return;
        }

        const latLng = latLngParam?.split(",");
        const lat = latLng[0];
        const lng = latLng[1];

        postsService.latLngToZipCode(lat, lng)
        .then((zip) => {
            // If the conversion was successful
            if (isValidZip(zip)) {
                setZipCode(zip);
            }
        });
    }, [latLngParam]);


    const onNewLocation = async () => {
        let newRadiusParam = {};
        let newLatLngParam = {};

        if (radius != "") {
            newRadiusParam = {"radius": radius};
        }

        if (zipCode != "") {
            // Convert zip code to latLng
            const latLng = await postsService.zipCodeToLatLng(zipCode);

            // If conversion failed
            if (latLng == null) {
                snackbar.handleOpen("Ugyldigt postnummer", "error");
            } else {
                newLatLngParam = {"latLng": `${latLng[0]},${latLng[1]}`};
            }
        }

        return {
            ...newRadiusParam,
            ...newLatLngParam
        }
    }

    // When the user presses enter in one of the fields
    const onEnter = async (event) => {
        event.target.blur();

        searchContext.refineRadiusAndZipCode(radius, zipCode);
    }

    return <Box>
        <Typography sx={{fontWeight: "bold"}}>Lokation</Typography>
        <Row sx={{
            paddingTop: "10px",
            alignItems: "center"
        }}>
            {/* Radius field */}
            <TextField
                sx={{maxWidth: "100px"}}
                label={"Km"}
                value={radius}
                onChange={(event) => {
                    setRadius(formatNumber({
                        value: event.target.value,
                        maxLength: 4,
                        excludeZero: true,
                        useComma: false
                    }));
                }}
                onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        onEnter(event);
                    }
                }}
            />

            <Typography
                sx={{padding: "0 5px"}}
            >fra</Typography>

            {/* Zip code field */}
            <TextField
                sx={{maxWidth: "100px"}}
                label={"Postnr."}
                value={zipCode}
                onChange={(event) => {
                    setZipCode(formatZip(event.target.value));
                }}
                onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        onEnter(event);
                    }
                }}
            />
        </Row>
    </Box>
});

export const LocationFilterMobile = () => {
    const theme = useTheme();
    const searchContext = useSearchContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const latLngParam = searchParams.get("latLng");
    const radiusParam = searchParams.get("radius");

    const isActive = latLngParam != null && radiusParam != null

    const [anchorEl, setAnchorEl] = useState(null);

    const anchorRef = React.useRef();
    const open = Boolean(anchorEl);

    const [radius, setRadius] = useState(radiusParam ?? "");
    const [zipCode, setZipCode] = useState("");

    useEffect(() => {
        if (open == false) return;

        setRadius(radiusParam ?? "")
    }, [radiusParam, open]);

    useEffect(() => {
        if (open == false) return;

        if (latLngParam == null) {
            setZipCode("");
            return;
        }

        const latLng = latLngParam?.split(",");
        const lat = latLng[0];
        const lng = latLng[1];

        postsService.latLngToZipCode(lat, lng)
        .then((zip) => {
            // If the conversion was successful
            if (isValidZip(zip)) {
                setZipCode(zip);
            }
        });
    }, [latLngParam, open]);

    const handleOpen = () => {
        setAnchorEl(anchorRef.current);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSave = async () => {
        await searchContext.refineRadiusAndZipCode(radius, zipCode);
        handleClose();
    }

    const handleReset = () => {
        setRadius("");
        setZipCode("");
    }

    const handleRadiusChange = (event) => {
        setRadius(formatNumber({
            value: event.target.value,
            maxLength: 4,
            excludeZero: true,
            useComma: false
        }));
    }

    const handleZipCodeChange = (event) => {
        setZipCode(formatZip(event.target.value));
    }

    return <React.Fragment>
        <FilterBox
            title={"Lokation"}
            isActive={isActive}
            onOpen={handleOpen}
            anchorRef={anchorRef}
        />
        <CustomPopper
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <React.Fragment>
                <Column sx={{display: "flex", justifyContent: "end"}}>
                    <Typography
                        sx={{
                            width: "fit-content",
                            fontWeight: "bold",
                            cursor: "pointer",
                            color: theme.palette.jagtred.main,
                            "&:hover": {
                                textDecoration: "underline",
                            }
                        }}
                        onClick={handleReset}
                    >
                        Nulstil
                    </Typography>
                </Column>
                <Space height="10px"/>
                <Row sx={{alignItems: "center"}}>
                    <TextField
                        sx={{maxWidth: "100px"}}
                        label={"Km"}
                        value={radius}
                        onChange={handleRadiusChange}
                    />
                    <Typography
                        sx={{padding: "0 5px"}}
                    >fra</Typography>
                    <TextField
                        sx={{maxWidth: "100px"}}
                        label={"Postnr."}
                        value={zipCode}
                        onChange={handleZipCodeChange}
                    />
                </Row>
                <Space height="15px"/>
                <Box sx={{textAlign: "end"}}>
                    <Button
                        variant="contained"
                        color="jagtred"
                        sx={{margin: "0 0 10px 0"}}
                        onClick={handleSave}
                    >
                        Gem
                    </Button>
                </Box>
            </React.Fragment>
        </CustomPopper>

    </React.Fragment>
}