import accountService from "./AccountService";
import {reportMessage} from "../Utilities/sentry";

class FeedbackService {
    async createFeedback(feedback) {
        const jwt = await accountService.getCognitoJwt()
        return fetch(`${process.env.REACT_APP_API_URL}/api/feedback`, {
            method: 'POST',
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify(feedback),
        }).then((response) => {
            if (!response.ok) {
                reportMessage(
                    "Received status != 200 during feedback creation", {options : {
                        status: response.status,
                        message: response.statusText,
                        response: response.clone().text(),
                        feedback: JSON.stringify(feedback)
                    }}
                );
                throw new Error('Failed to submit feedback');
            }
        });
    }
}

export default new FeedbackService();