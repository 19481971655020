import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import {observer} from "mobx-react";
import {chatStore} from "../../Stores/ChatStore";
import {accountStore} from "../../Stores/AccountStore";
import React from 'react';
import InitialChatBox from "./InitialChatBox";
import Typography from "@mui/material/Typography";
import {Box, Stack} from "@mui/material";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

const Chat = ({post}) => {
    const EmptyInbox = () => {
        return (
            <div>
                <Stack sx={{pt: 1, pb: 2}}>
                    <Typography color={"rgba(0, 0, 0, 0.6)"} fontSize={"smaller"}>
                        Se dine private samtaler på annoncen med andre Jagtbasen brugere
                    </Typography>
                </Stack>
                <Box sx={{padding: 5}}>
                    <Typography
                        align={"center"}
                        variant={"h6"}
                        color={"lightgray"}
                    >
                        DU HAR INGEN BESKEDER I ØJEBLIKKET
                    </Typography>
                </Box>
            </div>
        );
    };
    return (
        <div>
            <Stack direction="row" spacing={2}>
                <QuestionAnswerOutlinedIcon sx={{color: "#5e1022"}} fontSize="large"
                                            style={{paddingTop: 5}}/>
                <Typography
                    fontWeight={"bolder"} variant={"h4"}
                >
                    Beskeder
                </Typography>
            </Stack>
            {
                (!accountStore.logged)
                    ? <div>
                        <Box sx={{padding: 5}}>
                            <Typography
                                align={"center"}
                                variant={"h5"}
                                color={"darkgray"}
                            >
                                Du skal have en profil for at kunne læse og skrive beskeder
                            </Typography>
                        </Box>
                    </div>
                    : chatStore.inbox !== null && chatStore.inbox.Messages
                        ? <ChatBox post={post}/>
                        : chatStore.inboxes.length === 0 && post.UserId === accountStore.userInfo.attributes.sub
                            ? <EmptyInbox/>
                            : chatStore.inboxes.length > 1
                                ? <ChatList/>
                                : post.Active
                                    ? <InitialChatBox post={post}/>
                                    : <Typography sx={{padding: "15px 0"}}>Beskeder slået fra, da annoncen ikke er aktiv.</Typography>
            }
        </div>
    );
}

export default observer(Chat)