import {Box, styled} from "@mui/material";
import React from "react";

const Divider = styled(Box)(({ theme }) => ({
    height: "2px",
    width: "100%",
    backgroundColor: theme.palette.grey[300],
}));

export default Divider;
