import React, {useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Box, MenuItem, Select, useTheme} from "@mui/material";
import Row from "../../Basic/Row";
import {useMediaContext} from "../../../MediaContext";
import PictureRender from "../../Shared/Pictures/PictureRender";

export default function SearchFilter(props){
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [searchParams, setSearchParams] = useSearchParams();

    const queryParam = searchParams.get("query");
    const [queryHolder, setQueryHolder] = React.useState(queryParam ?? "");

    const categoryParam = searchParams.get("category");
    const category = categoryParam?.split(" > ");

    // 0 when searching in chosen category, 1 when searching on the entire jagtbasen.dk
    const [domain, setDomain] = useState(0);

    useEffect(() => {
        setQueryHolder(queryParam ?? "");
    }, [queryParam]);

    // Reset search domain when changing category
    useEffect(() => {
        setDomain(0);
    }, [categoryParam]);

    function handleSearch() {
        const emptyQuery = queryHolder == "";
        const onSearchPage = location.pathname.includes("search");

        // Call the callback function if it exists
        if (props?.callback) {
            props.callback();
        }

        // If the user is not on the search page already
        // or if the user wants to search on the entire jagtbasen.dk
        if (!onSearchPage || domain == 1) {
            const page = `/search/?page=1${emptyQuery ? "" : "&query=" + queryHolder}`;
            navigate(page);
            return;
        }

        searchParams.set("page", "1");

        if (!emptyQuery) {
            searchParams.set("query", queryHolder);
        }

        if (emptyQuery) {
            searchParams.delete("query");
        }

        setSearchParams(searchParams);
    }

    return <Row
        sx={{
            position: "relative",
            height: "50px",
            border: "1px solid black",
            borderRadius: "50px",
            width: "100%",
            margin: "0px 20px",
            alignItems: "center",
            backgroundColor: "white",
            ...props.sx
        }}
    >
        {/* Top Banner */}
        {props.showBanner && (
            <React.Fragment>
                <PictureRender
                    image="search-banner"
                    size={175}
                    style={{
                        opacity: "0.5",
                        position: "absolute",
                        left: "50%",
                        top: "-19px",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "175px",
                        zIndex: "-1",
                    }}
                />
            </React.Fragment>
        )}

        <SearchIcon
            sx={{
                fontSize: "25px",
                padding: "0 5px 0 15px",
                cursor: "pointer",
            }}
            onClick={ () => handleSearch() }
        />
        <input
            style={{
                backgroundColor: "transparent",
                "WebkitTapHighlightColor": "transparent",
                border: "none",
                margin: "0px",
                padding: "10px",
                outline: "none",
                fontSize: "18px",
                width: "100%",
            }}
            type="search"
            value={queryHolder ?? ""}
            placeholder="Søg på Jagtbasen"
            onChange={(event) => {
                setQueryHolder(event.target.value);
            }}
            onKeyUp={(event) => {
                if (event.key == "Enter") {
                    event.target.blur();
                    handleSearch();
                }
            }}
            enterKeyHint="search"
        />

        {/* Domain picker */}
        {category != null && (
            <Box sx={{
                borderLeft: "1px solid black",
                height: "100%",
                display: "flex",
                alignItems: "center",
            }}>
                <Select
                    sx={{
                        color: theme.palette.grey[500],
                        maxWidth: "150px",
                        padding: "0px",
                        ".MuiOutlinedInput-input": {
                            paddingLeft: "10px",
                            marginRight: "5px"
                        },

                        // Remove border
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
                    }}
                    value={domain}
                    onChange={(event) => {
                        setDomain(event.target.value);
                    }}
                >
                    <MenuItem value={0}>i {category[category.length - 1]}</MenuItem>
                    <MenuItem value={1}>på hele Jagtbasen</MenuItem>
                </Select>
            </Box>
        )}
    </Row>
}
