import React from "react";
import Footer from "./Footer/Footer";
import {observer} from "mobx-react-lite";
import {Outlet} from "react-router-dom";
import {Box, useMediaQuery} from "@mui/material";
import {MobileView, useMediaContext} from "../MediaContext";
import {accountStore} from "../Stores/AccountStore";
import Feedback from "./Feedback/Feedback";
import TopBar from "./Bars/TopBar";
import Space from "./Basic/Space";
import Row from "./Basic/Row";
import Column from "./Basic/Column";
import {bannerStore} from "../Stores/BannerStore";
import {Ad} from "./Ads/Ad";

const bannerWidth = 250;
const bannerHeight = 750;

const SideBanner = ({left, banner}) => {
    const aspectRatio = `${bannerWidth}/${bannerHeight}`;

    return <Column sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: left ? "end" : "start",
    }}>
        <Space height="193px"/>
        <Box sx={{
            position: "sticky",
            top: "20px",
            width: bannerWidth + "px",
        }}>
            <Ad
                banner={banner}
                aspectRatio={aspectRatio}
            />
        </Box>
    </Column>
}

function Home(props) {
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;

    // The max width of the application (without the side banners)
    const contentWidth = 1200;

    // Banner properties
    const bannerLocation = "D2"
    const banner = bannerStore.banners[bannerLocation];

    // Only show side banners if there is space for them
    const showBanners = useMediaQuery(`(min-width:${contentWidth + 2 * bannerWidth}px)`);

    return (
        <Box
            sx={{
                minHeight: isMobile ? undefined : "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Row sx={{
                width: "100%",
                justifyContent: "center",
                minHeight: isMobile ? undefined : (bannerWidth + 100) + "px",
            }}>
                {/* LEFT BANNER */}
                {showBanners && <SideBanner left={true} banner={banner}/>}

                {/* MIDDLE CONTENT */}
                <Column
                    sx={{
                        width: "100%",
                        minWidth: showBanners ? contentWidth + "px" : "",
                        maxWidth: contentWidth + "px",
                        padding: `0 25px`,
                        boxSizing: "border-box",
                    }}
                    style={isMobile ? {padding: 0, flexGrow: 1} : {flexGrow: 1}}
                >
                    <TopBar/>
                    <Outlet/>
                </Column>

                {/* RIGHT BANNER */}
                {showBanners && <SideBanner left={false} banner={banner}/>}
            </Row>
            <Footer/>
            <MobileView>
                <Space height="45px"/>
            </MobileView>
            {accountStore.logged && <Feedback/>}
        </Box>
    );
}

export default observer(Home);
