import {Grid, Typography} from "@mui/material";
import {MobileContainer} from "../../Global/Global";
import {zipToCity} from "../../../Constants/Constants";
import React, {Fragment} from "react";
import SearchFilter from "../Filters/SearchFilter";
import {PostCardDynamic, PostCardDynamicSkeletons} from "../../Posts/PostCard";
import BreadCrumbs from "../../BreadCrumbs/Breadcrumbs";
import Space from "../../Basic/Space";
import Column from "../../Basic/Column";
import Row from "../../Basic/Row";
import {useInstantSearch} from "react-instantsearch-hooks-web";
import {PriceFilterMobile} from "../Filters/PriceFilter";
import {useSearchContext} from "../../../Contexts/SearchContext";
import {LocationFilterMobile} from "../Filters/LocationFilter";
import {CategoryFilterMobile} from "../Filters/CategoryFilter";
import Divider from "../../Basic/Divider";
import {Paginate} from "./SearchResults";
import {useNavigate} from "react-router-dom";
import {bannerStore} from "../../../Stores/BannerStore";
import {Ad} from "../../Ads/Ad";

const Header = () => {
    return <React.Fragment>
        <MobileContainer>
            <SearchFilter sx={{margin: "20px 0"}}/>
            <BreadCrumbs sx={{paddingTop: "5px"}}/>
        </MobileContainer>

        <CategoryFilterMobile/>
        <Space height="5px"/>
        <Divider/>

        <MobileContainer>
            {/* Filtre */}
            <Column sx={{paddingTop: "10px"}}>
                <Typography sx={{fontWeight: "bold"}}>Filtre</Typography>
                <Space height="5px"/>
                <Row >
                    <PriceFilterMobile/>
                    <Space width="10px"/>
                    <LocationFilterMobile/>
                </Row>
                <Space height="5px"/>
            </Column>
            <Space height="10px"/>
        </MobileContainer>
    </React.Fragment>
}

// Function to render a single PostCardDynamic component
const renderPostCard = (post) => (
    <PostCardDynamic
        key={"search-results-mobile-" + post.objectID}
        postId={post.objectID}
        imageUrl={post.images[0].url}
        price={post.price}
        title={post.heading + ", " + post.body}
        subtitle={post.zip + " " + zipToCity["DK"][post.zip]}
        highlight={post.highlight}
    />
);

const Hits = () => {
    const searchContext = useSearchContext();
    const { status } = useInstantSearch()

    const hits = searchContext.data.hits ?? [];

    const banner = bannerStore.banners["M2"]
    const bannerRatio = "5/2"

    // If loading
    if (status == "loading" || status == "stalled") {
        return <PostCardDynamicSkeletons/>;
    }

    // If there were no results
    if (hits.length == 0 || status == "error") return (
        <MobileContainer>
            <Typography variant="h4">Beklager, der var ingen resultater</Typography>
            <Typography variant="h5">Prøv at søge på noget andet eller ændre i filtrene</Typography>
            <Space height="100px"/>
        </MobileContainer>
    )

    return <MobileContainer>
        <Grid container spacing={1}>
            {hits.map((post, index) => {

                return <Fragment>
                    {/* At the 18th element we insert an ad */}
                    {index == 18 && (
                        <Grid item xs={12}>
                            <Ad
                                banner={banner}
                                aspectRatio={bannerRatio}
                            />
                            <Space height="15px"/>
                        </Grid>
                    )}
                    {renderPostCard(post)}
                </Fragment>
            })}
        </Grid>
    </MobileContainer>
}

export const SearchResultsMobile = () => {
    return (
        <React.Fragment>
            <Header />
            <Space height="20px" />
            <Hits/>
            <Paginate/>
        </React.Fragment>
    );
}