import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import {categoryStore} from "../../../Stores/CategoryStore";
import List from "@mui/material/List";
import {Box, ListItem, TextField, Typography} from "@mui/material";


/*
    Returns the selected category and subcategory in the onClick method.

    Format:

    {
      "category": {
        "id": 16,
        "name": "Hunde"
      },
      "subcategory": {
        "id": 162,
        "name": "Hundesenge og tæpper"
      }
    }
 */
const CategorySelector = ({onClick}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchItems, setSearchItems] = useState();
    const [fuse, setFuse] = useState();

    const fuseOptions = {
        includeScore: true,
        keys: [
            'category.name',
            'subcategory.name',
        ],
    };

    // Once the categories have been fetched, initialize search-list
    useEffect(() => {
        const cats = categoryStore.categories.map(category => {
            return category.Subcategories.map(subcategory => {
                return {
                    category: {
                        id: category.CategoryId,
                        name: category.Name,
                    },
                    subcategory: {
                        id: subcategory.SubcategoryId,
                        name: subcategory.Name
                    }
                }
            })
        })

        setFuse(new Fuse(cats.flat(), fuseOptions))

    }, [categoryStore.isInitialized]);

    useEffect(() => {

        if (!categoryStore.isInitialized || fuse == null) return;

        const result = fuse.search(searchTerm);
        const filtered = result.map(({ item }) => item);
        setSearchItems(filtered);
    }, [searchTerm]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleItemClick = (index) => {
        const item = searchItems[index]
        onClick(item)
    };

    return (
        <Box sx={{
            padding: "20px"
        }}>
            <Typography>
                Hvad vil du sælge?
            </Typography>
            <TextField
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="F.eks. 'Riffel' eller 'Hund'"
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: "white",
                    }
                }}
                style={{maxWidth: "20rem"}}
                fullWidth
            />
            <List>
                {searchItems?.slice(0, 9).map((item, index) => (
                    <ListItem
                        key={index}
                        onClick={() => handleItemClick(index)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                fontWeight: 'bold',
                            }
                        }}
                    >
                        {item.subcategory.name}
                        <span style={{fontSize: 'smaller', color: 'grey', marginLeft: "5px"}}>
                            {"› " + item.category.name}
                        </span>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default CategorySelector;
