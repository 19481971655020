import {SideMenu} from "../MySiteDesktop";
import {Box, Link, Table, TableBody, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import {
    NavCell,
    StyledTableBody,
    StyledTableHeader,
    StyledTableImage,
    StyledTableSubTitle,
    WebTableSkeleton
} from "../../Shared/Shared";
import Space from "../../Basic/Space";
import React, {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { NavLink, useNavigate, Link as RouterLink } from "react-router-dom";
import {
    getActivatePostConfirmation,
    getDeactivatePostConfirmation,
    useConfirmationDialog
} from "../../Dialogs/ConfirmationDialog";
import useSnackbar from "../../Snackbar/SnackbarHook";
import {ExpiredOverlay, InactiveOverlay, InfoBox, InReviewOverlay} from "./MyPosts";
import PostsService from "../../../Services/PostsService";
import DeletePostConfirmation from "../../Posts/DeletePostConfirmation";

const TableHeader = ({children}) => {
    const theme = useTheme();

    return (
        <StyledTableHeader>
            <TableCell>Annonce</TableCell>
            <TableCell sx={{width: "100%"}}></TableCell>
            <TableCell>
                <VisibilityIcon
                    style={{
                        paddingTop: "6px",
                        color: "grey",
                        fontSize: "20px",
                    }}
                />
            </TableCell>
            <TableCell>
                <AccessTimeFilledIcon
                    style={{
                        paddingTop: "6px",
                        color: "grey",
                        fontSize: "20px",
                    }}
                />
            </TableCell>
            {children}
        </StyledTableHeader>
    );
};

const NoPosts = () => {
    const theme = useTheme();

    return <SideMenu>
        <InfoBox>
            <Typography id='AnonceNotFoundNotification'>
                Du har ingen annoncer på jagtbasen.dk, men det er nemt og hurtigt
                at <Link href="/opret-annonce">oprette en annonce her.</Link>
            </Typography>
        </InfoBox>
    </SideMenu>
}

export const MyPostsDesktop = ({posts, loading, fetchDataHandle}) => {
    const navigator = useNavigate();
    const getConfirmation = useConfirmationDialog();
    const snackbar = useSnackbar();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletePostId, setDeletePostId] = useState();

    function navigateTo(postId) {
        navigator("/post/" + postId);
    }

    const onDeletePost = async (postId) => {
        setDeletePostId(postId);
        setDeleteDialogOpen(true);
    }

    const onDeactivatePost = async (postId) => {
        const confirmed = await getDeactivatePostConfirmation(getConfirmation)

        if (confirmed) {
            const success = await PostsService.updatePostStatus(postId, false);

            if (!success) {
                snackbar.handleOpen("Fejl ved deaktivering", "error");
            } else {
                fetchDataHandle();
            }
        }

    }

    const onActivatePost = async (postId) => {
        const confirmed = await getActivatePostConfirmation(getConfirmation)

        if (confirmed) {
            const success = await PostsService.updatePostStatus(postId, true);

            if (!success) {
                snackbar.handleOpen("Fejl ved aktivering", "error");
            } else {
                fetchDataHandle();
            }
        }
    }

    // While fetching data
    if (loading) {
        return <SideMenu>
            <WebTableSkeleton>
                <TableHeader/>
            </WebTableSkeleton>
        </SideMenu>
    }

    // If user has no posts
    if (posts.length == 0) {
        return <NoPosts/>;
    }

    return (
        <SideMenu>
            <Table size="small">
                <TableBody>
                    <TableHeader>
                        <TableCell></TableCell>
                    </TableHeader>

                    {/* Table posts */}
                    {posts.map((post) => {
                        const to = "/post/" + post.PostId;
                        return (
                            <TableRow key={"user-posts-full-" + post.PostId}>
                                {/* Image */}
                                <NavCell to={to}>
                                    <StyledTableImage src={post.Images[0].Url} />
                                </NavCell>

                                {/* Body */}
                                <NavCell to={to}>
                                    <Box sx={{position: "relative"}}>
                                        {/* Any status overlay */}
                                        {post.Status == "InReview" && <InReviewOverlay/>}
                                        {post.Status == "Expired" && <ExpiredOverlay/>}
                                        {post.Status == "Inactive" && <InactiveOverlay/>}

                                        {/* Tier box */}
                                        {post.Tier != null &&
                                            <Box
                                                sx={{
                                                    borderRadius: "15px",
                                                    padding: "2px 7px 1px 7px",
                                                    border: "1px solid black",
                                                    display: "inline-block",
                                                    backgroundColor: post.Tier.Color,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {post.Tier.Name.charAt(0).toUpperCase() + post.Tier.Name.slice(1)}
                                            </Box>
                                        }
                                        <Space height="5px" />
                                        <StyledTableBody
                                            sx={{color: post.TextColor}}
                                            onClick={() => {
                                                navigateTo(post.PostId);
                                            }}
                                        >
                                            {post.Brand != null ? post.Brand + "," : ""}{" "}
                                            {post.Heading}, {post.Body}
                                        </StyledTableBody>
                                        <StyledTableSubTitle
                                            sx={{color: post.TextColor}}
                                            onClick={() => {
                                                navigateTo(post.PostId);
                                            }}
                                        >
                                            {post.Price} kr.
                                        </StyledTableSubTitle>
                                    </Box>
                                </NavCell>

                                {/* Visits */}
                                <TableCell>
                                    <Typography sx={{color: post.TextColor}}>
                                        {post.Visits}
                                    </Typography>
                                </TableCell>

                                {/* Status */}
                                <TableCell>
                                    <Typography sx={{color: post.TextColor}}>
                                        {post.Expired
                                            ? "Udløbet"
                                            : post.DaysLeftStr
                                        }
                                    </Typography>
                                </TableCell>

                                {/* Actions */}
                                <TableCell>
                                    <Link
                                        sx={{display: "block", cursor: "pointer"}}
                                        underline="hover"
                                        onClick={() => {
                                            navigator("/rediger-annonce/" + post.PostId)
                                        }}
                                    >
                                        Rediger
                                    </Link>
                                    <Link
                                        sx={{display: "block", cursor: "pointer"}}
                                        underline="hover"
                                        onClick={() => {
                                            if (post.Expired) return navigator("/rediger-annonce/" + post.PostId);
                                            if (post.Active) return onDeactivatePost(post.PostId);
                                            if (!post.Active) return onActivatePost(post.PostId);
                                        }}
                                    >
                                        {post.Expired
                                            ? "Genindryk"
                                            : post.Active
                                                ? "Deaktiver"
                                                : "Aktivér"
                                        }
                                    </Link>
                                    <Link
                                        sx={{display: "block", cursor: "pointer"}}
                                        color="primary"
                                        underline="hover"
                                        onClick={() => {
                                            onDeletePost(post.PostId);
                                        }}
                                    >
                                        Slet
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <DeletePostConfirmation deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen} postId={deletePostId} fetchDataHandle={fetchDataHandle} />
        </SideMenu>
    );
}