import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, Stack } from "@mui/material";
import React, { Fragment } from "react";
import Space from "../../Basic/Space";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function SafeSale() {
  return(
    <React.Fragment>
      <Space height="20px" />
      <Box>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography sx={{ m: 2 }} variant="h4">Sikkert salg</Typography>
        <Typography sx={{ m: 1 }}>
          1. Del aldrig personlige oplysninger med andre - Dette gælder både billeder af pas, kørekort eller udnyttelse af dit MitID
        </Typography>
        <Typography sx={{ m: 1 }}>
          2. Send ikke billeder af pas, kørekort eller sygesikringsbevis - og validér aldrig en anden persons brugerprofil.
      </Typography>
        <Typography sx={{ m: 1 }}>
          3. Man kan opleve at blive kontaktet af en ukendt person, som har brug for hjælp til at få sin konto valideret -  og som vil betale for din hjælp til validering. Du må aldrig validere en konto med dit MitID, som en anden person har adgang til eller har i sinde at bruge.
      </Typography>
        <Typography sx={{ m: 1 }}>
          4. Disse informationer kan misbruges til identitetstyveri, hvor andre kan købe varer eller svindle på nettet i dit navn. Det kan som ejer af identiteten være svært at bevise, at det ikke er dig der har foretaget ulovligheder - og kan i sidste ende være strafbart.
      </Typography>
        <Typography sx={{ m: 1 }}>
          5. I stedet anbefaler vi, at du foretager en MitID-validering af din egen profil samt beder den du handler med, gøre det samme. Dette er en god sikkerhed for både købe og sælger
    </Typography>
        <Typography sx={{ m: 1 }}>
          6. Har du mistanke om at din identitet er blevet misbrugt på nettet, så kontakt straks Hotline ved identitetstyveri - og er det sket på Jagtbasen, så kontakt også Jagtbasens Kundeservice
         </Typography>
        <Typography sx={{ m: 1 }}>
          7. Hotline ved identitetstyveri kan kontaktes på tlf.24 46 45 14 - eller læs mere her: <Link href="https://sikkerdigital.dk/hotline-ved-identitetstyveri">https://sikkerdigital.dk/hotline-ved-identitetstyveri</Link>
        </Typography>
      </Box>
    </React.Fragment>
  )
}