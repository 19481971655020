import {useSearchParams} from "react-router-dom";
import React from "react";
import Row from "../../Basic/Row";
import Space from "../../Basic/Space";
import {Button} from "@mui/material";
import {PriceFilterWeb} from "./PriceFilter";
import {LocationFilterDesktop} from "./LocationFilter";

export const FilterBarWeb = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const priceRef = React.useRef();
    const locationRef = React.useRef();

    /**
     * Note, it does not work to update the search parameters
     * independently from each filter, as these asynchronous updates
     * would interfere with each other. Instead, the filters must
     * return an object specifying the new search parameters for that filter
     * which can then be merged into on single update.
     */
    const onNewFilter = async () => {
        const priceParams = priceRef.current.onNewRange();
        const locationParams = await locationRef.current.onNewLocation();

        // Get all current search params except for price and location
        const currentParams = {};
        searchParams.forEach((value, key) => {
            if (key != "price" &&
                key != "latLng" &&
                key != "radius"
            ) {
                currentParams[key] = value;
            }
        });

        // exclude the page parameter, as this should be reset
        delete currentParams["page"];

        // Merge the new search parameters into one update
        const newParams = {
            ...currentParams,
            ...priceParams,
            ...locationParams,
        };

        setSearchParams(newParams);
    }

    return <Row alignItems="end">
        <PriceFilterWeb
            ref={priceRef}
        />
        <Space width="20px" />
        <LocationFilterDesktop
            ref={locationRef}
        />
        <Space width="20px"/>
        <Button
            variant="contained"
            color="jagtred"
            sx={{
                height: "30px",
                maxWidth: "30px",
                color: "#ffffff",
            }}
            onClick={() => {
                onNewFilter();
            }}
        >Vis</Button>
    </Row>
}