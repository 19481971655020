import accountService from "./AccountService";
import { reportException, reportMessage } from "../Utilities/sentry";

class UserVisitService {

    async recordUserVisit() {
        const jwt = await accountService.getCognitoJwt();
        const url = process.env.REACT_APP_API_URL + `/api/UserVisit`;

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${jwt}`,
                },
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                reportMessage('UserVisit service: Network response was not ok.');
            }
        } catch (error) {
            reportException(error);
        }
    }
}
export default new UserVisitService();