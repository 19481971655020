import { accountStore } from "../../Stores/AccountStore";

export function convertDateTimeToChatTime(timestamp) {
    // Create Date object from the timestamp
    const date = new Date(timestamp * 1000); // JavaScript uses milliseconds
    const now = new Date();

    // Define day names and month names
    const dayNames = ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];

    // Define helper function to format numbers to two digits
    function twoDigits(n) {
        return ('0' + n).slice(-2);
    }

    // E.g. 09:05
    const time = twoDigits(date.getHours()) + ':' + twoDigits(date.getMinutes());

    // If the date is from the current day
    if (date.toDateString() === now.toDateString()) {
        return time;
    }

    // If the date is within the last week approximately
    if (now - date < 6 * 24 * 60 * 60 * 1000) {
        return dayNames[date.getDay()] + ' ' + time;
    }

    // E.g. "21 Jun"
    let weekday = date.getDate() + ' ' + monthNames[date.getMonth()];

    // If the date is not within the same year
    if (date.getFullYear() !== now.getFullYear()) {
        return weekday + ' ' + date.getFullYear();
    }

    // If the date is from the same year
    return weekday;
}

function currentYear() {
   return new Date().getFullYear()
}
export const currentUserName = () => accountStore.userInfo.attributes.sub;
export const showLastMessage = (inbox) => {
    const message = inbox.Messages[inbox.Messages.length-1]
    const lastMessageText = message.MessageText;
    return (
      inbox.Messages[inbox.Messages.length-1].SenderUserId === currentUserName()
          ? "Dig: "+lastMessageText
          : lastMessageText
    )
}
export const inboxMessageUserName = (inbox) => {
    return inbox.VisitorUserId === currentUserName()? inbox.Post.User.Firstname.concat(" "+inbox.Post.User.Lastname) :
      inbox.User.Firstname.concat(" "+inbox.User.Lastname)
}


// Show bold when message is not read yet!
export function isBoldMessage(inbox) {
    const message = inbox.Messages[inbox.Messages.length-1]
    return (message.SenderUserId !== currentUserName() && !message.IsRead);
}