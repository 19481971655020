import {lotteryStore} from "../../../Stores/LotteryStore";
import postsService from "../../../Services/PostsService";
import {MobileContainer} from "../../Global/Global";
import {SwiperNavigationWrapper} from "../../../Utilities/postUtils";
import {accountStore} from "../../../Stores/AccountStore";
import Space from "../../Basic/Space";
import {PostCardDynamic, PostCardDynamicSkeletons} from "../../Posts/PostCard";
import SearchFilter from "../../Search/Filters/SearchFilter";
import {Title} from "../FrontPage";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import React, {Fragment, useEffect, useState} from "react";
import {Navigation, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import { Grid, styled } from "@mui/material";
import { bannerStore } from "../../../Stores/BannerStore";
import bannerService from "../../../Services/BannerService";
import {Banner} from "../../Basic/Banner";
import {Ad} from "../../Ads/Ad";

export const FrontPageMobile = observer(() => {
    const navigate = useNavigate();

    const [loadingSelected, setLoadingSelected] = useState(true);
    const [loadingLatest, setLoadingLatest] = useState(true);

    const [selectedPosts, setSelectedPosts] = useState([]);
    const [latestPosts, setLatestPosts] = useState([]);

    const lotteryMode = Object.keys(lotteryStore.lotteries).length > 0;

    const topBanner = bannerStore.banners["M1"];
    const topBannerRatio = "2/1";

    const middleBanner = bannerStore.banners["M2"];
    const middleBannerRatio = "5/2";

    const components = [];

    // Get selected posts from backend
    useEffect(() => {
        postsService.getSelectedPosts().then((posts) => {
            setSelectedPosts(posts)
            setLoadingSelected(false);
        });
        postsService.getLatestPosts().then((posts) => {
            setLatestPosts(posts)
            setLoadingLatest(false);
        });
    }, [])

    // If there are active lotteries
    components.push(
        <MobileContainer key="frontpage-swiper">
            <SwiperNavigationWrapper>
                <Swiper
                    navigation={true} // Show navigation arrows
                    slidesPerView={1}
                    modules={[Autoplay, Navigation]}
                    speed={500}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                >
                    {[
                        <SwiperSlide key={"frontpage-slide-1-mobile"}>
                            <Banner
                                aspectRatio={topBannerRatio}
                                style={{cursor: accountStore.logged ? "default" : "pointer"}}
                                onClick={() => {if (!accountStore.logged) navigate("/signup")}}
                                src={"https://jagtbasen.s3.eu-north-1.amazonaws.com/resources/frontpage-intro-mobile.png"}
                            />
                        </SwiperSlide>,
                        lotteryMode && (
                            <SwiperSlide key={"frontpage-slide-2-mobile"}>
                                <Banner
                                    aspectRatio={topBannerRatio}
                                    onClick={() => {navigate("/opret-annonce")}}
                                    style={{width: "100%", cursor: "pointer"}}
                                    src={"https://jagtbasen.s3.eu-north-1.amazonaws.com/resources/frontpage-banner-mobile.png"}
                                />
                            </SwiperSlide>
                        ),
                        topBanner != null && (
                            <SwiperSlide key={"frontpage-slide-3-mobile"}>
                                <Ad
                                    banner={topBanner}
                                    aspectRatio={topBannerRatio}
                                />
                            </SwiperSlide>
                        )
                    ]}
                </Swiper>
            </SwiperNavigationWrapper>
        </MobileContainer>
    )

    components.push(
        <Space key="frontpage-space-1" height="20px"/>
    )

    if (loadingSelected) {
        components.push(<React.Fragment key={"frontpage-premium-title"}>
            <MobileContainer>
                <Title title="Forsideannoncer"/>
            </MobileContainer>
            <PostCardDynamicSkeletons/>
            <Space height="10px"/>
        </React.Fragment>)
    }

    if (!loadingSelected) {
        components.push(<MobileContainer key={"frontpage-premium-images"}>
            <Title title="Forsideannoncer"/>
            <Grid container spacing={1}>
                {selectedPosts.map((post, index) => {
                    return <PostCardDynamic
                        key={"frontpage-premium-" + post.PostId}
                        postId={post.PostId}
                        imageUrl={post.Images[0]?.Url}
                        price={post.Price}
                        title={post.Heading}
                        subtitle={post.Body}
                        highlight={post.Highlight}
                    />
                })}
            </Grid>
            <Space height="10px"/>
        </MobileContainer>)
    }

    if (loadingLatest) {
        components.push(<React.Fragment key={"frontpage-latest-title"}>
            <MobileContainer>
                <Title title="Sidst oprettet"/>
            </MobileContainer>
            <PostCardDynamicSkeletons/>
            <Space height="10px"/>
        </React.Fragment>)
    }

    if (!loadingLatest) {
        components.push(<MobileContainer key={"frontpage-latest-images"}>
            <Ad
                banner={middleBanner}
                aspectRatio={middleBannerRatio}
            />
            <Space height="15px"/>
            <Title title="Sidst oprettet"/>
            <Grid container spacing={1}>
                {latestPosts.map((post, index) => {
                    return <PostCardDynamic
                        key={"frontpage-latest- " + post.PostId}
                        postId={post.PostId}
                        imageUrl={post.Images[0]?.Url}
                        price={post.Price}
                        title={post.Heading}
                        subtitle={post.Body}
                        highlight={post.Highlight}
                    />
                })}
            </Grid>
            <Space height="10px"/>
        </MobileContainer>)
    }

    return <Fragment>
        <MobileContainer>
            <SearchFilter sx={{margin: "20px 0 10px 0"}}/>
        </MobileContainer>
        <Space height="15px"/>
        {components}
    </Fragment>;
})