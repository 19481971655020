import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@mui/material";
import { observer } from "mobx-react";
import {accountStore} from "../../Stores/AccountStore";

const DAWAAutocomplete = ({ register, errors, id, label, options, watch, maxWidth, type, fullwidth, defaultAddressValue, disabled=false }) => {
    const addressRegex = /^[a-zA-ZæøåÆØÅ0-9\s.,'-]+\s\d{4}\s[a-zA-ZæøåÆØÅ\s]+$/;
    const [addressOptions, setAddressOptions] = useState([]);
  useEffect(() => {
    const subscription = watch((data) => {
      if(!data.address) {
        setAddressOptions([]);
      } else {
        // Make a request to the DAWA API to get autocomplete suggestions
          const fetchData = async (address) => {
              try {
                  const response = await fetch(`https://api.dataforsyningen.dk/autocomplete?q=${address}`);
                  const data = await response.json();
                  setAddressOptions(data);
              } catch (error) {
                  console.error('Error fetching data:', error);
              }
          };

          fetchData(data.address);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Autocomplete
      disableClearable
      id="dawa"
      options={addressOptions.map((option) => option.forslagstekst)}
      defaultValue={defaultAddressValue}
      filterOptions={(x) => x}
      noOptionsText="Ingen mulighed"
      onChange={(event, newValue) => {
          if (!!newValue)
            if (addressRegex.test(newValue))
                errors["address"] = null
      }}
      fullWidth={!!fullwidth}
      renderInput={(params) =>
        <TextField {...params}
         style={ !!maxWidth ? { maxWidth: maxWidth } : { maxWidth: "20rem" } }
         variant="outlined"
         fullWidth={!!fullwidth}
         error={!!errors[id]}
         label={label}
         type={!!type ? type : undefined}
         helperText={errors[id] && errors[id].message}
         {...register(id, options)}
        />}
    />
  );
};
export default observer(DAWAAutocomplete);