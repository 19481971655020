import {styled} from "@mui/material";

interface formatNumberParams {
    value: string,
    maxLength?: number,
    excludeZero?: boolean,
    useComma?: boolean
}

export const formatNumber = ({
    value,
    maxLength = 10,
    excludeZero = false,
    useComma = true
}: formatNumberParams): string => {
    // Remove all characters but digits 0-9
    value = value.replace(/[^0-9]/g, "");

    // Make sure the length is no longer than maxLength
    value = value.substring(0, maxLength);

    // Remove leading zeros
    if (excludeZero) {
        value = value.replace(/^0+/, "");
    }

    // Calculate number of commas needed
    const commas = Math.floor((value.length - 1) / 3);
    const length = value.length;

    // Insert commas
    if (useComma) {
        for (let i = 0; i < commas; i++) {
            const split = length - i * 3 - 3;
            value = value.slice(0, split) + "." + value.slice(split);
        }
    }

    return value;
};

export const formatZip = (value: string) => {
    // Remove all characters but digits 0-9
    value = value.replace(/[^0-9]/g, "");

    // Make sure the length is max 4
    value = value.substring(0, 4);

    return value;
};


/**
 * Sort posts based on activation and creation time
 * Sorts the object given in place - does not return a new object
 */
export function sortPosts(posts: [any]) {
    return posts.sort((a, b) => {
        if (a.ActivationTime > b.ActivationTime) return -1;
        if (b.ActivationTime > a.ActivationTime) return 1;
        if (a.CreationTime > b.CreationTime) return -1;
        else return 1;
    });
}

/**
 * Sort images based on the order attribute
 * Sorts the object given in place - does not return a new object
 * Example input: [{url: "...", order: 1}, {url: "...",order: 2}, {url: "...",order: 0}]
 */
export function sortImages(images: [any]) {
    return images.sort((a, b) => {
        if (a.order > b.order) return 1
        else return -1;
    })
}

/*
  Wrapping a <Swiper> component with this will add custom styling to the navigation arrows
 */
export const SwiperNavigationWrapper = styled("div")({
    // Right navigation arrow
    "& .swiper-button-next": {
        borderRadius: "60px 0px 0px 60px",
        padding: "12px 5px 12px 12px",
        backgroundColor: "white",
        opacity: "0.8",
        color: "black",
        marginTop: "calc(0px - (var(--swiper-navigation-size)/ 2) - 12px)",
        "--swiper-navigation-size": "25px",
        "--swiper-navigation-sides-offset": "0px",
        "&:hover": {
            opacity: "1",
        }
    },

    // Left navigation arrow
    "& .swiper-button-prev": {
        borderRadius: "0px 60px 60px 0px",
        padding: "12px 12px 12px 5px",
        backgroundColor: "white",
        opacity: "0.8",
        color: "black",
        marginTop: "calc(0px - (var(--swiper-navigation-size)/ 2) - 12px)",
        "--swiper-navigation-size": "25px",
        "--swiper-navigation-sides-offset": "0px",
        "&:hover": {
            opacity: "1",
        }
    },

    // Disabled navigation arrows
    "& .swiper-button-disabled": {
        pointerEvents: "auto !important",
        opacity: "0.0 !important",
    },
})


export const isValidZip = (zip: string): boolean => {
    return zip.length === 4 && !isNaN(parseInt(zip))
}

export const roundUpToNearestHundred = (ore: number) => {
    const remainder = ore % 100;
    if (remainder === 0) {
        return ore;
    }
    return ore + (100 - remainder);
}


// Will remove decimals, e.g. 5740 will equal 58
export const oreToKroner = (ore: number) => {
    return roundUpToNearestHundred(ore) / 100
}
