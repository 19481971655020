import {TextField} from "@mui/material";
import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form/dist/types";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

const InputTextArea: FC<{
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors;
    id: string;
    label: string;
    options: RegisterOptions;
    disabled: boolean;
    defaultValue: string;
}> = ({
          register,
          errors,
          id,
          label,
          options,
          disabled = false,
          defaultValue,
      }) => {
    return (
        <TextField
            sx={{
                "& .MuiInputBase-root": {
                    backgroundColor: "white",
                }
            }}
            style={{maxWidth: "20rem"}}
            fullWidth
            multiline={true}
            rows={4}
            error={errors[id] ? true : false}
            label={label}
            helperText={errors[id] && errors[id]?.message}
            disabled={disabled}
            {...register(id, options)}
            defaultValue={defaultValue}
        />
    );
};

export default observer(InputTextArea);
