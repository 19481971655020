import {observer} from "mobx-react-lite";
import {useMediaContext} from "../../MediaContext";
import TopBarDesktop from "./Web/TopBar/TopBarDesktop";
import TopBarMobile from "./Mobile/TopBarMobile";

const TopBar = () => {
    const mediaContext = useMediaContext();

    if (mediaContext.isMobile) {
        return (
            <TopBarMobile/>
        );
    } else {
        return (
            <TopBarDesktop/>
        );
    }
}

export default observer(TopBar);