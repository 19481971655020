import {makeAutoObservable, runInAction} from "mobx";
import ChatService from "../Services/ChatService";
import { accountStore } from "./AccountStore";

class ChatStore {
  inbox = null;
  inboxes = [];
  userInboxes = [];
  isInboxClicked = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getUserInboxes() {
    let result = await ChatService.fetchInboxesUser();
    runInAction(() => {
      this.userInboxes = result;
    });
  }

  async resetInbox(post) {
    let result = await ChatService.fetchInboxes(post.PostId);
    runInAction(() => {
      this.inbox = null;
      this.inboxes = result;
    });
  }

  async getInbox(inboxId) {
    let result = await ChatService.fetchInbox(inboxId);
    if (result !== null) {
      runInAction(() => {
        this.inbox = result;
      });
    }
    await this.updateRead();
    return "Jagtbasen";
  }

  async sendMessage(text, senderId) {
    let result = await ChatService.sendMessage(
      this.inbox.InboxId,
      text,
      senderId
    );
    await this.updateInbox();
    return result;
  }

  async createInbox(post, visitorUserId, messageText) {
    let result = await ChatService.createInbox(
      post.PostId,
      visitorUserId,
      messageText
    );
    if (result != null) this.inbox = result;
    return "no result";
  }

  resetInboxAndChat() {
    if (!this.isInboxClicked){
      this.inbox = null;
    }
    this.inboxes = [];
  }

  async getChatByPost(post) {
    this.resetInboxAndChat();
      await Promise.all([
        ChatService.fetchInboxes(post.PostId),
      ]).then((values) => {
        runInAction(() => {
          post.CreationDate = this.timestampToDate(post.CreationTime);
          if (values[0]) {
            if (values[0] !== null) {
              if (values[0].length === 1) {
                values[0][0].Messages.reverse();
                this.inbox = values[0][0];
              } else {
                this.inboxes = values[0];
              }
            }
          }
        });
      });
    await this.updateRead();
  }

  async updateInbox() {
    let result = await ChatService.fetchInbox(this.inbox.InboxId);
    if (result !== null) {
      runInAction(() => {
        this.inbox = result;
      });
    }
    await this.updateRead();
    return "Jagtbasen";
  }

  /*
  *  Updates the status of last message.
  *  If user is not current user and the status is 'not read'.
  */
  async updateRead() {
    if (this.inbox != null && !this.inbox.Messages[0].IsRead && this.inbox.Messages[0].SenderUserId !== accountStore.userInfo.attributes.sub) {
      await ChatService.updateRead(this.inbox.InboxId);
    }
  }

  /**
   * Converts Unixtimestamp to readable date
   * @param timestamp unix timestamp
   * @returns {string} date: "2/4 12:45"
   */
  timestampToDate(timestamp) {
    let date = new Date(timestamp * 1000);
    const month = date.toLocaleString("da-DK", { month: "long" });
    const time = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }).replace(".",":")
    return (
      "" +
      date.getDate() +
      ". " +
      month +
      " kl. " +
      time
    );
  }
}

export const chatStore = new ChatStore();
