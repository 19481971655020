import Space from "../../Basic/Space";
import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/material";
import Divider from "../../Basic/Divider";
import PictureRender from "../../Shared/Pictures/PictureRender";
export function FirmaKontakt() {
  return (

    <React.Fragment>
      <Space height="20px" />
      <Container>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography variant="h4">Kontakt Jagtbasen, Erhverv</Typography>
        <Space height="10px" />
        <Typography variant="h5">Jagtbasen ApS</Typography>
        <Space height="5px" />
        <Divider />
        <Space height="10px" />
        <Stack>

          <Stack direction={"row"}>
            <Typography fontWeight={"bold"}>CVR-nr.:</Typography>
            <Space width="5px" />
            <Typography>43924702</Typography>
          </Stack>
          <Space height="10px" />
          <Typography fontWeight={"bold"}>Adresse:</Typography>
          <Typography>Ny Østergade 9, 1.</Typography>
          <Typography>3600 Frederikssund</Typography>
          <Space height="5px" />
          <Stack direction={"row"}>
            <Typography fontWeight={"bold"}>Email.:</Typography>
            <Space width="5px" />
            <Typography>info@jagtbasen.dk</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography fontWeight={"bold"}>Tlf nr.:</Typography>
            <Space width="5px" />
            <Typography>91528585</Typography>
          </Stack>
        </Stack>
      </Container>
    </React.Fragment>
  );
}

