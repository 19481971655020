import { observer } from "mobx-react-lite";
import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Analytics, Auth} from "aws-amplify";
import Container from '@mui/material/Container';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Link,
  Stack
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm } from "react-hook-form";
import { accountStore } from "../../../Stores/AccountStore";
import InputText from "../../Form/InputText";
import useSnackbar from "../../Snackbar/SnackbarHook";
import PasswordField from "../../Form/PasswordField";
import {Fragment, useEffect} from "react";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "../../Form/LoadingButton";
import Space from "../../Basic/Space";
import {LoginContainer} from "../Login/Login";
import {useLocation} from "react-router-dom";
import {useMediaContext} from "../../../MediaContext";
import {reportException} from "../../../Utilities/sentry";
import {useNavigate, useSearchParams} from "react-router-dom";

function SignUp() {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm();
  const location = useLocation();
  const validation = useForm();
  const validationHandleSubmit = validation.handleSubmit;
  const [user, setUser] = React.useState({}); // User
  const [open, setOpen] = React.useState(false); // Verification code dialog
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordvalid, setPasswordvalid] = React.useState(false);
  const [validationButtonShow,setValidationButtonShow] = React.useState(false);
  const mediaContext = useMediaContext();
  const isMobile = mediaContext.isMobile;
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigateTo = (path) => {
    navigate(path)
  }

  const passwordRegex = /^(?=.*\d)(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?!.*[^a-zA-Z0-9_\u00C0-\u017F!@#$%^&*\-]).{8,}$/;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const snackbar = useSnackbar();

  const handleClose = () => {    // Verification Dialog close
    setOpen(false);
    setLoading(false);
  };

  const email = watch("email");

  const sendValidation = async () => {
    try {
      await Auth.resendSignUp(email);
      setOpen(true);
    } catch (err) {
      if (err.code === "UserNotFoundException"){
        snackbar.handleOpen("E-mail forkert", "error")
      } else {
        reportException(err);
      }
    }
  }

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.password === data.confirmPassword && data.password.match(passwordRegex)) {
        setPasswordvalid(true);
      } else {
        setPasswordvalid(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    Analytics.record({
      name: "Sign up - started"
    })
  }, [])

  const onSubmit = async data => {
    setLoading(true);
    let username = data.email;
    let password = data.password;
    let email = data.email;
    setUser(data);

    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: email
        }
      });
      setValidationButtonShow(true);
      setOpen(true);

    } catch (error) {
      console.log(error);

      if (error.code === "UsernameExistsException") {
        try {
          const tempuser = await Auth.signIn(username, password);

          const checkUser = await accountStore.checkUserAtJagtbasenDB(tempuser.username);
          if (!!checkUser) {
            snackbar.handleOpen("Bruger eksisterer allerede", "info");
            setLoading(false);
            navigate(`/log-ind?username=${username}`, {state: {from: location.state?.from}});
          } else {
            setLoading(false);

            searchParams.set("signUpStatus", "verified");
            setSearchParams(searchParams);
          }

        } catch (err) {
          if (err.code === "UserNotConfirmedException") {
            snackbar.handleOpen("Verificér venligst", "warning");
            setValidationButtonShow(true);
            await Auth.resendSignUp(username);
            setOpen(true);
            return;
          }
          if (err.code === "NotAuthorizedException") {
            //snackbar.handleOpen("Adgangskode forkert", "error");
            snackbar.handleOpen("Bruger eksisterer allerede", "info");
            setLoading(false);
            navigate(`/log-ind?username=${username}`, {state: {from: location.state?.from}});
            return;
          }

          reportException(err);
        }
      } else {
        reportException(error);
        console.error(error);
      }
    }
  };

  const onVerification = async data => {
    try {
      const confirm = await Auth.confirmSignUp(user.email, data.verificationCode);
      if (confirm === "SUCCESS") {
        setOpen(false);
        setValidationButtonShow(false);
        setTimeout(async () => {
          try {
            await accountStore.loginCognito(user.email, user.password);
            setUser({});
            searchParams.set("signUpStatus", "verified");
            setSearchParams(searchParams);
          } catch (e) {
            if (e.code === "NotAuthorizedException") {
              snackbar.handleOpen("Adgangs kode forkert", "error");
              setOpen(true);
              return;
            }

            reportException(e);
          }

        }, 2000);
      }
    } catch (err) {
      if (err.code === "CodeMismatchException") {
        snackbar.handleOpen("Forkert verifications kode", "warning");
      } else {
        reportException(err);
        console.log(err)
      }
    }
  };

  return (
    <Fragment>
      <LoginContainer>
        <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth="xs">
        <Fragment>
          <Typography component="h1" variant="h4">
            Opret konto
          </Typography>

          <Typography
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                }
              }}
              onClick={() => navigate("/log-ind", {state: {from: location.state?.from}})}
          >
            Har du allerede en bruger? Log ind her
          </Typography>

          <React.Fragment>
            <Space height="20px"/>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
              <InputText
                  id="email"
                  autoComplete="email"
                  maxWidth="100%"
                  label="E-mail adresse"
                  register={register}
                  options={{
                    required: "Påkrævet",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Ugyldig E-mail"
                    }
                  }}
                  errors={errors}
              />


              <Box sx={{ width: "100%", position: "relative" }}>
                <PasswordField
                    id="password"
                    label="Adgangskode"
                    autoComplete="new-password"
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    register={register}
                    errors={errors}
                    options={{
                      required: "Påkrævet",
                      pattern: {
                        value: passwordRegex,
                        message: "Et stort bogstav, et tal og 8 karakter"
                      }
                    }}
                />
                {passwordvalid && (
                    <CheckIcon
                      color="success"
                      fontSize="medium"
                      sx={{
                        position: "absolute",
                        top: "20%",
                        marginLeft: "5px"
                    }}
                    />
                )}
              </Box>


              <Box sx={{ width: "100%", position: "relative" }}>
                <PasswordField
                    id="confirmPassword"
                    label="Bekræft adgangkode"
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    register={register}
                    errors={errors}
                    options={{
                      required: "Påkrævet",
                      pattern: {
                        value: passwordRegex,
                        message: "Et stort bogstav, et tal og 8 karakter"
                      },
                      validate: (value) => {
                        if (value !== getValues("password"))
                          return "Adgangskode ikke ens";
                      }
                    }}
                />
                {passwordvalid && (
                    <CheckIcon
                        color="success"
                        fontSize="medium"
                        sx={{
                          position: "absolute",
                          top: "20%",
                          marginLeft: "5px"
                        }}
                    />
                )}
              </Box>
              <LoadingButton
                  type="submit"
                  color="info"
                  fullWidth
                  loading={loading}
                  label="Opret bruger"
                  disabledColor="disabledRed"
              />

              {validationButtonShow &&
                  <Button
                      color="warning"
                      fullWidth
                      variant="contained"
                      onClick={sendValidation}
                  >
                    SEND VALIDATION
                  </Button>
              }

            </Stack>
          {isMobile ?
            <Box sx={{mt:2}}>
              <Typography variant="body2">Ved at fortsætte accepterer jeg <Link sx={{cursor: "pointer"}} color="#5e1022" underline="hover" onClick={() => {navigateTo("/vilkaar-betingelser");}}>
               vilkårene & betingelserne
              </Link>, <Link sx={{cursor: "pointer"}} color="#5e1022"  underline="hover" onClick={() => {navigateTo("/gdpr-cookies");}} >
               persondata & cookiepolitik
              </Link> og <Link sx={{cursor: "pointer"}} color="#5e1022" underline="hover" onClick={() => {navigateTo("/handel-med-vaaben");}} >
               handel med våben
              </Link> for Jagtbasen
              </Typography>
            </Box>
            :
            <Box sx={{mt:2}}>
            <Typography variant="body2">Ved at fortsætte accepterer jeg <Link color="#5e1022" underline="hover" component="button" onClick={() => window.open("/vilkaar-betingelser", "vilkaar-betingelser", "width=600,height=1200")}>
                <Typography variant="body2">vilkårene & betingelserne</Typography>
              </Link>, <Link color="#5e1022" component="button" underline="hover" onClick={() => window.open("/gdpr-cookies", "gdpr-cookies", "width=600,height=1200")}>
                <Typography variant="body2">persondata & cookiepolitik</Typography>
              </Link> og <Link color="#5e1022" underline="hover" component="button" onClick={() => window.open("/handel-med-vaaben", "handel-med-vaaben", "width=600,height=1200")}>
                <Typography variant="body2">handel med våben</Typography>
              </Link> for Jagtbasen
            </Typography>
            </Box>
        }</React.Fragment>
          <FormProvider {...validation}>
            <Dialog open={open}>
              <DialogTitle>
                Verificér
                <IconButton
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8
                    }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                  <Typography>Indtast venligst den kode vi har sendt til din e-mail</Typography>
                  <Space height="5px"/>
                  <Typography sx={{fontSize: "14px"}}>(Husk at tjekke dit spamfilter)</Typography>
                </DialogContentText>
                <InputText
                    id="verificationCode"
                    maxWidth="100%"
                    label="Verifikationskode"
                    register={validation.register}
                    options={{
                      required: "Påkrævet",
                      pattern: {
                        value: /^\d{6}$/,
                        message: "Indtast seks cifrede kode"
                      }
                    }}
                    errors={validation.formState.errors}
                />
              </DialogContent>
              <DialogActions>
                <Button
                    type="submit"
                    onClick={validationHandleSubmit(onVerification)}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </FormProvider>
        </Fragment>
        </Container>
      </LoginContainer>
    </Fragment>
  );
}

export default observer(SignUp);