import {lotteryStore} from "../../../Stores/LotteryStore";
import {SwiperNavigationWrapper} from "../../../Utilities/postUtils";
import {accountStore} from "../../../Stores/AccountStore";
import Space from "../../Basic/Space";
import {MobileContainer} from "../../Global/Global";
import Row from "../../Basic/Row";
import {CategoryFilterWeb} from "../../Search/Filters/CategoryFilter";
import SelectedPosts from "./Sliders/SelectedPosts";
import LatestPosts from "./Sliders/LatestPosts";
import React, {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Navigation, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import { Box, styled } from "@mui/material";
import FrontpageMix from "./FrontpageMix";
import { bannerStore } from "../../../Stores/BannerStore";
import {Ad} from "../../Ads/Ad";
import {Banner} from "../../Basic/Banner";

export const FrontPageDesktop = observer(() => {
    const navigate = useNavigate();
    const lotteryMode = Object.keys(lotteryStore.lotteries).length > 0;
    const bannerLocation = "D1"
    const banner = bannerStore.banners[bannerLocation];
    const aspectRatio = "4/1";

    return (
        <Fragment>
            {/* Top banner */}
            <SwiperNavigationWrapper>
                <Swiper
                    navigation={true} // Show navigation arrows
                    slidesPerView={1}
                    modules={[Autoplay, Navigation]}
                    speed={500}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                >
                    {[
                        <SwiperSlide key={"frontpage-slide-1"}>
                            <Banner
                                aspectRatio={aspectRatio}
                                style={{cursor: accountStore.logged ? "default" : "pointer"}}
                                onClick={() => {if (!accountStore.logged) navigate("/signup")}}
                                src={"https://jagtbasen.s3.eu-north-1.amazonaws.com/resources/frontpage-intro.png"}
                            />
                        </SwiperSlide>,
                        lotteryMode && (
                            <SwiperSlide key={"frontpage-slide-2"}>
                                <Banner
                                    aspectRatio={aspectRatio}
                                    onClick={() => {navigate("/opret-annonce/")}}
                                    style={{cursor: "pointer"}}
                                    src={"https://jagtbasen.s3.eu-north-1.amazonaws.com/resources/frontpage-banner.png"}
                                />
                            </SwiperSlide>
                        ),
                        banner != null && (
                            <SwiperSlide key={"frontpage-slide-3"}>
                                <Ad banner={banner} aspectRatio={aspectRatio}/>
                            </SwiperSlide>
                        )
                    ]}
                </Swiper>
            </SwiperNavigationWrapper>

            <Space height="35px"/>

            <MobileContainer>
                <Row >
                    <CategoryFilterWeb />
                    <Box sx={{flexGrow: 1, overflow: "hidden"}}>
                        <SelectedPosts/>
                        <Space height="35px"/>
                        <LatestPosts/>
                        <Space height="35px"/>
                        <FrontpageMix/>
                    </Box>
                </Row>
            </MobileContainer>
        </Fragment>
    );
})