import {
    Box,
    Divider,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Fragment, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {chatStore} from "../../../Stores/ChatStore";
import {SideMenu} from "../MySiteDesktop";
import {useMediaContext} from "../../../MediaContext";
import Space from "../../Basic/Space";
import IconButton from "@mui/material/IconButton";
import {SpinIcon} from "../../Basic/SpinIcon";
import Row from "../../Basic/Row";
import Column from "../../Basic/Column";
import { Ellipsis, NavCell, StyledTableImage } from "../../Shared/Shared";
import * as PropTypes from "prop-types";
import {BlueDot} from "../../Basic/Dot";
import {convertDateTimeToChatTime, inboxMessageUserName, isBoldMessage, showLastMessage} from "../../Chat/common";
import {MobileContainer} from "../../Global/Global";
import { useNavigate } from "react-router-dom";

const InboxListItem = ({inbox}) => {
    const isBold = isBoldMessage(inbox);
    const to = "/post/" + inbox.PostId;
    const onClick = async () => {
        chatStore.isInboxClicked = true;
        await chatStore.getInbox(inbox.InboxId)
    };

    return <TableRow hover={true}>
        {/* Image */}
        <NavCell
            to={to}
            onClick={onClick}
            sx={{ width: "170px" }}
        >
            <StyledTableImage src={inbox.Post.Images[0].Url} />
        </NavCell>

        {/* Body */}
        <NavCell to={to} onClick={onClick} >
            <Row sx={{justifyContent: "space-between", alignItems: "center"}}>
                <Column sx={{width: "calc(100% - 35px)"}}>
                    {/* Title */}
                    <Ellipsis
                        sx={{
                            cursor: "pointer",
                            color: "grey.600"
                        }}
                    >
                        {inbox.Post.Heading}
                    </Ellipsis>

                    <Space height="5px"/>

                    {/* User name */}
                    <Ellipsis
                        sx={{
                            fontWeight: "bold",
                            cursor: "pointer"
                        }}
                    >
                        {inboxMessageUserName(inbox)}
                    </Ellipsis>

                    <Row sx={{
                        alignItems: "center"
                    }}>
                        {/* Last message */}
                        <Ellipsis
                            sx={{
                                fontWeight: isBold ? "bold" : "normal",
                                cursor: "pointer"
                            }}
                        >
                            {showLastMessage(inbox)}
                        </Ellipsis>
                    </Row>
                    {/* Time */}
                    <Typography sx={{fontSize: "14px"}}>
                        {convertDateTimeToChatTime(
                            inbox.Messages[inbox.Messages.length-1].Time
                        )}
                    </Typography>
                </Column>

                {/* Status dot */}
                {isBold && (
                    <Row>
                        <Space width="10px"/>
                        <BlueDot/>
                        <Space width="10px"/>
                    </Row>
                )}
            </Row>
        </NavCell>
    </TableRow>
};

function TableHeader(props) {
    return null;
}

TableHeader.propTypes = {children: PropTypes.node};

const InboxSkeleton = () => {
    const isMobile = useMediaContext().isMobile;

    let rows = [];
    for (let i = 0; i < 3; i++) {
        rows.push(
            <TableRow key={"user-inbox-desktop-loading-" + i}>
                {/* Image */}
                <TableCell sx={{width: "150px", padding: isMobile ? "5px 0" : undefined}}>
                    <Skeleton
                        variant="rectangular"
                        width="150px"
                        height="100px"
                        sx={{
                            borderRadius: "10px",
                            marginTop: "6px",
                            marginBottom: "6px",
                        }}
                    />
                </TableCell>

                {/* Body */}
                <TableCell sx={{width: "100%", paddingRight: isMobile ? 0 : undefined}}>
                    <Skeleton variant="text" sx={{maxWidth: "125px"}} />
                    <Skeleton variant="text" sx={{maxWidth: "250px"}} />
                    <Skeleton variant="text" sx={{maxWidth: "75px"}} />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <MobileContainer>
            <Table size="small" sx={{tableLayout: "fixed"}}>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </MobileContainer>
    );
}

function Inbox() {
    const navigate = useNavigate();
    const mediaContext = useMediaContext();

    const spinRef = useRef();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getInboxes(){
            setLoading(true);
            await chatStore.getUserInboxes();
            setLoading(false);
        }
        getInboxes();
    }, [])

    const handleRefresh = async () => {
        spinRef.current.spin()
        setLoading(true);
        await chatStore.getUserInboxes();
        setLoading(false);
    }

    const handleClick = (postId) => {
        chatStore.isInboxClicked = true;
        navigate('/post/' + postId)
    }

    return (
        <SideMenu hide={mediaContext.isMobile}>
            {mediaContext.isMobile && (
                <Space height="20px"/>
            )}

            <Column>
                {/* Title and reload icon */}
                <MobileContainer>
                    <Row sx={{ width: "100%", justifyContent: 'space-between'}}>
                        <Typography component="h1" variant="h4" sx={{mb: 1}}>
                            Indbakke
                        </Typography>
                        <Box>
                            <IconButton onClick={handleRefresh}>
                                <SpinIcon ref={spinRef}/>
                            </IconButton>
                        </Box>
                    </Row>
                </MobileContainer>

                <Divider/>

                {/* Loading */}
                {loading && (
                    <InboxSkeleton/>
                )}

                {/* Not loading */}
                {!loading && (
                    <Fragment>
                        {/* No messages */}
                        {chatStore.userInboxes.length === 0 && (
                            <Fragment>
                                <Space height="10px"/>
                                <Typography component="h1" variant="h5" sx={{mb: 1}}>
                                    Du har ingen beskeder
                                </Typography>
                            </Fragment>
                        )}

                        {/* Inbox table */}
                        <Table size="small" sx={{tableLayout: "fixed"}}>
                            <TableBody>
                                {chatStore.userInboxes.map((inbox, index) => {
                                    return <InboxListItem key={"inbox-item-" + index} inbox={inbox}/>
                                })}
                            </TableBody>
                        </Table>
                    </Fragment>
                )}
            </Column>
        </SideMenu>
    );
}

export default observer(Inbox);