import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import Space from "../Basic/Space";
import {reportException, reportMessage} from "../../Utilities/sentry";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100vh',
    background: theme.palette.background.default,
  },
  logo: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(3),
  },
}));

function UnsubscribePage() {
  const classes = useStyles();
  const { unSubscribeToken } = useParams();
  const { id } = useParams();
  const [message,setMessage] = useState("Afmelding igang ...")
  useEffect(() => {
    const url = process.env.REACT_APP_API_URL +`/api/EmailNotification/${unSubscribeToken}/${id}`;

    fetch(url, { method: 'POST' })
      .then((response) => {
        if (response.ok) {
          setMessage("Du har afmeldt e-mail-notifikationer på annoncen. Dermed, vil du ikke længere modatage notifikationer på mail.")
        } else {
            reportMessage(
            "Received status != 200 during UnsubscribePage", {options : {
                    status: response.status,
                    message: response.statusText,
                    response: response.clone().text(),
                    url: JSON.stringify(url)
                }}
            );
            response.text().then((errorMessage) => {
                setMessage('Fejl ved afmelding: ' + errorMessage);
            });
        }
      })
      .catch((error) => {
        reportException(error, {options: {
          url: url
        }});
        setMessage('Fejl ved afmelding, prøve igen!')
      });
  }, [unSubscribeToken]);
  return (
    <div className={classes.root}>
      <stack>
        <br/>
        <img src="/Images/Logos/Round/Text/115w/230w.png" alt="Logo" className={classes.logo} />
        <Typography variant="h4" align="left" gutterBottom>
          Afmeld "Private beskeder" på mail
        </Typography>
        <Space/>
        <Typography variant="h6" align={"left"} gutterBottom>
          {message}
        </Typography>
        <Space/>
        <Space/>
        <br/>
        <Button
          component="a"
          href={process.env.REACT_APP_URL}
          target="_blank"
          variant="contained"
          sx={{
            backgroundColor: '#5E1022FF',
            borderRadius: 4,
            color: '#ffffff',
            display: 'inline-block',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '49px',
            textAlign: 'center',
            textDecoration: 'none',
            width: 220,
          }}
        >
          Gå til Forside
        </Button>
      </stack>
    </div>
  );
}

export default observer(UnsubscribePage);

