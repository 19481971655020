import React from "react";
import {Box, Divider, Typography} from "@mui/material";
import {dateToString} from "../../Utilities/dateUtils";
import Link from "@mui/material/Link";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";


const EditBarWeb = ({endDate, handleDeactivatePost, handleActivatePost, handleDeletePost, post }) => {
    const navigate = useNavigate();
    return <React.Fragment>
        <Box
            sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
                boxSizing: "border-box",
            }}
        >
            {/* STATUS */}
            <Typography sx={{fontSize: "1.2rem"}}>Din annonce</Typography>
            <Divider sx={{margin: "5px 0px"}}/>
            <Typography sx={{fontSize: "1.1rem", fontWeight: "bold"}}>
                Status
            </Typography>

            {/* If the post is not paid */}
            {!post.Paid && (
                <React.Fragment>
                    <Typography>Status: inaktiv</Typography>
                </React.Fragment>
            )}

            {/* If the post is paid */}
            {post.Paid && (
                <React.Fragment>
                    <Typography>
                        Status: {post.Expired
                        ? "udløbet"
                        : post.Active
                            ? "aktiv"
                            : "inaktiv"
                    }
                    </Typography>
                    {!post.Expired && (
                        <Typography>Slutdato: {dateToString(endDate)}</Typography>
                    )}
                    <Typography>
                        Annoncetype: {post.Tier?.Name || "-"}
                    </Typography>
                </React.Fragment>
            )}

            {/* ADMINISTRATE */}
            <Typography
                sx={{fontSize: "1.1rem", fontWeight: "bold", paddingTop: "5px"}}
            >
                Administrer din annonce
            </Typography>
            <div style={{display: "block"}}>
                <Link
                    sx={{lineHeight: "24px", cursor: "pointer"}}
                    underline="hover"
                    onClick={() => {
                        navigate("/rediger-annonce/" + post.PostId);
                    }}
                >
                    Rediger
                </Link>
            </div>

            {/* Only allow users to active/deactive paid posts */}
            {post.Paid && (
                <div style={{display: "block"}}>
                    <Link
                        sx={{lineHeight: "24px", cursor: "pointer"}}
                        underline="hover"
                        onClick={async () => {
                            if (post.Expired) return navigate("/rediger-annonce/" + post.PostId);
                            if (post.Active) return handleDeactivatePost(post.PostId);
                            if (!post.Active) return handleActivatePost(post.PostId);
                        }}
                    >
                        {post.Expired
                            ? "Genindryk"
                            : post.Active
                                ? "Deaktiver"
                                : "Aktivér"}
                    </Link>
                </div>
            )}
            <div style={{display: "block", cursor: "pointer"}}>
                <Link
                    sx={{lineHeight: "24px"}}
                    color="primary"
                    underline="hover"
                    onClick={async () => {
                        handleDeletePost(post.PostId);
                    }}
                >
                    Slet
                </Link>
            </div>
        </Box>
    </React.Fragment>
}

export default observer(EditBarWeb)