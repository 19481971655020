import {Box, useTheme} from "@mui/material";
import React, {forwardRef} from "react";

export const Section = forwardRef(({ title, children }, ref) => {
    const theme = useTheme();

    return <React.Fragment>
        <Box ref={ref} sx={{
            width: "100%",
            backgroundColor: theme.palette.grey["300"],
            padding: "10px 5px",
            boxSizing: "border-box",
        }}
        >
            <h3 style={{
                margin: "0px",
                paddingLeft: "10px",
                color: theme.palette.grey["700"]
            }}>{title}</h3>
        </Box>

        <Box
            sx={{
                backgroundColor: theme.palette.grey["100"]
            }}
        >{children}</Box>
    </React.Fragment>
})