import React, {Fragment, useEffect} from "react";
import {SearchResultsWeb} from "./SearchResultsWeb";
import {useMediaContext} from "../../../MediaContext";
import {SearchResultsMobile} from "./SearchResultsMobile";
import {
    Configure,
    InstantSearch,
    useHierarchicalMenu,
    useHits,
    useInstantSearch, usePagination,
    useRange,
    useSearchBox
} from "react-instantsearch-hooks-web";
import {useSearchParams} from "react-router-dom";
import {useSearchContext} from "../../../Contexts/SearchContext";
import algoliasearch from "algoliasearch/lite";
import {getUnixTime} from "../../../Utilities/dateUtils";
import {Pagination} from "@mui/material";
import Space from "../../Basic/Space";
import { useConstantsContext } from "../../../Contexts/ConstantsContext";

export const Paginate = () => {
    const searchContext = useSearchContext();
    const mediaContext = useMediaContext();
    const {indexUiState} = useInstantSearch();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const pageParam = searchParams.get("page");
    const page = parseInt(pageParam);

    // Scroll to the top only after a state update
    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [indexUiState.page]);

    if (searchContext.data.nbPages < 1) return null;

    return <Fragment>
        <Space height="30px"/>
        <Pagination
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            page={isFinite(page) ? page : 1}
            count={searchContext.data.nbPages}
            onChange={(event, newPage) => {
                searchParams.set("page", newPage);
                setSearchParams(searchParams);
            }}
            size="large"
            siblingCount={mediaContext.isMobile ? 0 : 2}
        />
    </Fragment>
}

/**
 * Depending on the chosen search parameters for filtering
 * this method returns the correct configuration for Algolia
 */
const getGeoConfiguration = (latLng, radius) => {
    const useIP = latLng == null || radius == null;

    return {
        aroundLatLngViaIP: useIP,
        aroundLatLng: useIP ? undefined : latLng,
        aroundRadius: useIP ? "all" : radius * 1000,
    }
}

/**
 * This component is responsible for handling the search parameters
 * by updating the Algolia UI State according to changes.
 *
 * It also handles the initial configuration of Algolia, such as
 * the number of hits per page.
 */
const CustomConfigure = () => {
    const {setIndexUiState} = useInstantSearch();
    const [searchParams] = useSearchParams();
    const searchContext = useSearchContext();
    const constantsContext = useConstantsContext();

    const radiusParam = searchParams.get("radius");
    const latLngParam = searchParams.get("latLng");
    const queryParam = searchParams.get("query");
    const priceParam = searchParams.get("price");
    const pageParam = searchParams.get("page");
    const categoryParam = searchParams.get("category");

    // The following hooks are required for Algolia to work
    // Even if they are not used
    useSearchBox();
    const { hits } = useHits();
    const { start, range } = useRange({attribute: "price"});
    const { nbPages } = usePagination();
    useHierarchicalMenu({
        attributes: [
            'category.lvl0',
            'category.lvl1',
        ],
    })

    const configuration = {
        hitsPerPage: 36,
        ignorePlurals: true,
        removeStopWords: true,
        typoTolerance: true,
        synonyms: true,
        filters: `expirationTime > ${getUnixTime()} AND active:true AND paid:true AND deleted:false AND accepted:true`,
        aroundPrecision: [
            {"from": 0, "value": 1000},
            {"from": 1000, "value": 5000},
            {"from": 5000, "value": 50000},
            {"from": 50000, "value": 300000},
        ],
        ...getGeoConfiguration(latLngParam, radiusParam),
    }

    // Keep search context updated
    useEffect(() => {
        const newStart = [...start];

        // Limit start to the range
        if (!isFinite(start[0])) {
            newStart[0] = range["min"];
        }

        if (!isFinite(start[1])) {
            newStart[1] = range["max"];
        }

        searchContext.setData(data => {
            return {
                ...data,
                hits: hits,
                start: newStart,
                range: [range["min"], range["max"]],
                nbPages: nbPages,
            }
        })
    }, [hits, start, range, nbPages]);

    // Watch the query parameter
    useEffect(() => {
        setIndexUiState(prevState => {
            const newState = {
                ...prevState,
                query: queryParam ?? undefined,
                page: 1,
            }

            return newState;
        })
    }, [queryParam]);

    // Watch the price parameter
    useEffect(() => {
        setIndexUiState(prevState => {
            return {
                ...prevState,
                range: {
                    price: priceParam ?? undefined
                }
            }
        })
    }, [priceParam]);

    // Watch the category parameter
    useEffect(() => {
        setIndexUiState(prevState => {
            return {
                ...prevState,
                hierarchicalMenu: {
                    "category.lvl0": categoryParam?.split(" > ") ?? undefined
                }
            }
        })
    }, [categoryParam]);

    // Watch the page parameter
    useEffect(() => {
        setIndexUiState(prevState => {
            return {
                ...prevState,
                page: pageParam ?? undefined
            }
        });
    }, [pageParam]);

    return <Configure {...configuration}/>
}

/**
 * Shows search results
 */
function SearchResults() {
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;

    const client = algoliasearch(
        "SQJ6U6KFSB",
        "3fdb83b7f0943a0e0dff2956eccfe2a7"
    );
    client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

    return <InstantSearch
        searchClient={client}
        indexName={process.env.REACT_APP_ALGOLIA_INDEX}
        routing={false}
    >
        <CustomConfigure/>

        {isMobile
            ? <SearchResultsMobile/>
            : <SearchResultsWeb/>
        }
    </InstantSearch>

}

export default SearchResults;
