import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

const LoadingButton = ({ loading, type, color, label, disabledColor, onClick, sx, fullWidth }) => {
    return (
      <React.Fragment>
          {loading ?
            <Button type={type}
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: `${disabledColor}.main`,
                        color: "#ffffff"
                      },
                      ...sx
                    }}
                    color={disabledColor}
                    fullWidth = {!!fullWidth}
                    variant="contained"
                    disabled={loading}
                    >
              <CircularProgress
                size={24}
                sx={{
                  color: "#ffffff",
                }}
              />
            </Button>:
            <Button
              type={!!type ? type : undefined}
              onClick={!!onClick ? onClick : undefined}
              color={color}
              sx={!!sx ? sx : undefined}
              fullWidth={!!fullWidth}
              variant="contained"
            >
              {label}
            </Button>
          }
      </React.Fragment>
    )
}

export default LoadingButton;