import * as React from "react";
import {observer} from "mobx-react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    Button,
    Divider,
    Paper, Stack,
} from "@mui/material";
import {accountStore} from "../../../Stores/AccountStore";
import {useForm} from "react-hook-form";
import {Auth} from "aws-amplify";
import AttributeBlock from "./AttributeBlock";
import NameBlock from "./NameBlock";
import BirthdateBlock from "./BirthdateBlock";
import AddressBlock from "./AddressBlock";
import ShooterHandBlock from "./ShooterHandBlock";
import HuntingTypesBlock from "./HuntingTypesBlock";
import {Fragment, useEffect} from "react";
import {MitIdIcon} from "../../Shared/Icons/MitIdIcon";
import Skeleton from "@mui/material/Skeleton";
import {timestampToDate} from "../../../Utilities/dateUtils";
import Box from "@mui/material/Box";
import useSnackbar from "../../Snackbar/SnackbarHook";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SideMenu} from "../MySiteDesktop";
import LoginInfo from "./LoginInfo";
import Space from "../../Basic/Space";
import {useMediaContext} from "../../../MediaContext";
import {MobileContainer} from "../../Global/Global";
import dayjs from "dayjs";
import {reportException} from "../../../Utilities/sentry";
import {trimNames} from "../../Shared/Shared";

function Profile() {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const mediaContext = useMediaContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const [user, setUser] = React.useState({});
    const [chosenHuntType, setChosenHuntType] = React.useState([]);

    useEffect(() => {
        const validatedParam = searchParams.get("validated");
        if (!!accountStore.user.mitIdValidated) {
            if (!!validatedParam) {
                const isValidated = accountStore.user.mitIdValidated;
                if (isValidated) {
                    if (validatedParam === "true") {
                        snackbar.handleOpen(
                            "MitID validering gennemført", "success"
                        );
                    }
                    if (validatedParam === "validated") {
                        snackbar.handleOpen(
                            "Allerede MitID valideret", "success"
                        );
                    }
                } else {
                    snackbar.handleOpen(
                        "MitID validering mislykkedes", "error"
                    );
                }
            }
        }
    }, [accountStore.user.mitIdValidated]);

    const ShootingHand = ["right", "left", "both"]

    const {
        register,
        getValues,
        control,
        watch,
        formState: {errors},
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            email: accountStore.user.email,
            firstName: accountStore.user.firstname,
            lastName: accountStore.user.lastname,
            birthDate: new Date(accountStore.user.birthtime * 1000),
            address: accountStore.user.street + ", " + accountStore.user.zip,
            phone: accountStore.user.phone,
            favoriteHand: ShootingHand[accountStore.user.shootingHand],
            huntingType: []
        }
    });
    useEffect(() => {
        reset({
            email: accountStore.user.email,
            firstName: accountStore.user.firstname,
            lastName: accountStore.user.lastname,
            birthDate: new Date(accountStore.user.birthtime * 1000),
            address: accountStore.user.street + ", " + accountStore.user.zip,
            phone: accountStore.user.phone,
            favoriteHand: ShootingHand[accountStore.user.shootingHand],
            huntingType: []
        });
    }, [reset, accountStore.user]);

    // For email: https://stackoverflow.com/questions/66544422/can-change-the-users-email-in-aws-cognito-user-pool
    const onSubmit = async data => {
        if(data.birthDate instanceof Date){
            data.birthDate = dayjs(data.birthDate)
        }
        data.huntingType = chosenHuntType;
        const curUser = await Auth.currentAuthenticatedUser();
        data.id = curUser.username;
        data.huntingType = chosenHuntType;
        const addressArray = data.address.split(",");
        const zipCity = addressArray.pop().trim();
        data.zip = zipCity.split(" ")[0];
        data.address = addressArray.join();
        data.firstName = trimNames(data.firstName);
        data.lastName = trimNames(data.lastName);
        setUser(data);
        try {
            const isUpdated = await accountStore.updateUserOnJagtbasenDB(data);
            if (!!isUpdated) {
                setTimeout(async () => {
                    setUser({});
                }, 2000);
            }
        } catch (e) {
            reportException(e);
            console.log(e);
        }
    };

    const handleSubmitForm = () => {
        handleSubmit(onSubmit)();
    };

    if (accountStore.user.email != null) {
        return (
            <SideMenu hide={mediaContext.isMobile}>
                {mediaContext.isMobile && (
                    <Space height="20px"/>
                )}

                <MobileContainer>
                    <Box component="main" maxWidth="sm">
                        <Typography variant="h4">
                            Profil oplysninger
                        </Typography>
                        <Paper variant="outlined" sx={{mt: 2}} component="form" id="profile-form"
                               onSubmit={handleSubmit(onSubmit)}>

                            <NameBlock register={register}
                                       errors={errors}
                                       name="name"
                                       id="name"
                                       submitForm={handleSubmitForm}
                                       label="Navn:"
                            />
                            <Divider/>
                            <BirthdateBlock control={control}
                                            register={register}
                                            errors={errors}
                                            name="birthDate"
                                            label="Fødselsdato:"
                                            submitForm={handleSubmitForm}
                                            hookValues={getValues}
                            />
                            <Divider/>
                            <AddressBlock register={register}
                                          id="address"
                                          errors={errors}
                                          name="address"
                                          label="Adresse:"
                                          submitForm={handleSubmitForm}
                                          watch={watch}
                                          control={control}
                            />
                            <Divider/>
                            <AttributeBlock register={register}
                                            errors={errors}
                                            name="phone"
                                            blockTextId="phoneBlockText"
                                            id="phone"
                                            autoComplete="tel-local"
                                            patternValue={
                                                /^(\+\d{1,3})?\s?\d{7,14}$/
                                            }
                                            patternMessage="Ugyldig telefon nummer."
                                            requiredMessage="Påkrævet"
                                            submitForm={handleSubmitForm}
                                            label="Telefon:"
                                            inputLabel="Telefon nummer"
                                            blockValue={accountStore.user.phone}
                            />
                            <Divider/>
                            <ShooterHandBlock register={register}
                                              control={control}
                                              errors={errors}
                                              name="favoriteHand"
                                              label="Skyttehånd:"
                                              submitForm={handleSubmitForm}
                            />
                            <Divider/>
                            <HuntingTypesBlock register={register}
                                               errors={errors}
                                               name="huntingType"
                                               label="Jagttype:"
                                               submitForm={handleSubmitForm}
                                               chosenHuntType={chosenHuntType}
                                               setChosenHuntType={setChosenHuntType}
                            />
                        </Paper>
                        <Space height="5px"/>
                        <Typography variant="body2">
                            Bruger siden: {timestampToDate(accountStore.user.creationTime)}
                        </Typography>

                        <Space height="40px"/>

                        <LoginInfo/>

                        <Space height="40px"/>

                        <Typography component="h1" variant="h4" sx={{mb: 2}}>
                            Profil validering
                        </Typography>
                        <Typography variant="body2">
                            Du kan vise andre, at du er sikker at handle med, ved at validere din profil med MitID.
                        </Typography>

                        <Space height="15px"/>

                        {accountStore.user.mitIdValidated && (
                            <Box sx={{
                                display: "inline-flex",
                                flexDirection: "row",
                                border: 1,
                                borderColor: "jagtred.main",
                                borderRadius: 1
                            }}>
                                <Stack spacing={2} direction="row" sx={{m:1}}>
                                    <Typography>Valideret med </Typography> <MitIdIcon/>
                                </Stack>

                            </Box>
                        )}

                        {!accountStore.user.mitIdValidated && (
                            <Button
                                variant="outlined"
                                color="jagtred"
                                endIcon={<MitIdIcon/>}
                                href={`${process.env.REACT_APP_API_URL}/api/MitID/${accountStore.user.id}`}
                            >
                                Valider med
                            </Button>
                        )}
                    </Box>
                </MobileContainer>
            </SideMenu>
        );
    } else {
        return (
            <Container component="main" maxWidth="sm" sx={{mt: 5, mb: 5}}>
                <Typography component="h1" variant="h4" sx={{m: 2}}>
                    Profil oplysninger
                </Typography>
                <Skeleton variant="rounded" height={60}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton variant="rounded" height={60}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton variant="rounded" height={60}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton variant="rounded" height={60}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>

                <Skeleton variant="rounded" height={60} sx={{mt: 5}}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>

            </Container>
        );
    }

}

export default observer(Profile);