import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {observer} from "mobx-react";
import {chatStore} from "../../Stores/ChatStore";
import FaceIcon from '@mui/icons-material/Face';
import { Stack } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from "@mui/material/Typography";
import { isBoldMessage, showLastMessage } from "./common";
import { Ellipsis } from "../Shared/Shared";
import { BlueDot } from "../Basic/Dot";
import Row from "../Basic/Row";

function getInboxListItem(inbox, index) {
  const isBold = isBoldMessage(inbox);
  return <div>
    <ListItemButton
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "0px"
      }}
      onClick={async () => {
        await chatStore.getInbox(inbox.InboxId, index);
      }}>
      <ListItemAvatar sx={{ pl: 1 }}>
        <Stack>
          <FaceIcon sx={{ fontSize: 35 }} htmlColor={"#5e1022"} />
          <Typography color={"#5e1022"} fontSize={"13px"}>Køber</Typography>
        </Stack>
      </ListItemAvatar>
      <ListItemText
        primary={inbox.User.Firstname.concat(" " + inbox.User.Lastname)}
        primaryTypographyProps={{ fontSize: "large", variant: "h6" }}
        secondaryTypographyProps={{ color: isBold ? "black" : "auto"}}
        secondary={
          <Ellipsis
            sx={{
              fontWeight: isBold ? "bold" : "normal",
              cursor: "pointer"
            }}
          >
            {showLastMessage(inbox)}
          </Ellipsis>
        }
      />
      <Row sx={{alignItems: 'center'}}>
        {isBold && (
          <BlueDot/>
        )}
        <KeyboardArrowRightIcon sx={{ fontWeight: "40px", fontSize: "30px", color: "#5e1022" }} />
      </Row>
    </ListItemButton><Divider sx={{ marginLeft: "0px" }} variant="inset" component="li" />
  </div>;
}

function chatList() {
    return (
        <div>
            <Stack sx={{pt: 1, pb: 2}}>
                <Typography color={"rgba(0, 0, 0, 0.6)"} fontSize={"smaller"}>
                    Se dine private samtaler på annoncen med andre Jagtbasen brugere
                </Typography>
            </Stack>
            <List sx={{width: '100%', maxWidth: 700}}>
                <Typography variant={"h6"} fontWeight={"bold"}>Indbakke</Typography>
                {chatStore.inboxes.map((inbox, index) =>
                    getInboxListItem(inbox, index)
                )}
            </List>
        </div>
    );
}

export default observer(chatList);