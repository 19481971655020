import { Box, styled } from "@mui/material";

const Row = styled(Box)({
  display: "flex",
});

const CenterRow = styled(Row)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
});

export default Row;
export { CenterRow };
