import {observer} from "mobx-react";
import {Button, Grid, Grow, Stack, useMediaQuery, useTheme} from "@mui/material";
import InputText from "../../Form/InputText";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {accountStore} from "../../../Stores/AccountStore";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";

const PasswordBlock = ({
                           register,
                           errors,
                           submitForm,
                           id,
                           name,
                           autoComplete,
                           getValues,
                           label,
                           inputLabel,
                           patternValue,
                           patternMessage,
                           requiredMessage,
                           blockValue
                       }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const passwordRegex = /^(?=.*\d)(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?!.*[^a-zA-Z0-9_\u00C0-\u017F!@#$%^&*\-]).{8,}$/;

    const handleBlocks = (event, name) => {
      let isActive = !active
      setActive(isActive)
      const eventButtonContent = event.target.innerText;
        if(!!eventButtonContent){
            if (eventButtonContent.trim() === "GEM") {
                submitForm();
            }
        }
    };

    const [active, setActive] = React.useState(false);
    return (
        <Box>
            {active ?
                <Grow in={active}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} p={2} justifyContent="space-between"
                           alignItems="center">
                        <Stack spacing={2} sx={{width: "100%"}}>
                            <InputText
                                id={id}
                                autoComplete="current-password"
                                label="Gamle adgangskode"
                                type="password"
                                maxWidth={matches ? "100%" : undefined}
                                register={register}
                                options={{
                                    required: "Påkrævet",
                                    pattern: {
                                        value: passwordRegex,
                                        message: "Et stort bogstav, et tal og 8 karakter"
                                    }
                                }}
                                errors={errors}
                            />
                            <InputText
                                id="newPassword"
                                label="Ny adgangkode"
                                autoComplete="new-password"
                                type="password"
                                maxWidth={matches ? "100%" : undefined}
                                register={register}
                                options={{
                                    required: "Påkrævet",
                                    pattern: {
                                        value: passwordRegex,
                                        message: "Et stort bogstav, et tal og 8 karakter"
                                    }
                                }}
                                errors={errors}
                            />
                        </Stack>
                        <Stack direction={{xs: 'row', sm: 'column'}} spacing={1}
                               width={matches ? "100%" : undefined} justifyContent="space-between"
                               alignItems="center">
                            <Button name={name + "Cancel"}
                                    variant="contained"
                                    onClick={(event) => handleBlocks(event, name)}
                                    fullWidth={!matches}
                                    color="jagtred">
                                Annuller
                            </Button>
                            <Button name={name + "Save"}
                                    onClick={(event) => handleBlocks(event, name)}
                                    fullWidth={!matches}
                                    variant="contained"
                                    color="jagtyellow">
                                Gem
                            </Button>
                        </Stack>
                    </Stack>
                </Grow>
                :
                matches ?
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "3em",
                        padding: 3
                    }}>
                        <Stack spacing={1}>
                            <Typography sx={{fontWeight: 'bold'}}> {label} </Typography>
                            <Typography>{blockValue}</Typography>
                        </Stack>
                        <IconButton color="jagtred" name={name + "Edit"}
                                    onClick={(event) => handleBlocks(event, name)}> <EditIcon/>
                        </IconButton>
                    </Box>
                    :
                    <Grid container direction="row" justifyContent="space-around" alignItems="center"
                          sx={{height: "5em", padding: 3}}>
                        <Grid item sm={3}>
                            <Typography sx={{fontWeight: 'bold'}}> {label} </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>{blockValue}</Typography>
                        </Grid>
                        <Grid item sm={3} sx={{textAlign: "right"}}>
                            <IconButton color="jagtred" name={name + "Edit"}
                                        onClick={(event) => handleBlocks(event, name)}><EditIcon/></IconButton>
                        </Grid>
                    </Grid>
            }
        </Box>
    )

}

export default observer(PasswordBlock);