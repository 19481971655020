import {makeAutoObservable, runInAction} from "mobx";
import lotteryService from "../Services/LotteryService";

class LotteryStore {
    isInitialized = false; // False until everything is loaded
    lotteries = {};

    constructor() {
        makeAutoObservable(this)
        this.init();
    }

    async init() {
        await this.fetchLotteries();

        runInAction(() => {
            this.isInitialized = true;
        });
    }

    async fetchLotteries() {
        const lots = await lotteryService.getActiveLotteries();

        // If an error occurred or there are no lotteries
        if (lots == null || lots.length < 1) return;

        const basicPrizes = lots.find(lot => lot.Type == "basic_monthly")?.Prize;
        const premiumPrizes = lots.find(lot => lot.Type == "premium_monthly")?.Prize;

        runInAction(() => {
            this.lotteries = {
                basic: basicPrizes?.split(";"),
                premium: premiumPrizes?.split(";")
            }
        })
    }

}

export const lotteryStore = new LotteryStore();