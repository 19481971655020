import {Auth} from "aws-amplify";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Box, Divider, Paper, Snackbar } from "@mui/material";
import {useForm} from "react-hook-form";
import {accountStore} from "../../../Stores/AccountStore";
import AttributeBlock from "./AttributeBlock";
import {observer} from "mobx-react";
import PasswordBlock from "./PasswordBlock";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {reportException} from "../../../Utilities/sentry";

function LoginInfo() {

    const navigator = useNavigate();
    const [message, setMessage] = useState("");
    let initialValues = {
        email: accountStore.user.email
    }

    useEffect(() => {
        // If user is not signed in, redirect to login page
        if (!accountStore.logged) {
            navigator(`/log-ind`, {replace: true});
            return;
        }
    })

    const {
        register,
        getValues,
        formState: {errors},
        handleSubmit,
    } = useForm({defaultValues: initialValues});

    const onSubmit = async data => {
        const curUser = await Auth.currentAuthenticatedUser();
        if (initialValues.email !== data.email) {
            try {
                const result = await Auth.updateUserAttributes(curUser, {
                    email: data.email,
                    email_verified: "true"
                })
                if (result === "SUCCESS") {
                    let confirm = await accountStore.updateUserEmailOnJagtbasenDB(curUser.username, data.email);
                    await accountStore.getCurrentUser();
                }
            } catch (err) {
                reportException(err);
                console.log(err)
            }
        }
    }

    const submitPassword = async data => {
        const curUser = await Auth.currentAuthenticatedUser();
        if (!!data.password && !!data.newPassword) {
            try {
                const result = await Auth.changePassword(curUser, data.password, data.newPassword)
            } catch (err) {
                if (err.code === "LimitExceededException") {
                    setMessage("Antal forsøg overskredet. Prøv igen senere...")
                    handleSnackbarClick()
                    console.log(err)
                    return;
                }
                if (err.code === "NotAuthorizedException") {
                    setMessage("Forkert adgangskode")
                    handleSnackbarClick()
                    console.log(err)
                    return;
                }

                reportException(err);
            }
        }
    }

    const handleSubmitPassword = () => {
        handleSubmit(submitPassword)();
    }

    const handleSubmitForm = () => {
        handleSubmit(onSubmit)();
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const handleSnackbarClick = () => {
        setOpenSnackbar(true);
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    return (
        <Box component="main" maxWidth="sm" >
            <Typography component="h1" variant="h4" >
                Log ind oplysninger
            </Typography>
            <Paper variant="outlined" sx={{mt: 2}} component="form" id="profile-form" onSubmit={handleSubmit(onSubmit)}>
                <AttributeBlock register={register}
                                errors={errors}
                                name="email"
                                id="email"
                                autoComplete="email"
                                patternValue={/^\S+@\S+$/i}
                                patternMessage="Ugyldig e-mail"
                                requiredMessage="Påkrævet"
                                submitForm={handleSubmitForm}
                                label="E-mail:"
                                inputLabel="E-mail adresse"
                                blockValue={accountStore.user.email}
                />
                <Divider/>
                <PasswordBlock register={register}
                               errors={errors}
                               name="password"
                               id="password"
                               getValues={getValues}
                               patternValue={/^\S+@\S+$/i}
                               patternMessage="Ugyldig adgangskode"
                               requiredMessage="Påkrævet"
                               submitForm={handleSubmitPassword}
                               label="Adgangskode:"
                               inputLabel="Adgangskode"
                               blockValue={"**************"}
                />
            </Paper>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: "right"}}
                autoHideDuration={5000}
                open={openSnackbar}
                onClose={handleSnackbarClose}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{p: 0.5}}
                            onClick={handleSnackbarClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>

    )
}

export default observer(LoginInfo);