import {Box, Grid, Typography} from "@mui/material";
import Row from "../Basic/Row";
import Column from "../Basic/Column";
import EditIcon from "@mui/icons-material/Edit";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {observer} from "mobx-react-lite";
import Space from "../Basic/Space";
import {useNavigate} from "react-router-dom";

const CirculareOutline = ({children}) => {
    return (
        <Box
            sx={{
                border: "solid 1px black",
                borderRadius: "50%",
                display: "inline-block",
                padding: "7px",
            }}
        >
            <Box sx={{height: 0, paddingBottom: "100%"}}>
                {children}
            </Box>
        </Box>
    );
};

const EditBarMobile = ({
    handleDeactivatePost,
    handleActivatePost,
    handleDeletePost,
    post
}) => {
    const navigate = useNavigate();

    return <Grid item xs={12}>
        <Row sx={{
            marginTop: "5px",
            justifyContent: post.Paid ? "space-evenly" : "center"
        }}>
            <Column sx={{textAlign: "center", width: "fit-content"}}>
                <Box
                    onClick={() => {
                        navigate("/rediger-annonce/" + post.PostId);
                    }}
                    sx={{display: "inline-block", cursor: "pointer"}}
                >
                    <CirculareOutline>
                        <EditIcon color="jagtred"/>
                    </CirculareOutline>
                    <Typography>Rediger</Typography>
                </Box>
            </Column>

            {post.Paid && (
                <Column sx={{textAlign: "center", width: "fit-content"}}>
                    <Box onClick={() => {
                        if (post.Expired) return navigate("/rediger-annonce/" + post.PostId);
                        if (post.Active) return handleDeactivatePost(post.PostId);
                        if (!post.Active) return handleActivatePost(post.PostId);
                    }} sx={{display: "inline-block", cursor: "pointer"}}>
                        <CirculareOutline>
                            {post.Expired
                                ? <PlayArrowIcon color="jagtred"/>
                                : post.Active
                                    ? <PauseIcon color="jagtred"/>
                                    : <PlayArrowIcon color="jagtred"/>
                            }
                        </CirculareOutline>
                        <Typography>
                            {post.Expired
                                ? "Genindryk"
                                : post.Active
                                    ? "Deaktiver"
                                    : "Aktivér"
                            }
                        </Typography>
                    </Box>
                </Column>
            )}

            {!post.Paid && (
              <Space width="80px"/>
            )}

            <Column sx={{textAlign: "center", width: "fit-content"}}>
                <Box
                    onClick={() => {
                        handleDeletePost(post.PostId);
                    }}
                    sx={{display: "inline-block", cursor: "pointer"}}
                >
                    <CirculareOutline>
                        <DeleteIcon color="jagtred"/>
                    </CirculareOutline>
                    <Typography>Slet</Typography>
                </Box>
            </Column>
        </Row>
    </Grid>
}

export default observer(EditBarMobile);