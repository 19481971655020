import React, {Fragment} from "react";
import {Box, Typography} from "@mui/material";
import {MobileContainer} from "./Global/Global";
import Space from "./Basic/Space";

export const BadPath = () => {
    return <Fragment>
        <MobileContainer>
            <Box>
                <Space height="25px"/>
                <Box sx={{display: "flex"}}>
                    <Box>
                        <Typography variant="h4">
                            Beklager, siden findes ikke
                        </Typography>
                        <Typography variant="h5">
                            Tjek at linket er korrekt og prøv igen
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </MobileContainer>
    </Fragment>
}
