import React from "react";
import { Box, Card, CardActionArea, Grid, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { formatNumber } from "../../Utilities/postUtils";
import Skeleton from "@mui/material/Skeleton";
import { MobileContainer } from "../Global/Global";


/**
 * A single post card with fixed width
 */
function PostCardStatic({ postId, imageUrl, price, heading, body, highlight }) {
    const theme = useTheme();

    return <Card sx={{
        width: "200px",
        maxWidth: "200px"
    }}>
        <CardActionArea
            component={RouterLink}
            to={"/post/" + postId}
        >
            <Box sx={{ position: "relative" }}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.jagtred.main,
                        position: "absolute",
                        padding: "0px 5px",
                        right: "0px",
                        bottom: "15px",
                        fontWeight: "bold",
                        borderRadius: "4px 0px 0px 4px",
                        color: "white"
                    }}
                >
                    {formatNumber({ value: price.toString() })} kr.
                </Typography>
                <img
                    style={{
                        height: "150px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "4px"
                    }}
                    src={imageUrl}
                />
            </Box>

            <Box sx={{ margin: "0px 10px 5px 10px" }}>
                <Typography variant="h6" noWrap component="div" sx={{fontWeight: highlight ? "bold" : undefined}}>
                    {heading}
                </Typography>
                <Typography variant="body2" noWrap color="text.secondary">
                    {body.trim() === "" ? '\u00A0' : body}
                </Typography>
            </Box>
        </CardActionArea>
    </Card>;
}

/**
 * A single post card with dynamic width
 */
export function PostCardDynamic({ postId, imageUrl, price, title, subtitle, highlight, onClick }) {
    const theme = useTheme();

    return <Grid
        item
        xs={6} sm={4} md={4} lg={3}
        onClick={() => {
            // If an on click method is provided we use that
            if (onClick != null) {
                return onClick();
            }
        }}
        sx={{ cursor: "pointer", marginBottom: "10px" }}
    >
        <CardActionArea
            component={RouterLink}
            to={"/post/" + postId}
        >
            <Box sx={{ position: "relative" }}>
                <img
                    alt="image"
                    src={imageUrl}
                    style={{
                        height: "150px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "4px"
                    }}

                />
                <Typography
                    sx={{
                        position: "absolute",
                        bottom: "15px",
                        right: "0px",
                        padding: "0px 5px",
                        borderRadius: "4px 0px 0px 4px",
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: theme.palette.jagtred.main
                    }}
                >
                    {formatNumber({ value: price.toString() })} kr.
                </Typography>
            </Box>
            <Typography variant="h6" noWrap sx={{fontWeight: highlight ? "bold" : undefined}}>
                {title}
            </Typography>
            <Typography variant="body2" noWrap color="text.secondary">
                {subtitle.trim() === "" ? '\u00A0' : subtitle}
            </Typography>
        </CardActionArea>
    </Grid>;
}

export const PostCardDynamicSkeletons = ({ nbCards = 12 }) => {
    // Generate loading rows
    let rows = [];
    for (let i = 0; i < nbCards; i++) {
        rows.push(
            <Grid
                key={"search-results-mobile-skeleton-" + i}
                item
                xs={6} sm={4} md={4} lg={3}
            >
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="150px"
                />
                <Skeleton variant="text">
                    <Typography variant="h6">
                        Eksempel titel
                    </Typography>
                </Skeleton>
                <Skeleton variant="text">
                    <Typography variant="body2">
                        1462 København K
                    </Typography>
                </Skeleton>
            </Grid>
        );
    }

    return <MobileContainer>
        <Grid container spacing={1}>
            {rows}
        </Grid>
    </MobileContainer>;

};


export default observer(PostCardStatic);