import {
    Box,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useForm} from "react-hook-form";
import FeedbackService from "../../Services/FeedbackService";
import {observer} from "mobx-react-lite";
import useSnackbar from "../Snackbar/SnackbarHook";
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useMediaContext} from "../../MediaContext";
import {reportException} from "../../Utilities/sentry";

const Feedback = (props) => {
    const [open, setOpen] = useState(false);
    const [openBox, setOpenBox] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const snackbar = useSnackbar();
    const onSubmit = (data) => {
        const message = sanitizeInput(data.message); // Sanitize the message text
        FeedbackService.createFeedback({ message })
            .then(() => {
                snackbar.handleOpen("Tak for din feedback.", "success")
                reset();
                handleClose();
            })
            .catch((error) => {
                reportException(error, {options: {
                    message: message,
                }});
                snackbar.handleOpen("Din feedback kunne ikke sendes.", "error")
            });
    };

    const sanitizeInput = (input) => {
        if (typeof input !== 'string') {
            return '';
        }
        // Remove any HTML tags from the input
        input = input.replace(/<.*?>/g, '');
        // Remove any special characters that could be used for SQL injection attacks
        input = input.replace(/[^\p{L}\d\s.@-]/gu, '');
        return input;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBoxClick = () => {
        setOpenBox(!openBox);
    };

    const mediaContext = useMediaContext();

        return (
            <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",borderRadius: "4px 0px 0px 4px",
                        backgroundColor: "error.light",
                        position: "fixed",
                        zIndex: 10,
                        right: 0,
                        top: mediaContext.isMobile ? "initial" : "50%",  // if mobile, don't set top
                        bottom: mediaContext.isMobile ? 30 : "initial",  // if mobile, set bottom to 75
                        transform: mediaContext.isMobile ? "none" : "translateY(-50%)",  // if not mobile, apply transform
                    }}>
                        <IconButton sx={{color: "#ffffff"}}  onClick={handleBoxClick}>
                            {openBox ? <ArrowForwardIosIcon/> : <ArrowBackIosIcon/>}
                        </IconButton>
                        <Collapse in={openBox} orientation="horizontal">
                            <Button
                                onClick={handleClickOpen}
                                sx={{
                                    height: "100%",
                                    color: "#ffffff",
                                    backgroundColor: "error.light",
                                    "&:hover": {
                                        backgroundColor: 'error.main'
                                    }
                                }}
                                endIcon={<StarIcon/>}
                            >
                               Feedback
                            </Button>
                        </Collapse>
                    </Box>


                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>
                        <Stack direction="row" alignItems="center">
                        Feedback
                        </Stack>
                        <DialogContentText variant="body2">
                            Vi trækker lod om en termokande blandt alle der giver feedback
                        </DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContentText>
                                Hvordan kan vi forbedre siden?
                            </DialogContentText>
                            <TextField
                                id="outlined-multiline-static"
                                label="Skriv feedback"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'black',
                                    },
                                    '& label.Mui-error': {
                                        color: 'red',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'black',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                        },
                                        '&.Mui-error fieldset': {
                                            borderColor: 'red',
                                        },
                                    },
                                    mt: 2}}
                                {...register('message', {required: true})}
                            />
                            <DialogActions>
                                <Button color="jagtred" onClick={handleClose}>Annuller</Button>
                                <Button type="submit" color="jagtred">Send</Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </Box>
        )

}

export default observer(Feedback);