import * as React from 'react';
import {observer} from "mobx-react-lite";
import {
    Checkbox,
    FormControl, FormControlLabel, FormGroup,
    Grid, Grow,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import DateInput from "../../Form/DateInput";
import {Controller, useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import InputText from "../../Form/InputText";
import {accountStore} from "../../../Stores/AccountStore";
import {Analytics, Auth} from "aws-amplify";
import useSnackbar from "../../Snackbar/SnackbarHook";
import DAWAAutocomplete from "../../Form/DAWAAutocomplete";
import LoadingButton from "../../Form/LoadingButton";
import {useEffect} from "react";
import {LoginContainer} from "../Login/Login";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useMediaContext} from "../../../MediaContext";
import {reportException} from "../../../Utilities/sentry";
import Container from "@mui/material/Container";
import {trimNames} from "../../Shared/Shared";

function SecondFlow() {
    const mediaContext = useMediaContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const lastVisitedPage = location.state?.from;
    const [chosenHuntType, setChosenHuntType] = React.useState([]);
    const snackbar = useSnackbar()
    const isMobile = mediaContext.isMobile;
    const addressRegex = /^[a-zA-Z\u00C0-\u017F0-9\s.,'-]+\s\d{4}\s[a-zA-Z\u00C0-\u017F\s]+$/;

    const [checked, setChecked] = React.useState(false);

    const {
        register,
        formState: {errors},
        handleSubmit,
        getValues,
        control,
        watch
    } = useForm({defaultValues: {isBusiness: false, marketing: true, favoriteHand: "", birthDate: null}});

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        const { unsubscribe } = watch((value) => {
            setChecked(value.isBusiness);
        });
        return () => unsubscribe();
        }, [watch]);

    const onSubmit = async data => {
        setLoading(true)
        //todo: this call could fail if the user never created a cognito user
        const curUser = await Auth.currentUserInfo();

        const addressArray = data.address.split(",");
        const zipCity = addressArray.pop().trim();
        data.zip = zipCity.split(" ")[0];
        const zipRegex = /\b[1-9]\d{3}\b/

        if (!zipRegex.test(data.zip)) {
            snackbar.handleOpen("Der sket en fejl ved adresse indtastning. Prøv venligst igen.")
            setLoading(false)
            return
        }
        data.address = addressArray.join();
        data.email = curUser.attributes.email
        data.id = curUser.username
        data.huntingType = chosenHuntType
        data.firstName = trimNames(data.firstName);
        data.lastName = trimNames(data.lastName);
        if(data.isBusinessUser){
            let vatNumberInt = parseInt(data.vatNumber, 10);
            if (isNaN(vatNumberInt)) {
                snackbar.handleOpen("Der sket en fejl ved CVR indtastning. Prøv venligst igen.")
                setLoading(false)
                return
            }
        }

        try {
            const isCreated = await accountStore.createUserOnJagtbasenDB(data);
            if (!!isCreated) {
                setLoading(false)
                searchParams.set("signUpStatus", "huntingform");
                setSearchParams(searchParams);
            }
        } catch (e) {
            reportException(e);
            snackbar.handleOpen("Der skete en fejl", "error");
            setLoading(false);
            console.log(e)
        }
    };

    useEffect(() => {
        Analytics.record({
            name: "Sign up - 2. step started"
        })
    }, []);

    return (
        <LoginContainer>
            <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth={isMobile ? "xs" : "md"}>
                <Typography component="h1" variant="h4" sx={{mb: 2}}>
                    Opret bruger
                </Typography>
                <Typography sx={{mb: 1}}>
                    Dit navn og adresse vil ikke blive vist ved oprettelse af annoncer.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputText
                                        id="firstName"
                                        autoComplete="given-name"
                                        maxWidth="100%"
                                        label="Fornavn"
                                        register={register}
                                        options={{
                                            required: "Påkrævet",
                                            pattern: /^(?!.*[^a-zA-Z\u00C0-\u017F'\- ]).{2,}$/
                                        }}
                                        errors={errors}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputText
                                        id="lastName"
                                        autoComplete="family-name"
                                        maxWidth="100%"
                                        label="Efternavn"
                                        register={register}
                                        options={{
                                            required: "Påkrævet",
                                            pattern: /^(?!.*[^a-zA-Z\u00C0-\u017F'\- ]).{2,}$/
                                        }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DateInput
                                        control={control}
                                        name="birthDate"
                                        fullwidth
                                        maxWidth="100%"
                                        label="Fødselsdato"
                                        errors={errors}
                                        validateIndput={() => {
                                            if (!getValues("birthDate").isValid()) {
                                                return "Ugyldig dato."
                                            }
                                            let datenow = new Date();
                                            let birthDate = getValues("birthDate").toDate();
                                            let difference = datenow - birthDate;
                                            const sixteenYearsOldSeconds = 1000 * 60 * 60 * 24 * 365 * 16;
                                            const hundredYearsOldSeconds = 1000 * 60 * 60 * 24 * 365 * 100;
                                            if (difference < sixteenYearsOldSeconds) {
                                                return "Minimum alder 16 år";
                                            }
                                            if (difference > hundredYearsOldSeconds) {
                                                return "Ugyldig dato."
                                            }
                                        }}
                                    />
                                </Grid>
                                {checked && <Grow in={checked}>
                                    <Grid item xs={12}>
                                        <InputText
                                            id="vatNumber"
                                            autoComplete="vat-number"
                                            maxWidth="100%"
                                            label="CVR-nummer"
                                            register={register}
                                            options={{
                                                required: "Påkrævet",
                                                pattern: /^\d{8}$/
                                            }}
                                            errors={errors}
                                        />
                                    </Grid></Grow>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DAWAAutocomplete
                                        watch={watch}
                                        id="address"
                                        control={control}
                                        maxWidth="100%"
                                        fullwidth
                                        label="Adresse"
                                        register={register}
                                        options={{
                                            required: "Påkrævet",
                                            pattern: {
                                                value: addressRegex,
                                                message: "Indtast og vælg en gyldig adresse."
                                            }
                                        }}
                                        errors={errors}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputText
                                        id="phone"
                                        autoComplete="tel-local"
                                        maxWidth="100%"
                                        label="Telefonnr"
                                        register={register}
                                        options={{
                                            required: "Påkrævet",
                                            pattern: {
                                                value: /^(\+\d{1,3})?\s?\d{7,14}$/,
                                                message: "Ugyldig telefon nummer."
                                            }
                                        }}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        render={({field}) => (
                                            <FormControl fullWidth>
                                                <InputLabel sx={{height: "20px"}}
                                                            id="favoriteHand">Skyttehånd</InputLabel>
                                                <Select labelId="favoriteHand" label="Skyttehånd" {...field}>
                                                    <MenuItem value="right">Højre</MenuItem>
                                                    <MenuItem value="left">Venstre</MenuItem>
                                                    <MenuItem value="both">Begge</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                        control={control}
                                        name="favoriteHand"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack alignItems="flex-end" spacing={0.5}>
                                        <Stack  direction="column" alignItems="flex-start">

                                            <Controller
                                                name="isBusiness"
                                                color="info"
                                                control={control}
                                                render={({field}) =>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: "#5E1022",
                                                            }
                                                        }}
                                                        {...field} />} label={<Typography sx={{ fontWeight: 'bold' }}>Erhvervsbruger ?</Typography>}/>
                                                </FormGroup>
                                                } />
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <Controller
                                                    name="marketing"
                                                    color="info"
                                                    control={control}
                                                    render={({field}) =>
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                            <Checkbox
                                                                defaultChecked
                                                                sx={{
                                                                    '&.Mui-checked': {
                                                                        color: '#5e1022',
                                                                    },
                                                                }} {...field} />} label="Ja tak til tilbud og markedsføring."/>
                                                        </FormGroup>
                                                }
                                                />

                                            </Stack>
                                        </Stack>
                                        <LoadingButton onClick={handleSubmit(onSubmit)} color="info"
                                                       sx={{mt: 3, mb: 2, pl: 5, pr: 5, width: "15em"}}
                                                       loading={loading}
                                                       label="Næste" disabledColor="disabledRed"/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">

                    </Grid>
                </form>
            </Container>
        </LoginContainer>
    )
}

export default observer(SecondFlow)