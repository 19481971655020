import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputText from "../../Form/InputText";
import * as React from "react";
import {useForm} from "react-hook-form";
import Container from '@mui/material/Container';
import {Button} from "@mui/material";
import {observer} from "mobx-react-lite";
import {accountStore} from "../../../Stores/AccountStore";
import {Fragment} from "react";
import useSnackbar from "../../Snackbar/SnackbarHook";
import {LoginContainer} from "../Login/Login";
import {reportException} from "../../../Utilities/sentry";
import {useNavigate} from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm();

    const onSubmit = async data => {
        try {
            await accountStore.forgotPassword(data.email);
            snackbar.handleOpen("Vi har sendt en verifikationskode til din E-mail.", "info");
            navigate("/glemt-adgangskode-verificerer?username=" + data.email)
        } catch (e) {
            if (e.code === "LimitExceededException") {
                snackbar.handleOpen("Forsøgsgrænsen overskredet, prøv venligst igen senere.", "error");
                return;
            }
            if (e.code === "UserNotFoundException") {
                snackbar.handleOpen("Brugeren findes ikke.", "error");
                return;
            }

            reportException(e);
        }
    }

    return (
        <Fragment>
            <LoginContainer>
                <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth="xs">
                        <Box>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                                <Typography component="h1" variant="h4" sx={{mb: 3}}>Glemt adgangs
                                    kode</Typography>
                                <Typography sx={{mb: 3}}>
                                    Indtast din e-mail for at nulstille din adgangskode
                                </Typography>
                                <InputText
                                    id="email"
                                    autoComplete="email"
                                    maxWidth="100%"
                                    label="E-mail Adresse"
                                    register={register}
                                    options={{
                                        required: "Påkrævet",
                                        pattern: /^\S+@\S+$/i
                                    }}
                                    errors={errors}
                                />
                                <Typography variant="body2" sx={{mt: 3}}>Vi sender en verifikationskode til din e-mail adresse. Brug
                                    denne
                                    kode til at nulstille din adgangskode på Jagtbasen.dk.</Typography>
                                <Button type="submit" sx={{float: "right", mt: 3}} color="info"
                                        variant="contained">Send</Button>
                            </Box>
                        </Box>
                </Container>
            </LoginContainer>
        </Fragment>
    )
}

export default observer(ForgotPassword);