import {Fragment} from "react";
import {Title} from "../FrontPage";
import {Grid} from "@mui/material";
import {PostCardDynamic, PostCardDynamicSkeletons} from "../../Posts/PostCard";
import Space from "../../Basic/Space";
import {useNavigate} from "react-router-dom";
import { frontpageStore } from "../../../Stores/FrontpageStore";
import { observer } from "mobx-react-lite";

function FrontpageMix() {
    const navigate = useNavigate();
    const loading = frontpageStore.mixedPosts.length < 1;

    return <Fragment>
        <Title title="Andre annoncer"/>
        <Space height={loading ? "20px" : "5px"}/>
        {loading && (
            <Grid container spacing={2}>
                <PostCardDynamicSkeletons/>
            </Grid>
        )}
        {!loading && (
            <Grid container spacing={2}>
                {frontpageStore.mixedPosts.map((post, index) => {
                    return <PostCardDynamic
                        key={`font-page-swiper-${post.PostId}`}
                        postId={post.PostId}
                        imageUrl={post.Images[0]?.Url}
                        price={post.Price}
                        title={post.Heading}
                        subtitle={post.Body}
                        highlight={post.Highlight}
                    />
                })}
            </Grid>
        )}
    </Fragment>;
};

export default observer(FrontpageMix);