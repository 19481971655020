import accountService from "./AccountService";
import {isValidZip} from "../Utilities/postUtils";
import {reportException, reportMessage} from "../Utilities/sentry";

class PostsService {
    async getPostById(id) {
        const jwt = await accountService.getCognitoJwtOrNull();
        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {}
        };

        if (jwt !== null) {
            requestOptions.headers['Authorization'] = `Bearer ${jwt}`;
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/" + id,
                requestOptions
            );
            if(response.ok){
                return await response.json();
            } else {
                throw await response.text();
            }
        } catch (e) {
            reportException(e);
            console.log(e);
            throw e;
        }
    }

    async getPostForEdit(id) {
        try {
            const jwt = await accountService.getCognitoJwt()
            const requestOptions = {
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${jwt}`,
                }
            };
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/edit/" + id,
                requestOptions
            );
            if(response.ok){
                return await response.json();
            } else {
                throw await response.text();
            }
        } catch (e) {
            reportException(e);
            console.log(e);
            throw e;
        }
    }

    async getUserPosts() {
        try {
            const jwt = await accountService.getCognitoJwt()
            const requestOptions = {
                method: "GET",
                redirect: "follow",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${jwt}`,
                }
            };
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/user",
                requestOptions
            );
            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e)
        }
    }

    // Returns the post id if successfully, otherwise null
    async createPost(post, paymentType) {
        try {
            const jwt = await accountService.getCognitoJwt();
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                    "Payment-Type": paymentType,
                },
                body: JSON.stringify(post),
            };

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts",
                requestOptions
            );

            if (!response.ok) {
                reportMessage("Received status != 200 during create post", {notify: true, options: {
                    status: response.status,
                    statusText: response.statusText,
                    response: response.clone().text(),
                    post: JSON.stringify(post),
                }});
                throw new Error("Failed to create post, bad response");
            }

            return await response.json();
        } catch (e) {
            reportException(e, {options: {
                post: JSON.stringify(post),
            }});
            console.log(e)
            throw e;
        }
    }

    // Returns the true on success, otherwise false
    async updatePost(post, paymentType) {
        try {
            const jwt = await accountService.getCognitoJwt();
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                    "Payment-Type": paymentType,
                },
                body: JSON.stringify(post),
            };

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts",
                requestOptions
            );

            if (!response.ok){
                throw new Error("Bad response");
            }

            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e)
        }
    }

    async getSignedS3Url() {
        /*
          Returns url on success, otherwise it throws an error
         */
        const jwt = await accountService.getCognitoJwt();

        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        };

        const response = await fetch(
            process.env.REACT_APP_API_URL + "/api/Posts/signed-url",
            requestOptions
        );
        return await response.json();
    }

    async postS3Image(url, file, counter = 0) {
        /*
          Returns url if successful, otherwise it throws an error
          The method uses exponential backoff with failure
         */

        const wait = (ms) => new Promise((res) => setTimeout(res, ms));
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: file,
        };

        const response = await fetch(url, requestOptions);
        if (response.status == 200) return url.split("?")[0];

        if (counter < 3) {
            await wait(200 * Math.pow(3, counter));
            return await this.postS3Image(url, file, counter + 1);
        }

        throw new Error("Failed to upload image three times");
    }

    /*
      Update the status of a post in the backend to active or expired
      Returns true if successful, otherwise false
    */
    async updatePostStatus(postId, status) {
        try {
            const jwt = await accountService.getCognitoJwt()
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                },
                body: status
            }

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/update-status/" + postId,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Bad response")
            }

            return true;
        } catch (e) {
            reportException(e);
            console.log(e)
        }

        return false;
    }

    async deletePost(postId, reason) {
        try {
            const requestBody = { "Id": postId, "reason": parseInt(reason, 10)}
            const jwt = await accountService.getCognitoJwt()
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify(requestBody)
            }

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/",
                requestOptions
            );

            if (!response.ok)
                throw new Error("Bad response")

            return true;
        } catch (e) {
            reportException(e);
            console.log(e)
        }

        return false;
    }

    // Return a list of posts with the specified length
    // If the request fails or there are not posts, an empty list is returned
    async getSelectedPosts() {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/selected",
                requestOptions
            );
            if (!response.ok)
                throw new Error("Bad response")

            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
            return [];
        }
    }

    async getMixedPosts() {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/mix",
                requestOptions
            );
            if (!response.ok)
                throw new Error("Bad response")

            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
            return [];
        }
    }

    // Return a list of posts with the specified length
    // If the request fails or there are not posts, an empty list is returned
    async getLatestPosts() {
        const jwt = await accountService.retryGetJwtToken();
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };
        if (jwt) {
            requestOptions.headers['Authorization'] = `Bearer ${jwt}`;
        }
        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Posts/latest",
                requestOptions
            );
            if (!response.ok)
                throw new Error("Bad response")

            return await response.json();
        } catch (e) {
            reportException(e);
            console.log(e);
            return [];
        }
    }


    // Returns [lat, lng] on success otherwise null
    async zipCodeToLatLng(zipCode) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        if (!isValidZip(zipCode)) return null;

        try {
            let response = await fetch(
                "https://api.dataforsyningen.dk/postnumre?nr=" + zipCode,
                requestOptions
            );

            const json = (await response.json())[0];

            if (!response.ok || json == undefined)
                throw new Error("Error converting zip code to lat lng");

            return [json["visueltcenter"][1], json["visueltcenter"][0]];
        } catch (e) {
            console.log("Failed to convert zip code to lat lng");
            console.log(e);
        }
    }

    // Returns zip code on success otherwise null
    async latLngToZipCode(lat, lng) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        try {
            let response = await fetch(
                `https://api.dataforsyningen.dk/postnumre/reverse?y=${lat}&x=${lng}`,
                requestOptions
            );

            const json = await response.json();

            if (!response.ok)
                throw new Error("Error converting zip code to lat lng");

            return json["nr"];
        } catch (e) {
            reportException(e);
            console.log("Failed to convert lat/lng to zip code");
            console.log(e);
        }
    }

    async sendRedirectionInformation(postId){
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({postId: postId})
        }

        try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/api/Posts/redirection/track",
            requestOptions
        );

        if (!response.ok)
            throw new Error("Bad response")

        return true;
        } catch (e) {
            reportException(e);
            console.log(e)
        }
    }

}

export default new PostsService();
