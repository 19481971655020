import * as React from 'react';
import {styled} from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {observer} from "mobx-react-lite";
import {useNavigate, useSearchParams} from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Typography} from "@mui/material";
import {useEffect} from "react";
import {categoryStore} from "../../Stores/CategoryStore";
import Skeleton from "@mui/material/Skeleton";
import {useSearchBox} from "react-instantsearch-hooks-web";


function handleClick(event) {
    event.preventDefault();
}

const BreadCrumbSkeleton = () => {
    return <Skeleton>
        <Typography>{"Home > Jagttøj"}</Typography>
    </Skeleton>;
}

const StyledCrumb = styled(Typography)(({theme}) => ({
    fontSize: "18px",
}));

export const StyledNavigationCrumb = styled(StyledCrumb)(({theme}) => ({
    cursor: "pointer",
    "&:hover": {
        color: theme.palette.jagtred.main,
    }
}));

function BreadCrumbs(props) {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get("category")?.split(" > ") ?? [];
    const queryParam = searchParams.get("query");

    if (!categoryStore.isInitialized) {
        return <BreadCrumbSkeleton/>
    }

    return <Breadcrumbs
        sx={props.sx}
        separator={<NavigateNextIcon fontSize="small"/>}
    >
        {(category.length > 0 || queryParam != null) && (
            <StyledNavigationCrumb
                onClick={() => {navigate("/search")}}
            >Søg</StyledNavigationCrumb>
        )}

        {/* If a category is chosen show it as a crumb */}
        {(category.length > 0)  && <StyledNavigationCrumb
            onClick={() => {
                searchParams.delete("page");
                searchParams.delete("query");
                searchParams.set("page", 1);
                searchParams.set("category", category[0]);
                setSearchParams(searchParams);
            }}
        >
            {category[0]}
        </StyledNavigationCrumb>}

        {/* If a subcategory is chosen show it as a crumb */}
        {(category.length > 1)  && <StyledNavigationCrumb
            onClick={() => {
                searchParams.delete("page");
                searchParams.delete("query");
                searchParams.set("page", 1);
                searchParams.set("category", category[0] + " > " + category[1]);
                setSearchParams(searchParams);
            }}
        >
            {category[1]}
        </StyledNavigationCrumb>}

        {/* If a query is present, show it as a crumb */}
        {(queryParam != null)   && <StyledCrumb>
            {queryParam}
        </StyledCrumb>}
    </Breadcrumbs>
}

export default observer(BreadCrumbs);
