interface Source {
    webp: string[];
    png: string[];
    fallback: string;
    altText: string;
}

interface Sources {
    [key: string]: {
        [size: number]: Source;
    };
}

const sources: Sources = {
    "logo-round-text": {
        150: {
            webp: [
                "/Images/Logos/Round/Text/150w/300w.webp 150w",
                "/Images/Logos/Round/Text/150w/600w.webp 300w",
            ],
            png: [
                "/Images/Logos/Round/Text/150w/300w.png 150w",
                "/Images/Logos/Round/Text/150w/600w.png 300w",
            ],
            fallback: "/Images/Logos/Round/Text/150w/600w.png",
            altText: "Jagtbasen logo"
        },
    },
    "logo-round-no-text": {
        115: {
            webp: [
                "/Images/Logos/Round/NoText/115w/230w.webp 115w",
                "/Images/Logos/Round/NoText/115w/460w.webp 230w",
            ],
            png: [
                "/Images/Logos/Round/NoText/115w/230w.png 115w",
                "/Images/Logos/Round/NoText/115w/460w.png 230w",
            ],
            fallback: "/Images/Logos/Round/NoText/115w/460w.png",
            altText: "Jagtbasen logo"
        },
        180: {
            webp: [
                "/Images/Logos/Round/NoText/180w/360w.webp 180w",
                "/Images/Logos/Round/NoText/180w/720w.webp 360w",
            ],
            png: [
                "/Images/Logos/Round/NoText/180w/360w.png 180w",
                "/Images/Logos/Round/NoText/180w/720w.png 360w",
            ],
            fallback: "/Images/Logos/Round/NoText/180w/720w.png",
            altText: "Jagtbasen logo"
        },
        250: {
            webp: [
                "/Images/Logos/Round/NoText/250w/500w.webp 250w",
                "/Images/Logos/Round/NoText/250w/1000w.webp 500w",
            ],
            png: [
                "/Images/Logos/Round/NoText/250w/500w.png 250w",
                "/Images/Logos/Round/NoText/250w/1000w.png 500w",
            ],
            fallback: "/Images/Logos/Round/NoText/250w/1000w.png",
            altText: "Jagtbasen logo"
        },
    },
    "logo-long-gold": {
        150: {
            webp: [
                "/Images/Logos/Long/Gold/150w/300w.webp 150w",
                "/Images/Logos/Long/Gold/150w/600w.webp 300w",
            ],
            png: [
                "/Images/Logos/Long/Gold/150w/300w.png 150w",
                "/Images/Logos/Long/Gold/150w/600w.png 300w",
            ],
            fallback: "Images/Logos/Long/Gold/150w/600w.png",
            altText: "Jagtbasen logo"
        },
    },
    "logo-long-red": {
        175: {
            webp: [
                "/Images/Logos/Long/Red/175w/350w.webp 175w",
                "/Images/Logos/Long/Red/175w/700w.webp 350w",
            ],
            png: [
                "/Images/Logos/Long/Red/175w/350w.png 175w",
                "/Images/Logos/Long/Red/175w/700w.png 350w",
            ],
            fallback: "/Images/Logos/Long/Red/175w/700w.png",
            altText: "Jagtbasen logo"
        },
        225: {
            webp: [
                "/Images/Logos/Long/Red/225w/450w.webp 225w",
                "/Images/Logos/Long/Red/225w/900w.webp 450w",
            ],
            png: [
                "/Images/Logos/Long/Red/225w/450w.png 225w",
                "/Images/Logos/Long/Red/225w/900w.png 450w",
            ],
            fallback: "/Images/Logos/Long/Red/225w/900w.png",
            altText: "Jagtbasen logo"
        },
    },

    "search-banner": {
        175: {
            webp: [
                "/Images/SearchBanner/175w/350w.webp 175w",
                "/Images/SearchBanner/175w/700w.webp 350w",
            ],
            png: [
                "/Images/SearchBanner/175w/350w.png 175w",
                "/Images/SearchBanner/175w/700w.png 350w",
            ],
            fallback: "/Images/SearchBanner/175w/700w.png",
            altText: "Search banner"
        },
    },


    "payment-credit-card": {
        117: {
            webp: [
                "/Images/Payment/CreditCard/117w/234w.webp 117w",
                "/Images/Payment/CreditCard/117w/468w.webp 234w",
            ],
            png: [
                "/Images/Payment/CreditCard/117w/234w.png 117w",
                "/Images/Payment/CreditCard/117w/468w.png 234w",
            ],
            fallback: "/Images/Payment/CreditCard/117w/468w.png",
            altText: "Credit cards"
        },
        146: {
            webp: [
                "/Images/Payment/CreditCard/146w/292w.webp 146w",
                "/Images/Payment/CreditCard/146w/584w.webp 292w",
            ],
            png: [
                "/Images/Payment/CreditCard/146w/292w.png 146w",
                "/Images/Payment/CreditCard/146w/584w.png 292w",
            ],
            fallback: "/Images/Payment/CreditCard/146w/584w.png",
            altText: "Credit cards"
        },
        176: {
            webp: [
                "/Images/Payment/CreditCard/176w/352w.webp 176w",
                "/Images/Payment/CreditCard/176w/704w.webp 352w",
            ],
            png: [
                "/Images/Payment/CreditCard/176w/352w.png 176w",
                "/Images/Payment/CreditCard/176w/704w.png 352w",
            ],
            fallback: "/Images/Payment/CreditCard/176w/704w.png",
            altText: "Credit cards"
        },
        206: {
            webp: [
                "/Images/Payment/CreditCard/206w/412w.webp 206w",
                "/Images/Payment/CreditCard/206w/824w.webp 412w",
            ],
            png: [
                "/Images/Payment/CreditCard/206w/412w.png 206w",
                "/Images/Payment/CreditCard/206w/824w.png 412w",
            ],
            fallback: "/Images/Payment/CreditCard/206w/824w.png",
            altText: "Credit cards"
        },
    },
    "payment-mobile-pay-transparent": {
        30: {
            webp: [
                "/Images/Payment/MobilePay/Transparent/30w/60w.webp 30w",
                "/Images/Payment/MobilePay/Transparent/30w/120w.webp 60w",
            ],
            png: [
                "/Images/Payment/MobilePay/Transparent/30w/60w.png 30w",
                "/Images/Payment/MobilePay/Transparent/30w/120w.png 60w",
            ],
            fallback: "/Images/Payment/MobilePay/Transparent/30w/120w.png",
            altText: "Mobile Pay logo"
        },
        37: {
            webp: [
                "/Images/Payment/MobilePay/Transparent/37w/74w.webp 37w",
                "/Images/Payment/MobilePay/Transparent/37w/148w.webp 74w",
            ],
            png: [
                "/Images/Payment/MobilePay/Transparent/37w/74w.png 37w",
                "/Images/Payment/MobilePay/Transparent/37w/148w.png 74w",
            ],
            fallback: "/Images/Payment/MobilePay/Transparent/37w/148w.png",
            altText: "Mobile Pay logo"
        },
    },
    "payment-mobile-pay-filled": {
        40: {
            webp: [
                "/Images/Payment/MobilePay/Filled/40w/80w.webp 40w",
                "/Images/Payment/MobilePay/Filled/40w/160w.webp 80w",
            ],
            png: [
                "/Images/Payment/MobilePay/Filled/40w/80w.png 40w",
                "/Images/Payment/MobilePay/Filled/40w/160w.png 80w",
            ],
            fallback: "/Images/Payment/MobilePay/Filled/40w/160w.png",
            altText: "Mobile Pay logo"
        },
        56: {
            webp: [
                "/Images/Payment/MobilePay/Filled/56w/112w.webp 56w",
                "/Images/Payment/MobilePay/Filled/56w/224w.webp 112w",
            ],
            png: [
                "/Images/Payment/MobilePay/Filled/56w/112w.png 56w",
                "/Images/Payment/MobilePay/Filled/56w/224w.png 112w",
            ],
            fallback: "/Images/Payment/MobilePay/Filled/56w/224w.png",
            altText: "Mobile Pay logo"
        },
    },
};

export default sources;
