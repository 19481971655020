import React, {useEffect, useImperativeHandle, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Box, Button, Slider, TextField, Typography, useTheme} from "@mui/material";
import Row from "../../Basic/Row";
import {formatNumber} from "../../../Utilities/postUtils";
import Column from "../../Basic/Column";
import Space from "../../Basic/Space";
import {useSearchContext} from "../../../Contexts/SearchContext";
import {FilterBox} from "./FilterBox";
import {CustomPopper} from "../../Shared/Shared";
import {useNavigate} from "react-router-dom";


export const PriceFilterWeb = React.forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const priceParam = searchParams.get("price");

    const [min, setMin] = useState( "");
    const [max, setMax] = useState("");

    useImperativeHandle(ref, () => {
        return {
            onNewRange: onNewRange
        }
    });

    useEffect(() => {
        // If there is no price param
        if (priceParam == null) {
            setMin("");
            setMax("");
            return;
        }

        // If there is a price param, update the UI
        const price = priceParam.split(":");

        if (price.length > 0) {
            setMin(price[0]);
        }

        if (price.length > 1) {
            setMax(price[1]);
        }
    }, [priceParam]);

    // Returns an object specifying the new price range
    const onNewRange = () => {
        if (min == "" && max == "") return {}
        return {price: `${min}:${max}`}
    }

    const onEnter = (event) => {
        event.target.blur();

        // If both fields are empty
        if (min == "" && max == "") {
            searchParams.delete("price");
        } else {
            searchParams.set("price", `${min}:${max}`);
        }

        searchParams.delete("page");
        setSearchParams(searchParams);
    }

    return <Box>
        <Typography sx={{fontWeight: "bold"}}>Pris</Typography>
        <Row
            sx={{
                paddingTop: "10px",
                alignItems: "center",
            }}
        >
            <TextField
                sx={{maxWidth: "100px"}}
                label={"Fra"}
                value={min}
                onChange={(event) => {
                    setMin(formatNumber({
                        value: event.target.value,
                        useComma: false
                    }));
                }}
                onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        onEnter(event);
                    }
                }}
            />
            <Typography
                sx={{padding: "0 5px"}}
            >-</Typography>
            <TextField
                sx={{maxWidth: "100px"}}
                label={"Til"}
                value={max}
                onChange={(event) => {
                    setMax(formatNumber({
                        value: event.target.value,
                        useComma: false
                    }));
                }}
                onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        onEnter(event);
                    }
                }}
            />
        </Row>
    </Box>
});

export const PriceFilterMobile = (props) => {
    const theme = useTheme();
    const searchContext = useSearchContext();

    const start = searchContext.data.start;
    const range = searchContext.data.range;

    const [searchParams, setSearchParams] = useSearchParams();
    const priceParam = searchParams.get("price");
    const price = priceParam?.split(":") ?? ["", ""];

    const isActive = priceParam != null;
    const disabled = range[0] == range[1];

    const [sliderValue, setSliderValue] = useState([start[0], start[1]]);
    const [fieldValue, setFieldValue] = useState([price[0], price[1]]);
    const [anchorEl, setAnchorEl] = useState(null);

    const anchorRef = React.useRef();
    const open = Boolean(anchorEl);

    const sliderMarks = [
        {
            value: disabled ? 1 : range[0],
            label: `${range[0]} kr`,
        },
        {
            value: disabled ? 3 : range[1],
            label: `${range[1]} kr`,
        }
    ];

    useEffect(() => {
        setSliderValue([start[0], start[1]]);
        setFieldValue([price[0], price[1]]);
    }, [start, priceParam, open]);

    const handleOpen = () => {
        setAnchorEl(anchorRef.current);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        setFieldValue(newValue);
    }

    const handleMinFieldChange = (event) => {
        let newValue = formatNumber({
            value: event.target.value,
            useComma: false
        });

        setFieldValue(fieldValue => [newValue, fieldValue[1]])

        setSliderValue(value => {
            if (newValue == "")
                return [range[0], value[1]];

            if (newValue > value[1])
                return [value[1], value[1]];

            return [parseInt(newValue), value[1]];
        });
    }

    const handleMaxFieldChange = (event) => {
        let newValue = formatNumber({
            value: event.target.value,
            useComma: false
        });

        setFieldValue(fieldValue => [fieldValue[0], newValue])

        setSliderValue(value => {
            if (newValue == "")
                return [value[0], range[1]];

            if (newValue < value[0])
                return [value[0], value[0]];

            return [value[0], parseInt(newValue)];
        });
    }

    const handleMaxFieldBlur = () => {
        setFieldValue(value => {
            return [
                value[0],
                value[1] == "" ? "" : sliderValue[1]
            ]
        })
    }

    const handleMinFieldBlur = () => {
        setFieldValue(value => {
            return [
                value[0] == "" ? "" : sliderValue[0],
                value[1]
            ]
        })
    }

    const handleSave = () => {
        searchParams.delete("page");

        if (fieldValue[0] == "" && fieldValue[1] == "") {
            searchParams.delete("price");
        } else {
            searchParams.set("price", `${fieldValue[0]}:${fieldValue[1]}`);
        }

        setSearchParams(searchParams);
        handleClose();
    }

    const handleReset = () => {
        setSliderValue(range);
        setFieldValue(["", ""]);
    }

    return <Box>
        <FilterBox
            title={"Pris"}
            anchorRef={anchorRef}
            onOpen={handleOpen}
            isActive={isActive}
            sx={{position: "relative"}}
        />
        <CustomPopper
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <Box
                sx={{minWidth: "250px"}}
            >
                <Column sx={{display: "flex", justifyContent: "end"}}>
                    <Typography
                        sx={{
                            width: "fit-content",
                            fontWeight: "bold",
                            cursor: "pointer",
                            color: theme.palette.jagtred.main,
                            "&:hover": {
                                textDecoration: "underline",
                            }
                        }}
                        onClick={handleReset}
                    >
                        Nulstil
                    </Typography>
                </Column>
                <Space height="10px"/>
                <Row sx={{justifyContent: "space-between"}}>
                    <TextField
                        sx={{maxWidth: "100px"}}
                        label={"Fra"}
                        value={fieldValue[0]}
                        onChange={handleMinFieldChange}
                        onBlur={handleMinFieldBlur}
                    />
                    <TextField
                        sx={{maxWidth: "100px"}}
                        label={"Til"}
                        value={fieldValue[1]}
                        onChange={handleMaxFieldChange}
                        onBlur={handleMaxFieldBlur}
                    />
                </Row>
                <Space height="5px"/>
                <Row>
                    <Slider
                        disabled={disabled}
                        value={disabled ? 2 : sliderValue}
                        onChange={handleSliderChange}
                        marks={sliderMarks}
                        min={disabled ? 1 : range[0]}
                        max={disabled ? 3 : range[1]}
                        valueLabelDisplay="auto"
                        sx={{
                            color: theme.palette.jagtred.main,
                            "& .MuiSlider-mark": {
                                color: "transparent",
                            },
                            margin: "10px"
                        }}
                    />
                </Row>
                <Space height="15px"/>
                <Box sx={{textAlign: "end"}}>
                    <Button
                        variant="contained"
                        color="jagtred"
                        sx={{margin: "5px 0 10px 0"}}
                        onClick={handleSave}
                    >
                        Gem
                    </Button>
                </Box>
            </Box>
        </CustomPopper>
    </Box>
}