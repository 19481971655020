import {observer} from "mobx-react-lite";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {chatStore} from "../../Stores/ChatStore";
import {
    Box,
    List,
    ListItem,
    Skeleton,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {accountStore} from "../../Stores/AccountStore";
import Column from "../Basic/Column";
import {
    getActivatePostConfirmation,
    getDeactivatePostConfirmation,
    getRepostConfirmation,
    useConfirmationDialog
} from "../Dialogs/ConfirmationDialog";
import Row from "../Basic/Row";
import useSnackbar from "../Snackbar/SnackbarHook";
import Space from "../Basic/Space";
import ClearIcon from '@mui/icons-material/Clear';
import {DesktopView, MobileView, useMediaContext} from "../../MediaContext";
import {MobileContainer} from "../Global/Global";
import FullEditBar from "./FullEditBar";
import {reportException} from "../../Utilities/sentry";
import {PostDesktop} from "./PostDesktop";
import {PostMobile} from "./PostMobile";
import { StyledNavigationCrumb } from "../BreadCrumbs/Breadcrumbs";
import { categoryStore } from "../../Stores/CategoryStore";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import postsService from "../../Services/PostsService";
import PostsService from "../../Services/PostsService";
import UserPostVisitsService from "../../Services/UserPostVisitsService";
import {capitalizeFirstLetter} from "../../Utilities/global";
import DeletePostConfirmation from "./DeletePostConfirmation";


const StatusBox = ({children}) => {
    const theme = useTheme();
    const mediaContext = useMediaContext();
    const margin = mediaContext.isMobile ? "10px 0 10px 0" : "10px 0 0 0";

    return <Box
        sx={{
            margin: margin,
            padding: "20px 20px",
            borderRadius: "4px",
            backgroundColor: theme.palette.information.yellow.background,
            lineHeight: "1.25",
            color: theme.palette.information.yellow.text,
            border: `1px solid ${theme.palette.information.yellow.border}`,
        }}
    >
        {children}
    </Box>;
}

/**
 * Return the appropriate status box for the post if needed
 */
const StatusBlock = ({post}) => {
    // If the post is in review
    if (post.Status == "InReview") {
        return <StatusBox>
            BEMÆRK: Annoncen afventer godkendelse og er derfor ikke aktiv. For at øge sikkerheden skal alle våbenannoncer godkendes manuelt.
        </StatusBox>
    }

    {/* If the post is not paid */}
    if (post.Status == "NotPaid") {
        return <StatusBox>
            BEMÆRK: Annoncen er under behandling og derfor ikke aktiv endnu.
        </StatusBox>
    }

    {/* If the post is expired */}
    if (post.Status == "Expired") {
        return <StatusBox>
            BEMÆRK: Annoncen er udløbet.
        </StatusBox>
    }

    {/* If the post is inactive but not expired */}
    if (post.Status == "Inactive") {
        return <StatusBox>
            BEMÆRK: Annoncen er ikke aktiv. Grunden kan være, at sælger er i gang med et salg.
        </StatusBox>
    }

    return null;
}

export const conditionMap = {
    "Perfect": "Perfekt",
    "Good": "God",
    "Decent": "Anstændig",
    "Defect": "Defekt"
}

export const danishKrone = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

function Post() {
    let params = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);

    const mediaContext = useMediaContext();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = mediaContext.isMobile;

    const getConfirmation = useConfirmationDialog();

    const [loading, setLoading] = useState(true);
    // Is it the users own post
    const [isMyPost, setIsMyPost] = useState(false);
    const [mainImg, setMainImg] = useState(null);

    // Popover when share is clicked
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const openPopover = Boolean(popoverAnchorEl);
    const idPopover = openPopover ? "simple-popover" : undefined;
    const [showPaidBox, setShowPaidBox] = useState(true);
    const isPurchase = searchParams.get("purchase") === "True";
    const purchaseItem = capitalizeFirstLetter(searchParams.get("item"));
    const isNew = searchParams.get("new");
    const [breadcrumbs, setBreadcrumbs] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);

        if (isNew == null) return;

        if (isNew === "True") {
            snackbar.handleOpen("Annonce oprettet", "success");
        } else {
            snackbar.handleOpen("Annonce opdateret", "success");
        }

        searchParams.delete("new");
        setSearchParams(searchParams);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await accountStore.getCurrentUser();
            const postData = await postsService.getPostById(params.postId).catch(err => setError(err));
            await chatStore.getChatByPost(postData);
            setPost(postData);
            const categoryName = categoryStore.categoryIdToName[postData.Subcategory.CategoryId];
            const category = categoryStore.categories.find(c => c.Name == categoryName ?? "")
            const subcategoryName = postData.Subcategory.Name;
            setIsMyPost(accountStore.user.id == postData.UserId);

            const breadcrumbs = [];

            breadcrumbs.push(
              <StyledNavigationCrumb
                key={"post-breadcrumb-1"}
                onClick={() => {
                    navigate("/");
                }}
              >Hjem</StyledNavigationCrumb>
            )

            breadcrumbs.push(
              <StyledNavigationCrumb
                key={"post-breadcrumb-2"}
                onClick={() => {
                    navigate(`/search/?page=1&category=${categoryName}`);
                }}
              >{categoryName}</StyledNavigationCrumb>
            )

            if (category.Subcategories.length > 1) {
                breadcrumbs.push(
                  <StyledNavigationCrumb
                    key={"post-breadcrumb-3"}
                    onClick={() => {
                        navigate(`/search/?page=1&category=${categoryName} > ${subcategoryName}`);
                    }}
                  >{subcategoryName}</StyledNavigationCrumb>
                )
            }

            setBreadcrumbs(breadcrumbs);

            setLoading(false);

            if (chatStore.isInboxClicked) {
                scrollToMessageArea();
            }
        }

        setLoading(true);
        fetchData().then(async (e)=>{
            if(accountStore.logged){
                await UserPostVisitsService.addUserPostVisit(params.postId)
            }
        }).catch((e) => {
            console.log(e);
            reportException(e);
        });
    }, [params.postId]);

    const onDeletePost = async (postId) => {
        if (loading) return;
        setDeleteDialogOpen(true);
        // const confirmed = await getDeletePostConfirmation(getConfirmation);
        //
        // if (confirmed) {
        //     const success = await PostsService.deletePost(postId);
        //
        //     if (!success) {
        //         snackbar.handleOpen("Fejl ved sletning", "error");
        //     } else {
        //         snackbar.handleOpen("Annonce slettet");
        //         navigate("/");
        //     }
        // }




    }

    const onDeactivatePost = async (postId) => {
        if (loading) return;

        const confirmed = await getDeactivatePostConfirmation(getConfirmation);

        if (confirmed) {
            const success = await PostsService.updatePostStatus(postId, false);

            if (!success) {
                snackbar.handleOpen("Fejl ved genindrykning", "error");
            } else {
                const postData = await postsService.getPostById(params.postId);
                setPost(postData);
            }
        }
    }

    const onActivatePost = async (postId) => {
        if (loading) return;

        const confirmed = await getActivatePostConfirmation(getConfirmation);

        if (confirmed) {
            const success = await PostsService.updatePostStatus(postId, true);

            if (!success) {
                snackbar.handleOpen("Fejl ved genindrykning", "error");
            } else {
                const postData = await postsService.getPostById(params.postId);
                setPost(postData);
            }
        }
    }

    const scrollToMessageArea = () => {
        if (chatStore.isInboxClicked) {
            chatStore.isInboxClicked = false
        }
        document.querySelector("#postMessages").scrollIntoView({
            behavior: "smooth",
        });
    };

    const SuccessTypography = styled(Typography)({
        color: theme.palette.information.green.text,
    });

    // If no post was found
    if (!loading && post == null) {
        return <Fragment>
            <MobileContainer sx={{flexGrow: "0"}}>
                <Space height="25px"/>
                <Typography variant="h4">Beklager, annoncen kunne ikke findes</Typography>
                <Typography variant="h5">Prøv at søge på noget andet eller ændre i filtrene</Typography>
            </MobileContainer>
        </Fragment>
    }

    if (error) {
        return <Fragment>
            <MobileContainer sx={{flexGrow: "0"}}>
                <Space height="25px"/>
                <Typography variant="h4">Beklager, annoncen kunne ikke findes</Typography>
                <Typography variant="h5">Prøv at søge på noget andet eller ændre i filtrene</Typography>
            </MobileContainer>
        </Fragment>
    }

    return (
        <Fragment>
            <MobileContainer sx={{mb: 2}}>
                {/* New paid post success box */}
                {isPurchase && showPaidBox && (
                    <Box
                        sx={{
                            marginTop: isMobile ? "20px" : "10px",
                            backgroundColor: theme.palette.information.green.background,
                            padding: "10px 15px 10px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <Row sx={{justifyContent: "space-between"}}>
                            <SuccessTypography sx={{fontWeight: "bold", fontSize: "20px"}}>
                                Betaling gennemført
                            </SuccessTypography>
                            <ClearIcon
                                sx={{
                                    color: theme.palette.information.green.text,
                                    fontSize: "18px",
                                    paddingTop: "5px",
                                    cursor: "pointer"
                                }}
                                onClick={() => setShowPaidBox(false)}
                            />
                        </Row>
                        <SuccessTypography>Du har købt følgende produkt:</SuccessTypography>
                        <List sx={{listStyleType: "disc", pl: 4, color: theme.palette.information.green.text}}>
                            <ListItem sx={{display: 'list-item', padding: 0}}>
                                <SuccessTypography sx={{fontWeight: "bold"}}>{purchaseItem}</SuccessTypography>
                            </ListItem>
                        </List>
                        <SuccessTypography sx={{fontSize: "14px"}}>Bemærk, det kan tage et par minutter før købet er
                            aktivt</SuccessTypography>
                    </Box>
                )}

                {post != null && isMobile && (
                    <Space height="5px"/>
                )}

                {post != null && (
                  <StatusBlock post={post}/>
                )}

                <Column>
                    {!loading && isMyPost && !isMobile && accountStore.logged && (
                        <FullEditBar
                            endDate={new Date(post.EndDate * 1000)}
                            handleDeactivatePost={onDeactivatePost}
                            handleActivatePost={onActivatePost}
                            handleDeletePost={onDeletePost}
                            post={post}
                        />
                    )}
                    <Box>

                        {/* If loading */}
                        {loading && (
                            <Stack spacing={2}>
                                <Skeleton variant="text" style={{maxWidth: isMobile ? "100%" : "515px"}} height={50}/>
                                <Skeleton variant="rectangular" style={{maxWidth: isMobile ? "100%" : "515px"}}height={400}/>
                                <Skeleton variant="rectangular"style={{maxWidth: isMobile ? "100%" : "300px", borderRadius: "4px"}} height={64}/>
                                <Skeleton variant="text" style={{maxWidth: isMobile ? "100%" : "515px"}} height={50}/>
                                <Skeleton variant="text" style={{maxWidth: isMobile ? "100%" : "515px"}}/>
                                <Skeleton variant="text" style={{maxWidth: isMobile ? "100%" : "515px"}}/>
                            </Stack>
                        )}

                        {/* If not loading */}
                        {!loading && (
                            <Fragment>
                                <Breadcrumbs
                                    sx={{margin: "10px 0"}}
                                    separator={<NavigateNextIcon fontSize="small"/>}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>

                                <MobileView>
                                    <PostMobile
                                        condition={conditionMap}
                                        loading={loading}
                                        isMyPost={isMyPost}
                                        onDeactivate={onDeactivatePost}
                                        onActivate={onActivatePost}
                                        onDelete={onDeletePost}
                                        scrollToMessageArea={scrollToMessageArea}
                                        post={post}
                                    />
                                </MobileView>

                                <DesktopView>
                                    <PostDesktop
                                        condition={conditionMap}
                                        loading={loading}
                                        scrollToMessageArea={scrollToMessageArea}
                                        post={post}
                                    />
                                </DesktopView>
                                <DeletePostConfirmation deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen} postId={post.PostId} fetchDataHandle={undefined} />
                            </Fragment>
                        )}
                    </Box>
                </Column>
            </MobileContainer>
        </Fragment>
    );
}

export default observer(Post);
