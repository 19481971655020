import {makeStyles} from "@mui/styles";
import {accountStore} from "../../Stores/AccountStore";
import { Box, createTheme, Fab, Grid, TextField, ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import React from 'react';
import {chatStore} from "../../Stores/ChatStore";
import {useMediaContext} from "../../MediaContext";
import Row from "../Basic/Row";
import Space from "../Basic/Space";
import SendIcon from "@material-ui/icons/Send";
const useStyles = makeStyles({
    chatSection: {
        width: '100%'
    },
    logoColor :{
        color : '#5e1022'
    }
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#5e1022'
        }
    },
});
function InitialChatBox({post}) {
    const classes = useStyles();
    const customMessages = ["Er varen stadig til salg?","Er Prisen til forhandling?"]
    const [textMessage, setTextMessage] = React.useState("");
    const mediaContext = useMediaContext();

    const handleChange = (event) => {
        setTextMessage(event.target.value);
    };

    const handleTypedMessage = async () => {
       await sendMessage(textMessage)
    };

    const sendMessage = async (message) => {
        const sender = accountStore.userInfo.attributes.sub;
        await chatStore.createInbox(post, sender, message);
    };

    return (
      <Box maxWidth={700} sx={{ pt: 2 }}>
          <Grid container className={classes.chatSection}>
              {!mediaContext.isMobile && (
                  <Grid item xs={12}>
                      <Grid container spacing={2}>
                          <ThemeProvider theme={theme}>
                              {customMessages.map((value, index) =>
                                  <Grid item xs={6} key={"initial-chat-box-" + index}>
                                      <Button
                                          variant="outlined"
                                          fullWidth={true}
                                          sx={{whiteSpace: "nowrap"}}
                                          onClick={async () => { await sendMessage(value)}}
                                      >
                                          {value}
                                      </Button>
                                  </Grid>
                              )}
                          </ThemeProvider>
                      </Grid>
                      <Space height="15px"/>
                  </Grid>
              )}
              <Grid item xs={12}>
                  <Row sx={{
                      alignItems: "center"
                  }}>
                      {/* Input field */}
                      <TextField
                          id="outlined-basic-email"
                          label="Skriv en privat besked til sælger. Husk den gode tone…"
                          value={textMessage}
                          onChange={handleChange}
                          fullWidth
                      />

                      <Space width="15px"/>

                      {/* Send icon */}
                      <Box>
                          {textMessage === ""?
                              <Fab
                                  size={"medium"}
                                  disabled={true}
                                  sx={{
                                      background: '#cccccc',
                                      zIndex: 0
                                  }}
                                  aria-label="add"
                                  onClick={handleTypedMessage}
                              >
                                  <SendIcon htmlColor={"white"}/>
                              </Fab>:
                              <Fab
                                  size={"medium"}
                                  sx={{
                                      background: 'linear-gradient(to right, #5e1022 60%, #76142a)',
                                      zIndex: 0
                                  }}
                                  aria-label="add"
                                  onClick={handleTypedMessage}
                              >
                                  <SendIcon htmlColor={"white"}/>
                              </Fab>
                          }
                      </Box>
                  </Row>
              </Grid>
          </Grid>
      </Box>
    );
}

export default observer(InitialChatBox)