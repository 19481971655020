import React, {useEffect, useState} from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import {isMobile} from 'react-device-detect';

/**
 * The context provide media data for the whole application
 * such as window width, height, etc.
 */

const MediaContext = React.createContext({});

export const MediaContextProvider = ({children}) => {
    const theme = useTheme();
    const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isUnderMd = useMediaQuery(theme.breakpoints.down("md"));
    const isUnderLg = useMediaQuery(theme.breakpoints.down("lg"));
    const isUnderXl = useMediaQuery(theme.breakpoints.down("xl"));

    /**
     * When isMobile is true, the app should not be wrapped in a <Container>
     * thereby allowing the app to take up the full width of the screen.
     * We also switch to the mobile top-bar at this point.
     */
    const mobile = useMediaQuery(`(max-width:900px)`);

    return <MediaContext.Provider
        value={{
            isUnderSm: isUnderSm,
            isUnderMd: isUnderMd,
            isUnderLg: isUnderLg,
            isUnderXl: isUnderXl,
            isMobile: mobile,
        }}>
        {children}
    </MediaContext.Provider>
}

export const useMediaContext = () => {
    return React.useContext(MediaContext);
}

// Renders only on desktop
export const DesktopView = ({children}) => {
    const mediaContext = useMediaContext();

    if (mediaContext.isMobile) return null;
    return <React.Fragment>{children}</React.Fragment>;
}

// Renders only on mobile
export const MobileView = ({children}) => {
    const mediaContext = useMediaContext();

    if (!mediaContext.isMobile) return null;
    return <React.Fragment>{children}</React.Fragment>;
}


