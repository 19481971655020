import {SvgIcon} from "@mui/material";
import React from "react";
export function ApprovedIcon({fontSize}) {
    return(
      <SvgIcon
          style={{fontSize: fontSize ?? "20px"}}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="262.639 343.184 163.5 187.6"
      >
              <path d="m335.55 528.68c-27.068-9.8176-51.826-31.395-62.991-54.897-7.6767-16.16-8.056-18.884-9.0482-64.997-0.48507-22.543-0.82881-41.024-0.76388-41.068 0.0649-0.0442 18.462-5.5601 40.881-12.257l40.763-12.177 80.239 23.628 0.68399 7.5482c1.0415 11.494 0.98035 50.602-0.10406 66.571-1.0314 15.187-3.2423 23.499-9.5959 36.077-9.7737 19.348-27.546 35.285-53.07 47.591-14.069 6.7831-17.767 7.3287-26.995 3.982zm46.455-122.09c0.80481-2.5358-1.486-5.4579-4.2788-5.4579-0.61942 0-10.573 9.4682-22.119 21.04l-20.993 21.04-6.9103-6.3803c-10.415-9.6166-11.422-10.176-14.043-7.8035-1.1869 1.0741-2.158 2.7291-2.158 3.6777 0 0.94858 5.0415 6.8001 11.203 13.003l11.203 11.279 23.641-23.92c13.003-13.156 24.007-25.071 24.453-26.479z" stroke="#3166b0" fill="#3166b0"/>
      </SvgIcon>
    )
}