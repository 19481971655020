import { Box, styled } from "@mui/material";

type BoxProps = {
  width?: number | string;
  height?: number | string;
};

const Space = styled(Box)<BoxProps>(({ width, height }) => ({
  width: width,
  height: height,
  minWidth: width,
  minHeight: height,
}));

export default Space;
