import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import postsService from "../Services/PostsService";
import useSnackbar from "../Components/Snackbar/SnackbarHook";
import {useNavigate} from "react-router-dom";

const SearchContext = React.createContext({
    refineZipCode: () => {},
});

// The SearchContext is used to manage search state and
// provide global functionality outside the Algolia UI State
export const SearchContextProvider = ({children}) => {
    const snackbar = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();

    const _refineRadius = (radius) => {
        // If no radius is entered
        if (radius == "") searchParams.delete("radius");
        else searchParams.set("radius", radius);
    }
    const refineRadius = (radius) => {
        searchParams.delete("page");
        _refineRadius(radius)
        setSearchParams(searchParams);
    }

    const _refineZipCode = async (zipCode) => {
        // If no zip code is entered
        if (zipCode == "") {
            searchParams.delete("latLng");
            return;
        }

        // Convert zip code to latLng
        const latLng = await postsService.zipCodeToLatLng(zipCode);

        // If conversion failed
        if (latLng == null) {
            snackbar.handleOpen("Ugyldigt postnummer", "error");
            searchParams.delete("latLng");
        }

        // Update search params
        searchParams.set("latLng", latLng);
    }

    const refineZipCode = async (zipCode) => {
        searchParams.delete("page");
        await _refineZipCode(zipCode);
        setSearchParams(searchParams);
    }

    const refineRadiusAndZipCode = async (radius, zipCode) => {
        searchParams.delete("page");
        _refineRadius(radius);
        await _refineZipCode(zipCode);
        setSearchParams(searchParams);
    }

    // Default values
    const [data, setData] = useState({
        hits: [],
        start: [0, 1000],
        range: [0, 1000],
        nbPages: 0,
    });

    return (
        <SearchContext.Provider
            value={{
                data: data,
                setData: setData,
                refineRadius: refineRadius,
                refineZipCode: refineZipCode,
                refineRadiusAndZipCode: refineRadiusAndZipCode,
        }}
        >
            {children}
        </SearchContext.Provider>
    );
}

export const useSearchContext = () => {
    return React.useContext(SearchContext);
}