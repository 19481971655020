import {Box, Typography} from "@mui/material";
import React from "react";

export const FilterBox = ({title, onOpen, isActive, anchorRef }) => {
    return <Box sx={{position: "relative"}}>
        {isActive && (
            <Box sx={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
                borderRadius: "50%",
                backgroundColor: "black",
                minWidth: "25px",
                minHeight: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography
                    sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "white",
                    }}
                >1</Typography>
            </Box>
        )}
        <Box
            ref={anchorRef}
            sx={{
                border: "1px solid #000",
                borderRadius: "5px",
                width: "fit-content",
                padding: "7px 15px",
                cursor: "pointer",
            }}
            style={isActive ? {borderWidth: "3px"} : {}}
            onClick={onOpen}
        >
            <Typography
                sx={{fontSize: "18px"}}
                style={isActive ? {fontWeight: "bold"} : {}}
            >
                {title}
            </Typography>
        </Box>
    </Box>
}