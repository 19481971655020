import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {categoryStore} from "../../../Stores/CategoryStore";
import {deepCopy} from "../../../Constants/Constants";
import {
    Box,
    Tabs,
    Typography, useTheme,
} from "@mui/material";
import Row from "../../Basic/Row";
import {IconFactory} from "../../Shared/Icons/Factory/IconFactory";
import Space from "../../Basic/Space";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {MobileContainer} from "../../Global/Global";
import {useNavigate} from "react-router-dom";
import Column from "../../Basic/Column";

const CategoryFilterWebSkeleton = () => {
    // Generate loading rows
    let rows = [];
    const widths = ["95px", "120px", "135px", "95px", "140px", "130px", "85px", "100px", "120px", "80px", "110px", "140px"]
    for (let i = 0; i < widths.length; i++) {
        rows.push(
            <React.Fragment key={"category-filter-web-skeleton-" + i}>
                <Row>
                    <Skeleton
                        variant="rectangular"
                        width="35px"
                        height="35px"
                        sx={{
                            borderRadius: "5px",
                        }}
                    />
                    <Space width="15px"/>
                    <Skeleton
                        variant="text"
                        width={widths[i]}
                    />
                </Row>
                <Space height="15px" />
            </React.Fragment>
        )
    }

    return <Column sx={{width: "fit-content", paddingRight: "30px"}}>
        <Typography variant="h5">Kategorier</Typography>
        <Space height="10px" />
        {rows}
    </Column>
}

export const CategoryFilterWeb = observer(() => {
    const [ categories, setCategories ] = useState([]);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const categoryParam = searchParams.get("category");

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Wait for the category store to be initialized
        if (!categoryStore.isInitialized) return;

        // Get a fresh copy of the categories
        const newState = deepCopy(categoryStore.categories);

        // Extract the category filter
        const categoryFilter = categoryParam?.split(" > ");

        // Mark the chosen category and subcategory in the new state
        if (categoryFilter != null) {
            // Find the matching category
            const category = newState.find(c => c.Name == categoryFilter[0]);

            // Mark it as chosen
            category.isRefined = true;

            if (categoryFilter[1] != null) {
                //Find the matching subcategory
                const subCategory = category.Subcategories.find(s => s.Name == categoryFilter[1]);

                // Mark it as chosen
                subCategory.isRefined = true;
            }
        }

        setCategories(newState);
    }, [categoryStore.isInitialized, categoryParam]);

    if (!categoryStore.isInitialized)
        return <CategoryFilterWebSkeleton/>;

    return <Box sx={{minWidth: "200px", maxWidth: "200px", paddingRight: "30px"}}>
        <Typography variant="h5">Kategorier</Typography>

        {/* CATEGORIES */}
        {categories.map(category => {
            return <React.Fragment key={"search-results-categories-" + category.Name}>
                <Row sx={{alignItems: "center", marginTop: "10px"}}>
                    <IconFactory icon={category.Name} width="30px"/>
                    <Space width="10px"/>
                    <Typography
                        sx={{cursor: "pointer", fontSize: "18px"}}
                        style={category.isRefined ? {fontWeight: "bold", color: theme.palette.jagtred.main} : {}}
                        onClick={() => {
                            // If we are not on the search page, navigate to it
                            if (!location.pathname.includes("search")) {
                                return navigate("/search?category=" + category.Name)
                            }

                            // Else we update the search parameters
                            searchParams.delete("page")

                            if (category.isRefined) {
                                searchParams.delete("category");
                            } else {
                                searchParams.set("category", category.Name);
                            }

                            setSearchParams(searchParams);
                        }}
                    >{category.Name}</Typography>
                </Row>

                {/* SUBCATEGORIES */}
                {category.isRefined && category.Subcategories.length > 1 && (
                    <React.Fragment>
                        <Space height="5px"/>
                        {category.Subcategories?.map(subCategory => {
                            return <React.Fragment key={"search-results-subcategories-" + subCategory.Name}>
                                <Typography
                                    sx={{
                                        cursor: "pointer",
                                        marginLeft: "40px",
                                        fontSize: "15px",
                                        lineHeight: "17px",
                                        color: theme.palette.jagtred.main,
                                    }}
                                    style={subCategory.isRefined ? {fontWeight: "bold"} : {}}
                                    onClick={() => {
                                        // If we are not on the search page, navigate to it
                                        if (!location.pathname.includes("search")) {
                                            return navigate("/search?category=" + category.Name + " > " + subCategory.Name)
                                        }

                                        // Else we update the search parameters
                                        searchParams.delete("page")

                                        if (subCategory.isRefined) {
                                            searchParams.set("category", category.Name);
                                        } else {
                                            searchParams.set("category", category.Name + " > " + subCategory.Name);
                                        }

                                        setSearchParams(searchParams);
                                    }}
                                >{subCategory.Name}</Typography>
                                <Space height="10px"/>
                            </React.Fragment>
                        })}
                    </React.Fragment>
                )}
                <Space height="10px"/>
            </React.Fragment>
        })}
    </Box>
})

const CategoryFilterMobileSkeleton = () => {
    // Generate loading rows
    let boxes = [];
    const widths = ["20%", "25%", "20%", "20%", "15%"]
    for (let i = 0; i < widths.length; i++) {
        boxes.push(
            <Skeleton
                key={"category-filter-mobile-skeleton-" + i}
                variant="rectangular"
                sx={{
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    minWidth: `calc(${widths[i]} - 5px)`,
                    margin: "0 5px 10px 0",
                }}
            >
                <Typography sx={{
                    padding: "10px 0",
                }}>
                    null
                </Typography>
            </Skeleton>
        )
    }

    return <MobileContainer>
        {/* Title */}
        <Skeleton
            variant="rectangular"
            sx={{borderRadius: "4px"}}
        >
            <Typography variant="h4">Alle kategorier</Typography>
        </Skeleton>

        <Space height="10px" />

        {/* Category boxes */}
        <Row sx={{justifyContent: "start"}}>
            {boxes}
        </Row>
    </MobileContainer>
}

const TabItem = ({name, path}) => {
    const theme = useTheme();
    const [ searchParams, setSearchParams ] = useSearchParams();

    return <React.Fragment>
        <Typography
            sx={{
                padding: "10px 15px",
                cursor: "pointer",
                backgroundColor: theme.palette.grey[200],
                borderRadius: "5px",
                fontSize: "18px"
            }}
            onClick={() => {
                searchParams.set("category", path);
                setSearchParams(searchParams);
            }}
        >{name}</Typography>
        <Space width="5px"/>
    </React.Fragment>
}

export const CategoryFilterMobile = observer(() => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const param = searchParams.get("category")?.split(" > ");
    const title = param?.[param.length -1] ?? "Alle kategorier";
    const category = categoryStore.categories.find(c => c.Name == param?.[0] ?? "")
    const showSubcategories = category != null && category.Subcategories.length > 1;

    if (!categoryStore.isInitialized)
        return <CategoryFilterMobileSkeleton/>;

    return <MobileContainer>
        <Space height="10px" />
        {showSubcategories && (
            <React.Fragment>
                <ArrowBackIcon
                    sx={{cursor: "pointer"}}
                    onClick={() => {

                        if (param.length == 1) {
                            searchParams.delete("category")
                        } else {
                            searchParams.set("category", param[0]);
                        }

                        setSearchParams(searchParams);
                    }}
                />
                <Space height="5px" />
            </React.Fragment>
        )}
        <Typography variant="h4">{title}</Typography>
        <Space height="10px" />
        <Tabs
            value={false}
            variant="scrollable"
            scrollButtons={false}
        >
            {/* If no category is chosen or there are no subcategories for the chosen category */}
            {!showSubcategories && (
                categoryStore.categories.map(item => {
                    return <TabItem
                        key={"category-filter-mobile-" + item.Name}
                        name={item.Name}
                        path={item.Name}
                    />
                }
            ))}

            {/* If a category is chosen and there are subcategories for the chosen category */}
            {showSubcategories && (
                category.Subcategories.map(item => {
                    return <TabItem
                        key={"category-filter-mobile-" + item.Name}
                        name={item.Name}
                        path={`${param[0]} > ${item.Name}`}
                    />
                }
            ))}
        </Tabs>
        <Space height="10px" />
    </MobileContainer>

})