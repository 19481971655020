import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField
} from "@mui/material";

import Button from "@mui/material/Button";
import ReportService from "../../Services/ReportService";
import ReportDto from "../../DTO/ReportDto";
import CircularProgress from "@mui/material/CircularProgress";
import { accountStore } from "../../Stores/AccountStore";

const ReportPostPopUp = ({ open, handleClose, submit, postId, email}) => {
  const [reportType, setReportType] = useState('');
  const [reportMessage, setReportMessage] = useState('');
  const [reportCategories, setReportCategories] = useState([]);
  const [loading,setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchReportCategories() {
      const categories = await ReportService.getReportCategories();
      setReportCategories(categories)
    }
    fetchReportCategories();
  }, []);
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const handleReportMessageChange = (event) => {
    setReportMessage(event.target.value);
    if (event.target.value.length > 500) {
      setError('Maks 500 bogstaver er tilladt.');
    } else {
      setError('');
    }
  };

  const handleSubmit = async () => {
    if (!reportType || !email || !reportMessage) {
      alert('Venligst, udfyld alle felter');
      return;
    }
    setLoading(true)
    const category = reportCategories.find((c) => c.Name === reportType);
    const report = ReportDto.ReportDTO(postId, category.Id, accountStore.userInfo.attributes.sub, reportMessage)
    const result = await ReportService.createReport(report)
    setReportType('')
    setReportMessage('')
    setLoading(false)
    submit(result);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth maxWidth="sm">
      <DialogTitle>Anmeld denne annonce</DialogTitle>
      {loading ? <CircularProgress sx={{color: '#5e1022'}} /> :
      <DialogContent>
        <DialogContentText>
          Udfyld venligst følgende felter for at anmelde denne annonce.
        </DialogContentText>
        <Box sx={{ pt: 2 }} />
        <FormControl
          autoFocus
          required={true}
          sx={{
            '& label.Mui-focused': {
              color: 'black',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
              '&.Mui-error fieldset': {
                borderColor: 'red',
              },
            }
          }}
        >
          <InputLabel
            htmlFor="report-type"
            sx={{
              zIndex: 2, // Ensure label is above the line
              background: 'white', // Ensure label stands out
              padding: '0 4px', // Add some spacing around the label
            }}
          >
            Anmeld
          </InputLabel>
          <Select
            native
            value={reportType}
            onChange={handleReportTypeChange}
            inputProps={{
              name: 'report-type',
              id: 'report-type',
              required: true,
            }}
            sx={{
              zIndex: 1, // Ensure select is below the label
            }}
          >
            <option aria-label="Ingen" value="" />
            {
              reportCategories.map((category) =>
              <option key={category.Id} value={category.Name}>{category.Name}</option>
              )
            }
          </Select>
        </FormControl>
        <Box sx={{ pt: 1 }} />
        <TextField
          margin="dense"
          id="email"
          label="Din mail addresse"
          type="email"
          fullWidth
          value={email}
          required
          disabled={true}
        />
        <TextField
          sx={{'& label.Mui-focused': {
              color: 'black',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
              '&.Mui-error fieldset': {
                borderColor: 'red',
              },
            }
        }}
          margin="dense"
          id="report-message"
          label="Anmeldelse"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={reportMessage}
          onChange={handleReportMessageChange}
          error={!!error}
          required
        />
      </DialogContent>
      }
      <DialogActions>
        <Button onClick={handleClose} sx={{color : "#5E1022FF"}} disabled={!!error || loading}>
          Annuller
        </Button>
        <Button onClick={handleSubmit} sx={{color : "#5E1022FF"}} disabled={!!error || loading}>
          Anmeld
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportPostPopUp;
