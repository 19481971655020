import React, {FC, ReactNode} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {openDialogFunction} from "./ConfirmationDialog";
import CloseIcon from '@mui/icons-material/Close';
import Row from "../Basic/Row";

/*
  HOW TO USE:
  const showInfo = useInfoDialog()

  await showInfo(
    "Title",
    "Message",
    <div/>
  );
*/

const InfoDialog: FC<{
    open: boolean;
    onDismiss: () => void;
    title?: string;
    message?: string;
    node?: ReactNode;
}> = ({ open, onDismiss, title, message, node }) => {
    return (
        <Dialog open={open} onClose={onDismiss}>
            {/* Close icon */}
            <Row
                sx={{
                    justifyContent: "end",
                    padding: "10px 10px 0 0",
                }}
            >
                <CloseIcon
                    sx={{
                        cursor: "pointer",
                        color: "grey.600",
                        fontSize: "22px",
                    }}
                    onClick={onDismiss}
                />
            </Row>
            {title != null && (
                <DialogTitle
                    sx={{paddingTop: "0"}}
                >{title}</DialogTitle>
            )}
            {message != null && (
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
            )}
            {node != null && (
                <DialogContent sx={{paddingTop: 0}}>
                    {node}
                </DialogContent>
            )}
        </Dialog>
    );
};

type dialogState = {
    open: boolean;
    callback?: (value: boolean) => void;
    title?: string;
    message?: string;
    node?: ReactNode;
};

const  InfoDialogContext = React.createContext<openDialogFunction>(
    {} as openDialogFunction
);

export const InfoDialogProvider: FC = ({children}) => {
    const [dialogConfig, setDialogConfig] = React.useState<dialogState>({
        open: false,
    })

    const openDialog: openDialogFunction = (callback, title, message, node ) => {
        setDialogConfig({
            open: true,
            callback: callback,
            title: title,
            message: message,
            node: node,
        })
    }

    const resetDialog = () => {
        setDialogConfig({ ...dialogConfig, open: false });
    }

    const onDismiss = () => {
        resetDialog();
        dialogConfig.callback!(true);
    };

    return (
        <InfoDialogContext.Provider value={openDialog}>
            <InfoDialog
                open={dialogConfig.open}
                title={dialogConfig.title!}
                message={dialogConfig.message!}
                node={dialogConfig.node!}
                onDismiss={onDismiss}
            />
            {children}
        </InfoDialogContext.Provider>
    );
}

export const useInfoDialog = () => {
    const openDialog = React.useContext(InfoDialogContext);

    const showInfo = (title?: string, message?: string, node?: ReactNode) =>
        new Promise((res) => {
            openDialog(res, title, message, node);
        });

    return showInfo;
};

