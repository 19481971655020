import Typography from "@mui/material/Typography";
import React from "react";
import Space from "../../Basic/Space";
import { Container } from "@mui/material";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function Rules() {
  return(
    <React.Fragment>
      <Space height="20px" />
      <Container>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography variant="h3">Annonceringsregler</Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          1. Generelle krav
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Alle annoncer på Jagtbasen skal overholde gældende lovgivning og disse regler for annoncering. Ved at oprette en annonce accepterer du at overholde disse regler og at påtage dig ansvaret for indholdet af dine annoncer.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          2. Lovligt og relevant indhold
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Annoncer skal indeholde lovligt, relevant og nøjagtigt indhold. Du må ikke annoncere for produkter, tjenester eller aktiviteter, der er ulovlige, vildledende, diskriminerende, stødende, ærekrænkende eller på anden måde upassende.
          Jagtbasen forbeholder os retten til at afvise annoncer, der efter vores skøn strider mod gældende lovgivning, hensynet til læserne og brugerne eller Jagtbasen tarv eller omdømme.
          Af hensyn til annonceplacering og forståelse af annoncer forbeholder Jagtbasen ret til at ændre teksten i en given annonce og til at give annoncen en mere korrekt placering i tilfælde af fejlplaceringer.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          3. Produkt- og tjenestebeskrivelse
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Beskrivelsen skal inkludere væsentlige oplysninger og egenskaber, såsom produktets eller tjenestens stand, størrelse, farve, mærke, model og eventuelle begrænsninger eller betingelser.
          Annoncer skal indeholde følgende:
        </Typography>
        <ul>
          <li>En tydelig og nøjagtig beskrivelse af det produkt eller den tjeneste, der tilbydes</li>
          <li>Antal og mængde skal altid fremgå af annoncen. Har du flere af den samme ting, kan du enten opgive prisen pr. stk. eller på den samlede mængde.</li>
          <li>En annonce pr. ting. Ved salg må den samme ting kun indrykkes i en annonce under en rubrik, altså kun et sted.</li>
          <li>En ting pr. annonce. Hver ting skal indrykkes i en annonce for sig. En undtagelse herfra er, når der er tale om flere ens ting eller ting, der naturligt hører sammen. Er der flere varer i en annonce, er det tilladt at oplyse den billigste pris i prisfeltet.
            For varer som hører sammen (f.eks. 4 fælge), skal den samlede pris oplyses.</li>
          <li>Alle annoncer skal være rubrikspecifikke. Det vil sige, at teksten skal omhandle det produkt, der bliver annonceret for.</li>
          <li>Søgeord/Tags: Det er ikke tilladt at indsætte søgeord i annonceteksten, som ikke har relevans til varen der bliver solgt</li>
        </ul>
        <Typography variant="h6" component="h1" gutterBottom>
          Privat annoncering
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Privat annoncering er som udgangspunkt gratis, dog er der visse undtagelser, hvor Jagtbasen opkræver et indrykningsgebyr. Der kan indtastes op til 2.000 tegn pr. annonce.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          4. Priser og betalingsbetingelser
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Annoncer skal angive den fulde pris for det produkt eller den tjeneste, der tilbydes, inklusive moms, samt eventuelle andre gebyrer. Betalingsbetingelser og -metoder skal være klart angivet og overholde gældende lovgivning og betalingsudbyderes krav.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          5. Billeder og medier
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Annoncer skal indeholde klare og nøjagtige billeder eller medier, der repræsenterer det produkt eller den tjeneste, der tilbydes. Du må ikke bruge billeder eller medier, der er vildledende, krænkende eller krænker tredjeparts ophavsret eller andre rettigheder.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          6. Forbudte varer og tjenester
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Jagtbasen forbeholder sig retten til at forbyde annoncer for bestemte varer og tjenester, herunder, men ikke begrænset til, ulovlige stoffer, pornografisk materiale, falske eller stjålne varer og tjenester, der krænker tredjepartsrettigheder.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          7. Annonceringsgebyrer og varighed
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Jagtbasen kan opkræve gebyrer for annoncering og fastsætte varigheden af en annonce. Du accepterer at betale disse gebyrer i henhold til de gældende betalingsvilkår og at overholde annoncens varighed. En annonce varer som udgangspunkt 4 uger, hvorefter den udløber og kan genfornys.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          8. Ændringer og sletning af annoncer
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Jagtbasen forbeholder sig retten til at redigere, slette eller afvise annoncer, der ikke overholder disse regler for annoncering, gældende lovgivning eller platformens generelle vilkår og betingelser.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          9. Ansvarsfraskrivelse
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Jagtbasen påtager sig intet ansvar for nøjagtigheden eller kvaliteten af annoncer eller for eventuelle tvister, der måtte opstå mellem købere og sælgere. Annoncører er ansvarlige for indholdet af deres annoncer og for eventuelle konsekvenser, der måtte opstå som følge af deres annoncer.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          10. Ophavsret og intellektuel ejendomsret
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du må ikke bruge materiale, der krænker ophavsret, varemærker, patenter eller andre intellektuelle ejendomsrettigheder i dine annoncer. Du erklærer og garanterer, at du har ret til at bruge alt materiale i dine annoncer, og at brugen af sådant materiale ikke krænker tredjeparts rettigheder.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          11. Kontaktinformation og kommunikation
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Annoncer skal indeholde korrekte og ajourførte kontaktinformationer, så potentielle købere kan kontakte dig vedrørende annoncen. Du accepterer at kommunikere på en høflig, ærlig og professionel måde med andre brugere og at overholde Jagtbasens retningslinjer for kommunikation og privatliv.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          12. Feedback og vurderinger
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Brugere kan give feedback og vurderinger i forbindelse med transaktioner.
          Du accepterer at give og modtage konstruktiv feedback og at overholde Jagtbasens retningslinjer for feedback og vurderinger.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          13. Overholdelse af lovgivning og regulering
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du er ansvarlig for at overholde alle lokale og nationale love og bestemmelser, der gælder for dine annoncer, herunder, men ikke begrænset til, skattelove, våbentilladelser, forbrugerbeskyttelseslove og regler for import og eksport.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          14. Ændringer i regler for annoncering
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi forbeholder os retten til at opdatere og ændre disse regler for annoncering fra tid til anden uden forudgående varsel. Det er dit ansvar løbende at gennemgå og overholde de gældende regler for annoncering. Din fortsatte brug af Jagtbasen efter ændringer i reglerne udgør din accept af sådanne ændringer. Ved at oprette og indsende en annonce på Jagtbasen accepterer du de vilkår og betingelser, der er angivet i disse regler for annoncering, og forstår, at du påtager dig det fulde ansvar for din annonce og for eventuelle konsekvenser, der måtte opstå som følge af din annonce.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          15. Annonceovervågning og rapportering
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi forbeholder os retten til at overvåge annoncer for at sikre overholdelse af disse regler for annoncering og gældende lovgivning. Hvis du støder på en annonce, der overtræder disse regler eller lovgivningen, opfordres du til at rapportere den til vores kundesupport. Vi vil tage passende skridt for at undersøge og tage handling i forhold til sådanne rapporter.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          16. Sanktioner ved overtrædelse
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Hvis du overtræder disse regler for annoncering, kan Jagtbasen træffe en række foranstaltninger, herunder, men ikke begrænset til, at slette eller ændre annoncen, udstede advarsler, suspendere eller opsige din konto og/eller indberette overtrædelser til myndighederne.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          17. Ansvarsbegrænsning
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi fraskriver os ethvert ansvar for tab, skader, krav eller udgifter, der måtte opstå som følge af dine annoncer, din brug af Jagtbasen eller dit forhold til andre brugere. Du accepterer at holde os skadesløse for eventuelle krav, der måtte opstå som følge af dine annoncer, herunder krav fra tredjeparter.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          18. Samtykke til elektronisk kommunikation
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at oprette en annonce accepterer du at modtage elektronisk kommunikation fra Jagtbasen, herunder e-mails og meddelelser vedrørende dine annoncer, kontooplysninger og Platformens tjenester. Du accepterer, at alle aftaler, meddelelser, oplysninger og anden kommunikation, som vi sender dig elektronisk, opfylder alle juridiske krav om, at sådan kommunikation skal være skriftlig.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at oprette og indsende en annonce på Jagtbasen bekræfter du din forståelse af og accept af disse regler for annoncering. Du accepterer at overholde reglerne og påtager dig ansvaret for indholdet af dine annoncer og eventuelle konsekvenser, der måtte opstå som følge af dine annoncer.
        </Typography>
      </Container>
    </React.Fragment>
  )
}