import {
  Box,
  ClickAwayListener, DialogActions, Grid, IconButton, Paper,
  Popper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from "@mui/material/Skeleton";
import React, { useRef, useState } from "react";
import {Avatar} from "@material-ui/core";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CloseIcon from "@mui/icons-material/Close";
import Space from "../Basic/Space";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

// Fills an entire table cell with a NavLink and pads its contents
export const NavCell = ({children, to, onClick, sx}) => {
    return <TableCell sx={{ padding: 0, height: "1px", ...sx }}>
        <NavLink
            to={to}
            onClick={onClick}
            style={{
                textDecoration: "none",
                color: "inherit"
            }}
        >
            <Box sx={{
                padding: "12px 15px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                {children}
            </Box>
        </NavLink>
    </TableCell>;
}

export const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.grey["A200"],
    borderTop: "1px solid rgba(224, 224, 224, 1)"
}));

export const StyledTableImage = styled("img")(({ theme }) => ({
    minHeight: "100px",
    minWidth: "150px",
    height: "100px",
    width: "150px",
    objectFit: "cover",
    borderRadius: "4px",
    cursor: "pointer",
    border: "2px solid" + theme.palette.grey["400"],
}));

// A table element to contain two lines of text
export const StyledTableBody = styled(Typography)({
    maxHeight: 50,
    overflow: "hidden",
    wordBreak: "break-word",
    cursor: "pointer"
});

export const StyledTableSubTitle = styled(Typography)({
    fontSize: "0.75rem",
    cursor: "pointer",
});

/**
 * Loads a four cell wide table skeleton.
 * The table header should be passed through the children prop.
 *
 */
export const WebTableSkeleton = ({children}) => {
    // Generate loading rows
    let rows = [];
    for (let i = 0; i < 3; i++) {
        rows.push(
            <TableRow key={"user-posts-full-loading-" + i}>
                {/* Image */}
                <TableCell>
                    <Skeleton
                        variant="rectangular"
                        width="150px"
                        height="100px"
                        sx={{
                            borderRadius: "10px",
                            marginTop: "6px",
                            marginBottom: "6px",
                        }}
                    />
                </TableCell>

                {/* Body */}
                <TableCell>
                    <Skeleton variant="text" width="70%" />
                    <Skeleton variant="text" width="50%" />
                    <Skeleton variant="text" width="20%" sx={{ fontSize: "0.75rem" }} />
                </TableCell>

                {/* Visits */}
                <TableCell sx={{ minWidth: "35px" }}>
                    <Skeleton variant="text" />
                </TableCell>

                {/* Status */}
                <TableCell sx={{ minWidth: "50px" }}>
                    <Skeleton variant="text" />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Table size="small">
            <TableBody>
                {children}
                {rows}
            </TableBody>
        </Table>
    );
};

export const CustomPopper = ({onClose, open, anchorEl, children}) => {
    return <React.Fragment>
        {/* Prevent clicks outside the popper */}
        {open && (
            <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    backgroundColor: "transparent",
                    height: "100%",
                    width: "100%",
                    zIndex: "1"
                }}
            />
        )}

        {/* The popper itself */}
        <Popper
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            placement={"bottom"}
            sx={{zIndex: "2"}}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Box sx={{
                    padding: "15px 30px 10px 30px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0 5px 10px 5px rgba(0, 0, 0, 0.2)",
                    margin: "4px 20px 0 20px",
                    maxWidth: "calc(100% - 40px)",
                }}>
                    {children}
                </Box>
            </ClickAwayListener>
        </Popper>
    </React.Fragment>
}

function stringAvatar(name) {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
}

export const AccountAvatar = ({radius, firstName, lastName}) => {
    return <Avatar style={{
        backgroundColor: "#5e1022",
        height: radius ?? "40px",
        width: radius ?? "40px",
    }}>
        {stringAvatar(firstName.trim() + " " + lastName.trim())}
    </Avatar>
}

export const trimNames = (namesString) => {
    const regex = /[a-zA-Z\u00C0-\u017F'\-]+/gm;
    const str = namesString;
    let match;
    let names = [];

    while ((match = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (match.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        match.forEach((match) => {
            names.push(`${match}`)
        });
    }
    return names.join(" ");
}

function PaperComponent(props) {
  return (
    <Paper {...props} />
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'unset',
    borderRadius: '5px'
  },
  customizedButton: {
    position: 'absolute',
    left: '97%',
    top: '-3%',
    backgroundColor: 'lightgray',
    color: 'gray',
  },
}));
export const ImagesViewer = ({ imageUrls, imageIndex, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(imageIndex);
  const imgRef = useRef();
  const classes = useStyles();
  const closeDialog = () => {
    setIsOpen(false);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={isOpen} onClose={closeDialog} maxWidth="md"
        PaperComponent = {PaperComponent}
        classes={{ paper: classes.paper }}
      >
        <Space height={15} />
        <DialogContent style={{ minWidth: 750, marginBottom : -28,marginTop: -10 }}>
            <TransformWrapper
            centerOnInit={true}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <TransformComponent wrapperStyle={{ width: "100%",backgroundColor:'rgba(112,112,112,0.07)',cursor:'zoom-in' }}>
                <img src={imageUrls[selectedImageIndex].Url} alt="test"
                     style={{
                       minHeight: 500,
                       maxHeight: 500,
                       maxWidth: 1000,
                       cursor: "pointer",
                        objectFit: 'contain',
                        display: 'inline-block', // Center horizontally
                     }}
                />
              </TransformComponent>
            </Box>
          </TransformWrapper>
          <Space height={10} />
          <Grid container spacing={1} justifyContent={"center"}>
            {imageUrls.map((imageUrl, index) => (
              <Grid item key={index} justify = "center">
                <img
                  ref={imgRef}
                  src={imageUrl.Url}
                  alt={`Thumbnail ${index}`}
                  style={{
                    border: selectedImageIndex === index ? "2px solid rgb(255 194 25)" : "none",
                    width: 60,
                    height: 60,
                    cursor: "pointer",
                    borderRadius: '5px',
                    objectFit: 'cover'
                  }}
                  onClick={() => setSelectedImageIndex(index)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ "&:hover": { backgroundColor: 'lightgrey' } }}
            className={classes.customizedButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const Ellipsis = ({children, sx, ...other}) => {
    return <Typography
        sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...sx
        }}
        {...other}
    >
        {children}
    </Typography>
}