import React, {CSSProperties} from 'react';
import sources from './PictureSources';

/**
 * PictureRender Component
 *
 * Purpose:
 * This component automate the rendering of static images, allowing for different
 * formats (webp, png) and sizes for optimal performance. It leverages a predefined
 * set of image sources to provide images for various use cases.
 *
 * How it Works:
 * - The component accepts `image` and `size` as props, along with any other standard HTML image attributes.
 * - It verifies the existence of the given image and size combination in the `sources` object.
 * - If the combination exists, it returns a `<picture>` element as described in the README file.
 *
 * Usage:
 * 1. Add new images to the `sources` object.
 * 2. Import the PictureRender component.
 * 2. Use the component in your JSX and pass the required props.
 *
 * Example:
 * ```jsx
 * <PictureRender image="logo-round-text" size={150} alt="Logo" />
 * ```
 *
 * This will render the appropriate image from the predefined sources, ensuring
 * optimal loading and display based on the specified format and size.
 */

interface PictureRenderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    image: keyof typeof sources;
    size: number;
}

const PictureRender: React.FC<PictureRenderProps> = ({ image, size, ...props }) => {
    if (!sources[image] || !sources[image][size]) {
        throw new Error(`Unsupported combination of image: ${image} and size: ${size}`);
    }

    const { webp, png, fallback, altText } = sources[image][size];

    return (
        <picture>
            <source srcSet={webp.join(', ')} sizes={`${size}px`} type="image/webp" />
            <source srcSet={png.join(', ')} sizes={`${size}px`} type="image/png" />
            <img
                {...props}
                src={fallback}
                alt={altText}
                style={{ width: `${size}px`, cursor: props.onClick ? 'pointer' : 'default', ...props.style }}
            />
        </picture>
    );
};

export default PictureRender;
