import {observer} from "mobx-react-lite";
import React from "react";
import Skeletons from "./Skeletons";
import {MobileContainer} from "../../../Global/Global";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Autoplay} from "swiper";
import {SwiperNavigationWrapper} from "../../../../Utilities/postUtils";
import PostCardStatic from "../../../Posts/PostCard";
import {Title} from "../../FrontPage";
import { frontpageStore } from "../../../../Stores/FrontpageStore";

function SelectedPosts() {
    const SwiperSlider = () => {
        return <SwiperNavigationWrapper>
            <Swiper
                style={{
                    height: "auto",
                    padding: "5px 0px",
                }}  // Color of the navigation arrows
                navigation={true} // Show navigation arrows
                spaceBetween={15}
                slidesPerView={"auto"}
                slidesPerGroupAuto={true}
                modules={[Navigation, Autoplay]} // Enable navigation module
                speed={500}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
            >
                {frontpageStore.selectedPosts.map((post, index) =>
                    <SwiperSlide
                        style={{
                            width: "auto",
                            textAlign: "start",
                        }}
                        key={`font-page-swiper-${index}`
                        }>
                        <PostCardStatic
                            key={post.PostId}
                            postId={post.PostId}
                            imageUrl={post.Images[0]?.Url}
                            price={post.Price}
                            heading={post.Heading}
                            body={post.Body}
                            highlight={post.Highlight}
                        />
                    </SwiperSlide>
                )}
            </Swiper>
        </SwiperNavigationWrapper>
    }

    return (
        <MobileContainer>
            <Title title={"Forsideannoncer"}/>
            {frontpageStore.selectedPosts.length > 0
                ? <SwiperSlider/>
                : <Skeletons/>
            }
        </MobileContainer>
    );
}

export default observer(SelectedPosts);