import React from 'react';
import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {useMediaContext} from "../../MediaContext";
import PictureRender from "../Shared/Pictures/PictureRender";

const theme = createTheme();

theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};

// Vi vil bruge useStyles til at lave et CSS-in-JS tema
const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        background: `url(${process.env.PUBLIC_URL + '/Images/Backgrounds/forest.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    content: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
}));

const Maintenance
    = () => {
    const classes = useStyles();
    const mediaContext = useMediaContext();

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.root}>
                <Box className={classes.content}>
                    {mediaContext.isUnderSm
                        ? <PictureRender image="logo-round-no-text" size={180}/>
                        : <PictureRender image="logo-round-no-text" size={250}/>
                    }
                    <Typography color="#ebcf82" sx={{textShadow: "1px 1px 2px black", mt: 2, fontSize: "28px"}}>
                        Vores hjemmeside er i øjeblikket under vedligeholdelse
                    </Typography>
                    {/*<Space height="10px"/>*/}
                    <Typography variant="h2" color="#ebcf82" sx={{textShadow: "1px 1px 2px black", fontWeight: "bold"}}>
                        Vi er snart tilbage!
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Maintenance;