import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Space from "../../Basic/Space";
import React, { Fragment } from "react";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function ReceiptAndPayment() {
  return (
    <React.Fragment>
      <Space height="20px" />
      <Box>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography sx={{ m: 2 }} variant="h4">Kvittering og betaling</Typography>
        <Typography sx={{ m: 1 }}>
          1. Lav evt. en købsaftale, hvor sælger bekræfter, at de ting, der står i annoncen, stemmer.
          Aftal på forhånd med sælger, hvilke rettigheder du har, hvis noget går galt på et senere tidspunkt.
        </Typography>
        <Typography sx={{ m: 1 }}>
          2. Brug lidt tid på at få aftalen skrevet ned, tilføj gerne dato for handlen og lad sælger kvittere.
        </Typography>
        <Typography sx={{ m: 1 }}>
          3. Hvis varen er af nyere dato, så husk at spørge, om der findes en bon/kvittering eller endda et
          garantibevis. Så kan du om nødvendigt klage til butikken over mulige fejl og mangler.
        </Typography>
        <Typography sx={{ m: 1 }}>
          4. Hvis der kun er et mobilnummer på sælger, så bed evt. også om sælgers fulde navn samt
          adresse. </Typography>
        <Typography sx={{ m: 1 }}>
          5. Vær ekstra opmærksom, hvis en annonce er skrevet på engelsk eller google translate dansk. Eller
          annonceteksten er skrevet på dansk, men når sælger svarer tilbage på din henvendelse, så er det på
          engelsk. </Typography>
        <Typography sx={{ m: 1 }}>
          6. Er sælger NemID/MitID valideret? Hvis ikke, så kan du bede om at sælger NemID/MitID validere sin
          brugerprofil på Jagtbasen før I afslutter handlen.
        </Typography>
        <Typography sx={{ m: 1 }}>
          7. Vær altid skeptisk, hvis prisen på en vare er uhørt lav. Hvis noget er for godt til at være sandt, er der
          som regel fare på færde.
        </Typography>
        <Typography sx={{ m: 1 }}>
          8. Opgiv aldrig dine kreditkort-oplysninger til fremmede.
        </Typography>
        <Typography sx={{ m: 1 }}>
          9. Mød så vidt muligt sælger og afslut handlen, så du får varen i hånden og betaler kontant ved samme
          lejlighed.
        </Typography>
        <Typography sx={{ m: 1 }}>
          10. Modtager du betaling via MobilePay eller kontooverførsel, så tjek at beløbet er indsat på din bankkonto
          inden du afsender varen
        </Typography>
        <Typography sx={{ m: 1 }}>
          11. Vær på vagt, hvis sælger beder dig overføre penge via Western Union.
        </Typography>
        <Typography sx={{ m: 1 }}>
          12. Undlad at overføre penge til en udenlandsk konto.
        </Typography>
      </Box>
    </React.Fragment>
  );
}