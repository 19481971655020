import * as React from 'react';
import {observer} from "mobx-react";
import GlobalSnackbar from "./GlobalSnackbar";
import Slide from "@mui/material/Slide";

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}
export const SnackbarContext = React.createContext({});

function SnackbarProvider({children}) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [iconType, setIconType] = React.useState("");
    const [transition, setTransition] = React.useState(undefined);

    const handleOpen = (message, icontype) => {
        setIconType(icontype)
        setMessage(message)
        setTransition(() => TransitionLeft);
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return(
        <SnackbarContext.Provider value={{isOpen: open, handleOpen: handleOpen, iconType: iconType, message: message, handleClose: handleClose, transition:transition}}>
            {children}
            <GlobalSnackbar />
        </SnackbarContext.Provider>
    )
}

export default observer(SnackbarProvider)