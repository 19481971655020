import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import Space from "../../Basic/Space";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function SafeTrade() {
  return (
    <React.Fragment>
      <Space height="20px" />
      <Box>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography sx={{ m: 2 }} variant="h4">En sikker handel</Typography>
        <Typography sx={{ m: 1 }}>
          1. Oplever du en billig vare, hvor prisen næsten er for god til at være sand, skal du som udgangspunkt være
          skeptisk.
        </Typography>
        <Typography sx={{ m: 1 }}>
          2. i anbefaler altid at handle med en sælger, som er NemID/MitID valideret. Desuden anbefaler vi at man
          benytter MobilePay som betalingsform samt at man får sælgers fulde navn, telefonnummer og adresse inden man
          afslutter handlen.
        </Typography>
        <Typography sx={{ m: 1 }}>
          3. Vælg en sælger som er NemID/MitiD-valideret. Det er som udgangspunkt en sikkerhed for at sælger er hvem
          han/hun giver sig ud for at være. Vær dog opmærksom - En validering er ikke nødvendigvis en garanti
        </Typography>
        <Typography sx={{ m: 1 }}>
          4. Mødes med sælger personligt, så du kan se varen inden du betaler
        </Typography>
        <Typography sx={{ m: 1 }}>
          5. Modtager du betaling via MobilePay eller kontooverførsel, så tjek at beløbet er indsat på din bankkonto
          inden du afsender varen
        </Typography>
        <Typography sx={{ m: 1 }}>
          6. Bed om en original kvittering på varen, især hvis det er en nyere vare
        </Typography>
        <Typography sx={{ m: 1 }}>
          7. Er profilen nyoprettet, skal du være ekstra på vagt. Bed evt. sælger om at NemID/MitID-validere profilen
          inden handel
        </Typography>
        <Typography sx={{ m: 1 }}>
          8. Oplever du noget mistænkeligt, så kontakt os hurtigst muligt på support@jagtbasen.dk eller klik “Anmeld
          annonce" i toppen af annoncen. Så undersøger vi sagen.
        </Typography>
      </Box>
    </React.Fragment>
  );
}