import { TextField } from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";

const InputText = ({
    register,
    errors,
    id,
    label,
    options,
    maxWidth,
    autoComplete,
    type,
    defaultValue,
    disabled = false,
}) => {
    return <TextField
        sx={{
            "& .MuiInputBase-root": {
                backgroundColor: "white",
            }
        }}
        style={!!maxWidth ? {maxWidth: maxWidth} : {maxWidth: "20rem"}}
        fullWidth
        error={!!errors[id]}
        label={label}
        defaultValue={defaultValue}
        type={!!type ? type : undefined}
        helperText={errors[id] && errors[id].message}
        autoComplete={!!autoComplete ? autoComplete : undefined}
        disabled={disabled}
        {...register(id, options)}
    />

};

export default observer(InputText);
