import React, {useEffect, useState, Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {deepCopy} from "../../../Constants/Constants";
import {dateToString} from "../../../Utilities/dateUtils";
import {MyPostsDesktop} from "./MyPostsDesktop";
import {MyPostsMobile} from "./MyPostsMobile";
import {DesktopView, MobileView} from "../../../MediaContext";
import {Box, Typography, useTheme} from "@mui/material";
import {WhiteOverlay} from "../../Basic/Overlay";
import PostsService from "../../../Services/PostsService";
import {sortPosts} from "../../../Utilities/postUtils";

export const InfoBox = ({children}) => {
    const theme = useTheme();

    return <Box sx={{
        backgroundColor: theme.palette.grey.A200,
        borderRadius: "4px",
        padding: "10px 20px 20px 20px",
        width: "100%",
        height: "fit-content",
        boxSizing: "border-box"
    }}>
        {children}
    </Box>
}

const MessageOverlay = ({children}) => {
    return <Fragment>
        <WhiteOverlay/>
        <Typography sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) ",
            textAlign: "center",
            zIndex: 2,
            fontWeight: "bold",
            padding: "5px 10px",
            borderRadius: "10px",
            boxShadow: "0px 0px 30px 20px white",
            backgroundColor: "white",
            maxWidth: "125px",
        }}>
            {children}
        </Typography>
    </Fragment>
}

export const InReviewOverlay = () => {
    return <MessageOverlay>
        Afventer godkendelse
    </MessageOverlay>
}

export const ExpiredOverlay = () => {
    return <MessageOverlay>
        Udløbet
    </MessageOverlay>
}

export const InactiveOverlay = () => {
    return <MessageOverlay>
        Inaktiv
    </MessageOverlay>
}

const MyPosts = () => {
    const navigator = useNavigate();
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const fetchData = async () => {
        const userPosts = await PostsService.getUserPosts();
        const sortedPosts = sortPosts(userPosts);
        setLoading(false)
        // Prepare each post
        const postList = sortedPosts.map((post) => {
            const deepPost = deepCopy(post);
            deepPost.TextColor = post.Active && !post.Expired && post.Accepted ? "" : "gray";
            deepPost.DaysLeftStr = post.DaysLeft + " dage";
            deepPost.EndDateStr = `${dateToString(new Date(post.EndDate * 1000), true)}`
            return deepPost;
        });
        setPosts(postList);
    }

    useEffect(() => {
        fetchData();
    }, []);



    const activePosts = posts.filter((post) => {
        return post.Active && !post.Expired
    });

    const inactivePosts = posts.filter((post) => {
        return !post.Active || post.Expired
    });

    return <Fragment>
        <MobileView>
            <MyPostsMobile
                activePosts={activePosts}
                inactivePosts={inactivePosts}
                posts={posts}
                loading={loading}
            />
        </MobileView>

        <DesktopView>
            <MyPostsDesktop
                posts={posts}
                loading={loading}
                fetchDataHandle={fetchData}
            />
        </DesktopView>
    </Fragment>
};

export default observer(MyPosts);
