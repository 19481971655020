import { makeAutoObservable, runInAction } from "mobx";
import postsService from "../Services/PostsService";

/**
 * By keeping the frontpage posts here, we do not have to fetch
 * them every time the frontpage is reloaded.
 */
class FrontpageStore {
  isInitialized = false; // False until everything is loaded

  selectedPosts = [];  // Forsideannoncer
  latestPosts = [];  // Sidst oprettet
  mixedPosts = [];  // Andre annoncer

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  async init() {
    const latest = await postsService.getLatestPosts();
    const selected = await postsService.getSelectedPosts();
    const mixed = await postsService.getMixedPosts();

    runInAction(() => {
      this.latestPosts = latest;
      this.selectedPosts = selected;
      this.mixedPosts = mixed;
      this.isInitialized = true;
    });
  }
}

export const frontpageStore = new FrontpageStore();