import React, { Fragment } from "react";
import Space from "../../Basic/Space";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function TradeWithWeapon() {
  return (
    <React.Fragment>
      <Space height="20px" />
      <Container>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography variant="h3">Handel med tilladelsesbaseret aktiver (f.eks. våben):</Typography>
        <Space height="10px" />
        <Typography variant="h6" component="h1" gutterBottom>
          1. Generelt:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at handle med våben på Jagtbasen, accepterer du denne ansvarsfraskrivelse og forpligter dig til at overholde gældende lovgivning og regler for våbenhandel i Danmark, samt alle relevante lokale og nationale love og bestemmelser.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          2. Overholdelse af lovgivning:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Det er brugerens/sælgers ansvar at sikre, at dine våbenhandler overholder alle gældende love, herunder, men ikke begrænset til, våbenlovgivning, eksport- og importkontrolregler, aldersbegrænsninger, licenskrav og kontrollere at vedkommende du handler med, har gyldig billede legitimation samt udstedt jagttegn for indeværende år. Du skal indhente og vedligeholde alle nødvendige licenser, tilladelser og registreringer i forbindelse med dine våbenhandler.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          3. Sikkerhed og ansvar:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du accepterer at handle med våben på en sikker og ansvarlig måde og at tage alle nødvendige forholdsregler for at forhindre, at våben falder i hænderne på uautoriserede personer eller bruges til ulovlige formål. Du påtager dig det fulde ansvar for eventuelle skader, tab eller ansvar, der måtte opstå som følge af dine våbenhandler, herunder, men ikke begrænset til, ulykker, skader på personer eller ejendom og ulovlig brug af våben.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          4. Oplysningspligt:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du skal give nøjagtige og fuldstændige oplysninger om de våben, du handler med, herunder, men ikke begrænset til, våbentype, fabrikant, model, kaliber, serienummer, tilstand og eventuelle ændringer eller tilpasninger. Du skal også informere købere om eventuelle restriktioner, advarsler eller særlige krav i forbindelse med det pågældende våben.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          5. Ansvarsfraskrivelse:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Jagtbasen fraskriver sig ethvert ansvar for tab, skader, krav eller udgifter,der måtte opstå som følge af dine våbenhandler,
          din brug af platformen eller dit forhold til andre brugere.
          Du accepterer at holde platformen skadesløs for eventuelle krav, der måtte opstå som følge af dine våbenhandler, herunder krav fra tredjeparter.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          6. Overvågning og rapportering:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Platformen forbeholder sig retten til at overvåge dine våbenhandler og samarbejde med retshåndhævende myndigheder i tilfælde af mistanke om ulovlig aktivitet. Hvis du støder på en våbenhandler, der overtræder gældende lovgivning eller denne ansvarsfraskrivelse, opfordres du til at rapportere den til vores kundesupport.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at handle med våben på denne platform bekræfter du din forståelse og accept af denne ansvarsfraskrivelse og påtager dig ansvaret for dine våbenhandler og eventuelle konsekvenser, der måtte opstå som følge heraf.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          Sanktioner ved overtrædelse:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          7. Hvis du overtræder denne ansvarsfraskrivelse, gældende lovgivning eller platformens vilkår og betingelser, kan platformen træffe en række foranstaltninger, herunder, men ikke begrænset til, at slette dine annoncer, udstede advarsler, suspendere eller opsige din konto og/eller indberette overtrædelser til myndighederne.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          8. Ændringer i ansvarsfraskrivelsen:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Vi forbeholder os retten til at opdatere og ændre denne ansvarsfraskrivelse fra tid til anden uden forudgående varsel. Det er dit ansvar løbende at gennemgå og overholde den gældende ansvarsfraskrivelse. Din fortsatte brug af platformen efter ændringer i ansvarsfraskrivelsen udgør din accept af sådanne ændringer.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          9. Tvister og lovvalg:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Eventuelle tvister, der måtte opstå som følge af dine våbenhandler på Jagtbasen, skal afgøres i overensstemmelse med gældende lovgivning og eventuelle retssager skal anlægges ved en kompetent domstol. Du accepterer, at lovene i det land, hvor platformen er registreret, finder anvendelse på din brug af platformen og dine våbenhandler, uanset eventuelle lovvalgsregler.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          10. Force majeure:
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Platformen er ikke ansvarlig for manglende overholdelse af sine forpligtelser i henhold til denne ansvarsfraskrivelse,
          hvis sådan manglende overholdelse skyldes årsager, der ligger uden for platformens rimelige kontrol,
          såsom naturkatastrofer, krig, terrorisme, oprør, embargoer, strejker, ændringer i lovgivning eller myndighedsbeslutninger.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Ved at handle med våben på Jagtbasen bekræfter du din forståelse af og accept af denne ansvarsfraskrivelse og påtager dig ansvaret for dine våbenhandler og eventuelle konsekvenser, der måtte opstå som følge heraf. Det er dit ansvar at sikre, at du handler i overensstemmelse med alle gældende love og bestemmelser,
          og du accepterer at holde platformen skadesløs for eventuelle krav, der måtte opstå som følge af dine våbenhandler.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          11.	Ansvar for tredjeparter
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du accepterer, at platformen ikke kan kontrollere eller garantere for handlingerne og adfærden hos tredjeparter, herunder andre brugere og potentielle købere. Platformen er ikke ansvarlig for eventuelle tab, skader eller krav, der måtte opstå som følge af handlinger eller undladelser fra sådanne tredjeparter.
          Du skal udvise passende omhu og skøn i dine interaktioner med andre brugere og tredjeparter.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          12.	Begrænsninger og forbud
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Platformen forbeholder sig retten til at begrænse eller forbyde salg af visse våbentyper eller relaterede genstande i overensstemmelse med gældende lovgivning og platformens politikker.
          Det er dit ansvar at være opmærksom på og overholde sådanne begrænsninger og forbud.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          13.	Oplysning om risici
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Du erkender og accepterer, at handel med våben indebærer visse risici, herunder, men ikke begrænset til, risikoen for fysisk skade, tab eller tyveri af våben og potentielle juridiske konsekvenser.
          Du accepterer at påtage dig disse risici i forbindelse med dine våbenhandler og fraskriver Jagtbasen ethvert ansvar for sådanne risici.
        </Typography>
        <Typography variant="h6" component="h1" gutterBottom>
          14.	Uddannelse og træning
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Det anbefales, at du og eventuelle købere af dine våben gennemgår passende uddannelse og træning i sikker håndtering, opbevaring og brug af våben.
          Platformen påtager sig intet ansvar for manglende uddannelse eller træning hos dig eller tredjeparter.
          Ved at handle med våben på denne platform bekræfter du din forståelse af og accept af denne ansvarsfraskrivelse og påtager dig ansvaret for dine våbenhandler og eventuelle konsekvenser,
          der måtte opstå som følge heraf. Det er dit ansvar at sikre, at du handler i overensstemmelse med alle gældende love og bestemmelser,
          og du accepterer at holde platformen skadesløs for eventuelle krav, der måtte opstå som følge af dine våbenhandler.
        </Typography>
        <Space height="15px"/>
      </Container>
    </React.Fragment>
  )
}