import {InputAdornment, TextField} from "@mui/material";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {formatNumber} from "../../Utilities/postUtils";

const InputPrice = ({
    register,
    errors,
    id,
    label,
    options,
    disabled = false,
    defaultValue = "",
}) => {
    const {onChange, onBlur, name, ref} = register(id, options);

    const [price, setPrice] = useState(formatNumber({ value: defaultValue, }));

    return (
        <TextField
            sx={{
                "& .MuiInputBase-root": {
                    backgroundColor: "white",
                }
            }}
            style={{maxWidth: "10rem"}}
            fullWidth
            disabled={disabled}
            value={price}
            error={errors[id] ? true : false}
            label={label}
            helperText={errors[id] && errors[id].message}
            InputProps={{
                endAdornment: <InputAdornment position="end">,00 Kr. </InputAdornment>,
                inputProps: {
                    style: {textAlign: "right"}
                }
            }}
            onChange={(event) => {
                event.target.value = formatNumber({value: event.target.value, maxLength: 8});
                setPrice(event.target.value);
                onChange(event);
            }} // assign onChange event
            onBlur={onBlur} // assign onBlur event
            name={name} // assign name prop
            ref={ref} // assign ref prop
        />
    );
};

export default observer(InputPrice);
