import React, {Fragment, useEffect} from "react";
import { observer } from "mobx-react";
import {DesktopView, MobileView} from "../../MediaContext";
import {MySiteDesktop} from "./MySiteDesktop";
import {MySiteMobile} from "./MySiteMobile";
import {accountStore} from "../../Stores/AccountStore";
import MuiAlert from "@mui/material/Alert";
import {Link, Snackbar, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import { Link as RouterLink } from 'react-router-dom';
import Typography from "@mui/material/Typography";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff'
        },
        info: {
            main: '#5e1022'
        },
        warning: {
            main: '#ebcf82',
        }
    }
});
function MySite() {
    const [open, setOpen] = React.useState(false);
    const checkHuntingTypesIsChosen = () => {
        if (accountStore.user.huntingChoices.length === 0) {
            setOpen(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    useEffect(() => {
        checkHuntingTypesIsChosen();
    }, []);
    return <Fragment>
        <MobileView>
            <MySiteMobile/>
        </MobileView>
        <DesktopView>
            <MySiteDesktop/>
        </DesktopView>
        <ThemeProvider theme={theme}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: "center"}}>
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                <Link component={RouterLink} to="/signup?signUpStatus=huntingform&new=false">
                    <Typography>Vælg venligst din foretrukne jagttype.</Typography>
                </Link>
            </Alert>
        </Snackbar>
        </ThemeProvider>
    </Fragment>
}

export default observer(MySite);