import React, {useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/grid";
import "./styles.css";

// import required modules
import { FreeMode, Navigation, Thumbs, Pagination, Zoom, Grid } from "swiper";
import { observer } from "mobx-react";
import { Grid as MuiGrid, Link } from "@mui/material";
import { ImagesViewer } from "../Shared/Shared";

const DesktopGallery = ({ gallery }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    
    const handleThumbSwiper = (swiper) => {
        swiper.allowTouchMove = false; // disable touch events
        swiper.allowSlidePrev = false; // disable slide prev
        swiper.allowSlideNext = false; // disable slide next
    };

    const handleThumbClick = (swiper, index) => {
        swiper.slideTo(index); // update main image swiper's active index
        console.log(thumbsSwiper)
    };

    const handleThumbnailClick = (imageIndex) => {
        setSelectedImageIndex(imageIndex);
    };
    const handleDialogClose = () => {
        setSelectedImageIndex(null);
    };

    return (
      <MuiGrid container spacing={0.5}>
          <MuiGrid item xs={8}>
              <Swiper style={{
                  "--swiper-navigation-color": "#000000",
                  "--swiper-pagination-color": "#000000"
              }}
                      zoom={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs, Zoom]}
                      className="mySwiper2">
                  {gallery.map((image,index) =>
                    <SwiperSlide key={`swiperslide-${image.ImageId}`}>
                        <div className="swiper-zoom-container">
                            <img src={image.Url} alt={`swiperslide-${image.ImageId}`}
                                 onClick={() => handleThumbnailClick(index)}
                                 style={{ cursor: 'pointer', objectFit: "contain" }}
                            />
                        </div>
                    </SwiperSlide>
                  )}
              </Swiper>
          </MuiGrid>
          <MuiGrid item xs={4}>
              {/*  {(swiper)=>{ handleThumbSwiper(swiper); setThumbsSwiper(swiper)}}*/}
              <Swiper
                onSwiper={setThumbsSwiper}
                slidesPerView={gallery.length > 4 ? 2 : 1}
                grid={{
                    rows: 4
                }}
                modules={[Grid, Navigation, Thumbs]}
                className="mySwiperr"
                style={{ height: 300 }}
              >
                  {gallery.map((image,index) =>
                    <SwiperSlide key={`swiperslide-${image.ImageId}`} style={{ padding: 2 }}>
                        <img src={image.Url} alt={`swiperslide-${image.ImageId}`}
                             onClick={() => handleThumbnailClick(index)}
                             style={{ cursor: 'pointer', objectFit: "cover"}}/>
                    </SwiperSlide>
                  )}
              </Swiper>
          </MuiGrid>
          {/* Display the dialog box */}
          {selectedImageIndex !== null && (
            <ImagesViewer imageUrls={gallery} imageIndex={selectedImageIndex} onClose={handleDialogClose} />
          )}
      </MuiGrid>
    );
};

export default observer(DesktopGallery);