import SignUp from "./SignUp";
import * as React from 'react';
import {observer} from "mobx-react-lite";
import SecondFlow from "./SecondFlow";
import {useSearchParams} from "react-router-dom";
import {Fragment} from "react";
import ThirdFlow from "./ThirdFlow";

function SignUpFlow() {
    const [searchParams] = useSearchParams();
    const signUpState = searchParams.get("signUpStatus") || "new";

    return (
        <Fragment>
            {signUpState === "new" && <SignUp signUpState={signUpState} />}
            {signUpState === "verified" && <SecondFlow signUpState={signUpState} />}
            {signUpState === "huntingform" && <ThirdFlow signUpState={signUpState} />}
        </Fragment>
    )
}

export default observer(SignUpFlow)