import {makeAutoObservable, runInAction} from "mobx";
import AccountService from "../Services/AccountService";
import {reportException} from "../Utilities/sentry";

class AccountStore {
    cognitoUser = {};
    userInfo = {};
    user = {};
    logged = false;
    error = "";
    state = "loading";

    constructor() {
        makeAutoObservable(this);
    }

    async getCurrentUser() {
        try {
            const tempCognitoUser = await AccountService.getSession();
            const tempUserInfo = await AccountService.getUserInfo();
            const tempUser = await AccountService.getUserAtJagtbasenDB();
            runInAction(async () => {
                this.cognitoUser = tempCognitoUser;
                this.userInfo = tempUserInfo;
                this.user = tempUser;
                if (!!this.user) {
                    this.logged = true;
                }
                this.state = 'success';
            });
        } catch (e) {
            runInAction(async () => {
                this.logged = false;
                if (e === "No current user") {
                    this.state = 'noCurrentUser';
                } else {
                    this.state = 'error';
                }
            });
        }
    }

    async loginCognito(username, password) {
        try {
            const tempCognitoUser = await AccountService.loginCognito(username, password);
            const tempUserInfo = await AccountService.getUserInfo();
            runInAction(() => {
                this.cognitoUser = tempCognitoUser;
                this.userInfo = tempUserInfo;
            })
        } catch (error) {
            runInAction(() => {
                this.logged = false;
                this.error = error.code;
                this.state = "error"
            });
            throw error;
        }
    }

    async logOutCognito() {
        try {
            await AccountService.logOutCognito();
            runInAction(() => {
                this.logged = false;
            });
        } catch (e) {
            reportException(e);
            console.error(e);
        }
    }

    async createUserOnJagtbasenDB(user) {
        try {
            const tempuser = await AccountService.createUserOnJagtbasenDB(user);
            runInAction(() => {
                this.user = tempuser;
            })
            return tempuser;
        } catch (e) {
            reportException(e, {notify: true, options: {
                user: JSON.stringify(user)
            }});
            throw e;
        }
    }

    async checkUserAtJagtbasenDB(id) {
        try {
            const result = await AccountService.checkUserAtJagtbasenDB(id);
            return result;
        } catch (e) {
            return false;
        }
    }

    async getUserAtJagtbasen() {
        try {
            const result = await AccountService.getUserAtJagtbasenDB();
            if (!!result) {
                runInAction(() => {
                    this.user = result;
                    this.logged = true;
                    this.state = "success"
                })
            }
            return result;
        } catch (e) {
            return false;
        }
    }

    async updateUserOnJagtbasenDB(user) {
        try {
            const tempuser = await AccountService.updateUserOnJagtbasenDB(user);
            runInAction(() => {
                this.user = tempuser;
            })
            return tempuser;
        } catch (e) {
            reportException(e);
            throw e;
        }
    }

    async updateUserEmailOnJagtbasenDB(id, email) {
        try {
            const result = await AccountService.updateUserEmailOnJagtbasenDB(id, email);
            return result;
        } catch (e) {
            reportException(e);
            throw e;
        }
    }

    async forgotPassword(email) {
        try {
            const result = await AccountService.forgotPassword(email)
            console.log(result);
            return result;
        } catch (e) {
            reportException(e);
            console.log(e);
            throw e;
        }
    }

    async resetPassword(username, code, password) {
        try {
            const result = await AccountService.resetPassword(username, code, password);
            console.log(result);
            return result;
        } catch (e) {
            reportException(e);
            console.log(e);
            throw e;
        }
    }

    async uploadUserHuntingTypes(huntingTypes){
        try {
            await AccountService.uploadUserHuntingTypes(huntingTypes);
            await this.getCurrentUser();
        } catch (e) {
            reportException(e);
            console.log(e);
            throw e;
        }
    }
}

export const accountStore = new AccountStore();
