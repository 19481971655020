import accountService from "./AccountService";
import {reportException, reportMessage} from "../Utilities/sentry";

class ReportService {
  async getReportCategories(){
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/Reports/categories",
        requestOptions
      );
      return await response.json();
    } catch (e) {
      reportException(e);
      console.log(e);
    }
  }

  async createReport(report){
    const jwt = await accountService.getCognitoJwt()
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`,
        },
        body: JSON.stringify(report),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/Reports",
        requestOptions
      );
      if (response.ok){
        const jsonRes = await response.json();
        return jsonRes.message
      } else {
        reportMessage(
            "Received status != 200 during create report", {options : {
                status: response.status,
                message: response.statusText,
                response: response.clone().text(),
                report: JSON.stringify(report)
              }}
        );
      }
    } catch (e) {
      reportException(e);
    }
  }
}
export default new ReportService();
