import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Container, Divider} from "@mui/material";
import Space from "../../Basic/Space";
import React, { Fragment } from "react";
import PictureRender from "../../Shared/Pictures/PictureRender";

export function Terms() {
  return(
    <React.Fragment>
      <Space height="20px" />
    <Container>
      <Box>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography sx={{ mt: 2, mb: 2 }} variant="h4" color="jagtred-main">Jagtbasen's Brugervilkår</Typography>
        <Divider />
        <Typography sx={{ mt: 1, mb: 1 }}>
          Opdateret: 01-05-2023
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Velkommen til jagtbasen.dk!
        </Typography>
        <Divider />
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          1. Introduktion
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Velkommen til Jagtbasen. Jagtbasen er en online købs- og slagsplatform der faciliterer handel mellem private jægere i Danmark. Disse brugervilkår accepterer du når du besøger www.jagtbasen.dk samt ved at oprette en konto og benytte platformen. Disse ydelser stilles til rådighed af Jagtbasen Aps, Ny Østergade 9, 1 sal. 3600, Frederikssund. Cvr-nr. 43924702. Disse vilkår træder i kraft 01.05.2023 og vil løbende blive opdateret i forbindelse med ændringer i lovgivningen.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          2.	Registrering, brugerkonto og anvendelse af Jagtbasen
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          For at få adgang til og bruge platformen www.jagtbasen.dk skal du oprette en konto ved at indtaste dine personlige oplysninger og vælge et brugernavn og en adgangskode. Du er ansvarlig for at opretholde fortroligheden af din adgangskode og for alle aktiviteter, der finder sted på din konto.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          3.	Personlige oplysninger og databeskyttelse (GDPR)
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Ved at bruge platformen Jagtbasen accepterer du vores indsamling, brug og deling af dine personlige oplysninger i overensstemmelse med vores databeskyttelsespolitik og gældende lovgivning.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          4.	Brugeradfærd
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Du accepterer at bruge jagtbasen.dk på en lovlig, etisk og ansvarlig måde. Du må ikke:
          ●	Overtræde gældende lovgivning eller disse vilkår. Her under købeloven, forbrugeraftaler, aftaleloven og markedsføringsloven.
          ●	Oprette falske eller vildledende annoncer. Sælge forfalskede varer eller på anden måde krænke ophavs-, varemærke-, eller andre immaterielle rettigheder.
          ●	Krænke tredjeparts intellektuelle ejendomsrettigheder, privatlivets fred, jagtbasens regler for annoncering, eller andre rettigheder.
          ●	Fremme ulovlige, voldelige, diskriminerende, stødende eller upassende aktiviteter.
          ●	Manipulere eller forsøge at manipulere priser, ratings eller andre brugerdata på platformen.
          ●	Forsøge at omgå eller på anden måde underminere platformens sikkerhedsforanstaltninger. belaste vores online infrastruktur eller forstyrre driften af Jagtbasen. Benytte virus eller andre teknologier, der kan skade jagtbasen eller vores brugerne og deres interesser eller ejendom.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          5.	Gebyrer, betalinger og fortrydelsesret
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Platformen kan opkræve gebyrer for visse tjenester, såsom annonce og annonceringsringsgebyrer, transaktionsgebyrer eller abonnementsgebyrer. Du accepterer at betale disse gebyrer i henhold til de gældende betalingsvilkår. Fortrydelsesretten gælder under ingen omstændigheder aftaler om levering af digitalt indhold, som ikke leveres på fysisk hvis, hvis udførelsen er påbegyndt med forbrugerens forudgående udtrykkelige samtykke og anerkendelse af, at fortrydelsesretten dermed bortfalder. Medmindre andet aftales, accepterer du at dit Indhold vil blive indrykket/leveret af Jagtbasen inden udløbet af den lovfæstede fortrydelsesperiode. Du accepterer også, at en eventuel fortrydelsesret bortfalder, så snart Jagtbasen har opfyldt aftalen om indrykning af det pågældende Indhold.

          Hvis fortrydelsesretten gælder for den tjenesteydelse Jagtbasen leverer, accepterer du, at Jagtbasen er berettiget til at kræve betaling for den del af tjenesteydelsen, der allerede er leveret, hvis du gør brug af din fortrydelsesret. Er der tale om en annoncerings- eller abonnementsydelse vil denne forholdsmæssige tilbagebetaling blive beregnet ud fra antallet af resterende dage i din annoncerings- eller abonnementsperiode på det tidspunkt, hvor du gør brug af din fortrydelsesret, set i forhold til den aftalte annoncerings- eller abonnementsperiode.

          Hvis du gør brug af din fortrydelsesret, vil Jagtbasen refundere dig det beløb du har betalt for tjenesteydelsen fratrukket et beløb, der står i rimeligt forhold til den del af tjenesteydelsen, der allerede er leveret, jf. ovenfor, senest 14 dage efter modtagelse af din fortrydelsesanmodning.

          For så vidt angår indrykning af betalingsannoncer på Jagtbasen online platforme forpligter Jagtbasen sig til at vise de pågældende annoncer indtil de annoncerede varer er solgt eller ikke længere er i din besiddelse, dog aldrig længere end den aftalte annonceringsperiode. Du accepterer at Jagtbasen tjenesteydelse herefter anses for fuldt udført og at en eventuel fortrydelsesret derfor bortfalder.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          6.	MitID-validering
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Jagtbasen tilbyder alle brugere muligheden for at Mit ID-validere deres brugerprofil, og ved at gøre brug af denne mulighed bekræfter du din identitet. Det tager kun få minutter at Mit ID-validere din brugerprofil, og du behøver kun at gøre det én gang.

          Jagtbasen kan når som helst kræve, at du Mit ID-validerer din brugerprofil (f.eks. når du uploader tilladelsesbaseret genstande så som våben eller på baggrund af en konkret forespørgsel fra kunde support), og forbeholder sig retten til at deaktivere din brugerprofil og eventuelt Indhold indtil, Mit ID-valideringen er gennemført.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          7.	Annoncer og transaktioner
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Du er ansvarlig for indholdet i dine annoncer og for at sikre, at de er nøjagtige, lovlige og ikke vildledende. Platformen er ikke ansvarlig for eventuelle tvister, der måtte opstå mellem købere og sælgere. Alle transaktioner og aftaler mellem brugere er deres eget ansvar.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          8.	Ansvarsbegrænsning
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Platformen jagtbasen.dk leveres "som den er" og "som tilgængelig", uden nogen garantier, hverken udtrykkelige eller underforståede. Jagtbasen fraskriver os ethvert ansvar for tab, skader, krav eller udgifter, der måtte opstå som følge af din brug af platformen jagtbasen.dk eller dit forhold til andre brugere. Du indestår som bruger for, at det Indhold du opretter på Jagtbasen tjenester, er i overensstemmelse med Jagtbasen regler for annoncering, ikke overtræder nogen love (herunder den danske Markedsføringslov, våbenloven mm.) og ikke krænker tredjemands rettigheder.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          9.	Ophævelse og misbrug
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          jagtbasen.dk forbeholder os retten til at opsige eller suspendere din konto og adgang til platformen uden varsel, hvis du overtræder disse vilkår, gældende lovgivning eller på anden måde udgør en risiko for platformen eller andre brugere. Benyt venligst knappen "Anmeld annonce" på de enkelte annoncer til at fortælle os om eventuelle problemer eller stødende indhold, så vi sammen kan sikre annoncekvaliteten på Jagtbasen. Vi kan beslutte os for at benytte nogle af disse foranstaltninger, fjerne lagret indhold eller afskære en bruger fra vores side, men vi påtager os ikke noget ansvar for overvågning af vores side, ydelser eller for uautoriseret eller ulovligt indhold eller brugernes benyttelse af vores hjemmeside eller ydelser.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          10.	Jagtbasen`s ansvar
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Jagtbasen er ikke engageret i transaktionen mellem køber og sælger og fungerer ikke selv som sælger eller leverandør af produkter eller tjenester, der annonceres for salg på vores platform. Vi påtager os intet ansvar for produkter eller tjenester, som du køber ved at benytte vores platform. Du accepterer ikke at gøre os ansvarlige for handlinger eller annoncer fra andre brugere. Størstedelen af vores indhold stammer fra andre private og erhvervs brugere, så vi kan ikke garantere nøjagtigheden af indholdet eller brugerkommunikation, kvaliteten, sikkerheden eller lovligheden af det, der tilbydes. Vi kan heller ikke garantere uafbrudt eller sikker adgang til vores tjenester eller jagtbasen.dk. Derfor frasiger vi os udtrykkeligt - i det omfang loven tillader det – alle garantier, erklæringer og vilkår, udtrykkelige eller underforståede, inklusive kvalitet, salgbarhed, handelsmæssig kvalitet, holdbarhed, egnethed til et bestemt formål og det, der følger af lovgivningen. Vi er ikke ansvarlige for tab, hvad enten det drejer sig om penge (inklusive fortjeneste), goodwill, omdømme eller andre særlige, indirekte eller følgeskader, der opstår som følge af din brug af vores hjemmeside og tjenester, selvom vi er underrettet herom, eller vi med rimelighed kunne forudse muligheden for sådanne skader.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          11.	Ændringer i vilkår
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Vi forbeholder os retten til at opdatere og ændre disse vilkår fra tid til anden uden forudgående varsel. Det er dit ansvar løbende at gennemgå og overholde de gældende vilkår. Din fortsatte brug af platformen jagtbasen.dk efter ændringer i vilkårene udgør din accept af sådanne ændringer.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          12.	Ophavsret og intellektuel ejendomsret
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Alt indhold og materiale på platformen Jagtbasen, herunder tekst, grafik, logoer, billeder, software og andre elementer, er beskyttet af ophavsret og andre intellektuelle ejendomsrettigheder og tilhører os eller vores licensgivere. Du må ikke kopiere, reproducere, distribuere, ændre eller på anden måde udnytte sådant indhold uden vores udtrykkelige skriftlige samtykke.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          13.	Tredjeparts links og indhold
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Platformen kan indeholde links til tredjeparts websteder eller indhold, som vi ikke kontrollerer eller støtter. Vi er ikke ansvarlige for indholdet eller praksis på sådanne tredjeparts websteder, og din brug af dem sker på egen risiko.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          14.	Klager og tvister
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Hvis du har en klage eller en tvist i forbindelse med Jagtbasen, bedes du kontakte vores kundeservice for at søge en løsning. Hvis vi ikke kan løse din tvist tilfredsstillende, kan du vælge at søge retsmidler gennem juridiske kanaler.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          15.	Gældende love og generelle betingelser
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Disse vilkår er underlagt lovgivningen i Danmark, hvor vores platform Jagtbasen Aps er registreret, og enhver tvist, der opstår i forbindelse med disse vilkår, skal afgøres ved de relevante domstole.
          Ved at fortsætte med at bruge platformen jagtbasen.dk accepterer du de vilkår og betingelser, der er angivet i disse brugervilkår, og forstår, at du påtager dig det fulde ansvar for din brug af platformen og for eventuelle transaktioner eller interaktioner med andre brugere.
          Disse betingelser og de øvrige relaterede vilkår, som er angivet på Jagtbasen, danner hele aftalen mellem Jagtbasen og dig og erstatter alle tidligere aftaler. Særlige vilkår, der vedrører et bestemt produkt eller en bestemt tjeneste fra Jagtbasen, vil have forrang over disse generelle betingelser i tilfælde af uoverensstemmelse.
          Denne aftale er underlagt dansk lovgivning. Du accepterer, at aftalen ikke udelukkende er underlagt værnetinget ved de danske domstole. Hvis Jagtbasen ikke håndhæver en bestemt bestemmelse, betyder det ikke, at vi har givet afkald på vores ret til at gøre det senere. Hvis en domstol erklærer en af disse betingelser for ugyldige, vil de øvrige betingelser fortsat være gældende. Vi kan overdrage denne aftale efter vores eget valg i overensstemmelse med de nedenfor angivne bestemmelser om underretning herom.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          Generelt
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Disse vilkår og de andre relaterede vilkår, der er angivet på jagtbasen.dk, udgør hele aftalen mellem Jagtbasen og dig og erstatter alle tidligere aftaler. Andre vilkår der vedrører et specifikt produkt eller en specifik ydelse udbudt af Jagtbasen har, i tilfælde af uoverensstemmelser, forrang i forhold til disse generelle vilkår.
          Denne aftale reguleres af lovgivningen i Danmark. Du er indforstået med, at aftalen ikke er underlagt eksklusivt værneting ved domstolene i Danmark. Hvis Jagtbasen ikke håndhæver nogen særlig bestemmelse, betyder dette ikke, at vi har givet afkald på vores ret til at gøre dette på et senere tidspunkt. Hvis en domstol finder nogen af disse betingelser ugyldige, vil de resterende betingelser stadig gælde. Vi kan overdrage denne aftale efter eget skøn i overensstemmelse med nedenstående bestemmelser vedrørende underretning herom. Dine meddelelser skal sendes til support@jagtbasen.dk
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          Ændringer eller Rettelser
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Vi kan opdatere disse vilkår til enhver tid, så opdateringerne træder i kraft næste gang, du opretter Indhold på jagtbasen's tjenester eller efter 30 dage, afhængig af hvad der sker først. Ingen anden ændring af denne aftale vil være gyldig, medmindre den fremsættes skriftligt. Send spørgsmål, kommentarer eller klager til Kundesupport.
        </Typography>
      </Box>
      <Space height="15px"/>
    </Container>
    </React.Fragment>
  )
}