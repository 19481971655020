import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Zoom } from "swiper";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    zIndex: 9999,
    backgroundColor:"#5e1022"
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '500px',
  },
}));

const MobileFullSizeImages = ({fullImage, setFullImage, gallery}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClose = () => {
    setFullImage({index: fullImage.index, isOpen: false})
    navigate(-1)
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={fullImage.isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{ sx: { position: "relative" } }}
      >
        <Swiper
          style={{
            "--swiper-navigation-color": "#ffffff",
            "--swiper-pagination-color": "#ffffff",
            height: "100%",
            zIndex: 1
          }}
          initialSlide={fullImage.index}
          zoom={true}
          spaceBetween={10}
          navigation={true}
          pagination={true}
          modules={[FreeMode, Navigation, Pagination, Zoom]}
          className="mySwiper2">
          {gallery.map((image) =>
            <SwiperSlide key={`swiperslide-${image.ImageId}`}>
              <div className="swiper-zoom-container">
                  <img alt={"større billede"} src={image.Url} />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className={classes.overlay}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.closeButton}
          >
            <CloseIcon sx={{color:'#ffffff'}}/>
          </IconButton>
        </div>
      </Dialog>
    </div>
  );
}

export default observer(MobileFullSizeImages)