import React, {forwardRef, useImperativeHandle, useState} from "react";
import SyncIcon from "@mui/icons-material/Sync";
import css from "./SpinIcon.module.css"

export const SpinIcon = forwardRef((props, ref) => {
    const [isSpinning, setIsSpinning] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            spin: spin
        }
    });

    const spin = () => {
        if (isSpinning) return;

        setIsSpinning(true)
        setTimeout(() => setIsSpinning(false), 1000);   // call method in 300 milliseconds
    }

    return <SyncIcon
        className={isSpinning ? css.rotate : ""}
    />

})