import { Box, Fab, IconButton, ListItem, Stack, TextField, useTheme } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import SendIcon from '@material-ui/icons/Send';
import {observer} from "mobx-react";
import {makeStyles} from "@mui/styles"
import {accountStore} from "../../Stores/AccountStore";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import Grid from "@mui/material/Grid";
import {chatStore} from "../../Stores/ChatStore";
import FaceIcon from "@mui/icons-material/Face";
import {convertDateTimeToChatTime} from "./common";
import {SpinIcon} from "../Basic/SpinIcon";
import Row from "../Basic/Row";
import Space from "../Basic/Space";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%'
    },
    boxShadow : {
        textShadow : 5,
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        border: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '250px',
        overflowY: 'auto',
        display:'flex',
        flexDirection: 'column-reverse'
    },
    logoColor :{
        color : '#5e1022'
    },
    gradientcolor :{
        mainGradient: "linear-gradient(to right, tomato, cyan)"
    }
});

const ChatBox = ({post}) => {
    const classes = useStyles();
    const [textMessage, setTextMessage] = React.useState("");
    const spinRef = React.useRef();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchMessages(){
            await chatStore.updateInbox();
        }
        setLoading(true);
        fetchMessages().then().finally(setLoading(false))
    }, []);

    const handleChange = (event) => {
        setTextMessage(event.target.value);
    };
    async function sendMessage() {
        await chatStore.sendMessage(textMessage, currentUserName())
        setTextMessage("");
    }
    const currentUserName = () => accountStore.userInfo.attributes.sub;
    const messageItem = (message) => {
        const time = convertDateTimeToChatTime(message.Time)

        let senderIsCurrentUser = message.SenderUserId === currentUserName()
        let pos =  senderIsCurrentUser? 'row-reverse' : 'row'
        let padding = senderIsCurrentUser ? {pl:1} : {pr:1}
        let color = 'rgba(112,112,112,0.09)'

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: pos,
                    alignItems: 'center',
                    mt: 1,
                    borderRadius: "4px",
                    width: "100%"
                }}
            >
                {!senderIsCurrentUser?
                    <Stack sx={padding} style={{textAlign: "center"}}>
                        <FaceIcon fontSize={"large"} htmlColor={"#5e1022"}/>
                        <Typography sx={{fontSize:10, color:"#5e1022"}} fontWeight={"bold"}>{getChatPerson()}</Typography>
                    </Stack>
                    : false}
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: "4px",
                        bgcolor: color,
                        maxWidth:200
                    }}
                >
                    <Typography
                    >{message.MessageText}</Typography>
                </Box>
                <Typography sx={{pr:1,pl:1}} color={"gray"}>{" · "}</Typography>
                <Typography sx={{
                    display: 'flex',
                    textAlign: senderIsCurrentUser ? "right" : "left",
                    fontSize: 'small',
                    color: 'gray'
                }}>{time}</Typography>
            </Box>
        );
    }

    function getUserName() {
        return isOwnThePost? chatStore.inbox.User.Firstname.concat(" "+chatStore.inbox.User.Lastname)
            : post.User.Firstname.concat(" "+post.User.Lastname)
    }
    function getPerson(){
        return isOwnThePost? "køber" : "sælger"
    }

    function getChatPerson() {
        return isOwnThePost? "Køber" : "Sælger"
    }

    const isOwnThePost = Boolean(currentUserName() === post.UserId);
    function getBeskederTekst() {
        return isOwnThePost ? "Dine private samtale med "+ getPerson() : "Dine private samtale med "+getPerson()
    }

    const handleUpdate = async () => {
        spinRef.current.spin();
        setLoading(true);
        await chatStore.updateInbox()
        setLoading(false);
    }

    return (
        <div key={"Chatboxshown"}>
            <Stack sx={{pt: 1, pb: 2}}>
                <Typography color={"rgba(0, 0, 0, 0.6)"} fontSize={"smaller"}>{getBeskederTekst()}</Typography>
            </Stack>
            <Box sx={{maxWidth: 700, border: 1, borderRadius: "4px", borderColor: 'grey.500'}}>
                <Grid container className={classes.chatSection}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                p: 1,
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                            }}>
                            <Box
                                display="flex"
                                flexDirection={"row"}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {chatStore.inboxes.length > 1
                                    ? <IconButton size="small" onClick = { async () => {
                                            await chatStore.resetInbox(post)
                                        }}
                                    >
                                        <ArrowBackIosNewSharpIcon/>
                                    </IconButton>
                                    : <div/>
                                }
                                <Typography >
                                    <span style={{fontWeight: "bold"}}>
                                     {getUserName()}
                                    </span>
                                    <span style={{color: theme.palette.grey[500]}}>
                                        {isOwnThePost
                                            ? " (køber)"
                                            : " (sælger)"
                                        }
                                    </span>
                                </Typography>
                                <IconButton size="small" onClick={handleUpdate}>
                                    <SpinIcon ref={spinRef}/>
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <List className={classes.messageArea}>
                            <Space height="5px"/>
                            {!loading && (
                                chatStore.inbox.Messages.map((value, index) => {
                                    return (
                                        <ListItem sx={{pt:0,pb:0}} key={index}>
                                            {messageItem(value)}
                                        </ListItem>
                                    );
                                })
                            )}
                            <Space height="5px"/>
                        </List>
                        <Divider/>
                        {!post.Active && <Row sx={{
                            padding: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center"
                        }}>
                            <Typography>Beskeder slået fra, da annoncen ikke er aktiv.</Typography>
                        </Row>}
                        {post.Active &&
                            <Row sx={{
                                padding: "15px",
                                alignItems: "center"
                            }}>
                                {/* Input field */}
                                <TextField
                                    id="outlined-basic-email"
                                    label="Skriv en besked"
                                    onChange={handleChange}
                                    value={textMessage}
                                    disabled={!post.Active}
                                    fullWidth
                                />

                                <Space width="15px"/>

                                {/* Send icon */}
                                <Box>
                                    <Fab
                                        size="medium"
                                        sx={{
                                            background: textMessage === "" ? '#cccccc' : 'linear-gradient(to right, #5e1022 60%, #76142a)',
                                            cursor: textMessage === "" ? 'default' : 'pointer'
                                        }}
                                        aria-label="add"
                                        disabled={textMessage === "" || Boolean(!post.Active)}
                                        onClick={sendMessage}
                                    >
                                        <SendIcon htmlColor="white" />
                                    </Fab>
                                </Box>
                            </Row>
                        }
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}


export default observer(ChatBox)