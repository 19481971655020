import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import {MediaContextProvider} from "./MediaContext";
import {
    ConfirmationDialogProvider
} from "./Components/Dialogs/ConfirmationDialog";
import SnackbarProvider from "./Components/Snackbar/SnackbarProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import {InfoDialogProvider} from "./Components/Dialogs/InfoDialog";
import {BrowserRouter as Router} from "react-router-dom";
import {SearchContextProvider} from "./Contexts/SearchContext";
import * as Sentry from "@sentry/react";
import { ConstantsContextProvider } from "./Contexts/ConstantsContext";
import { log } from "./Utilities/global";

window.log = log;

Sentry.init({
    dsn: "https://47ef9daadb244be7a033183e8b3962a3@o4505260910706688.ingest.sentry.io/4505260913524736",
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/testapi\.jagtbasen\.com/, /^https:\/\/api\.jagtbasen\.com/],
        }),
        new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture % of the transactions

    // Session Replay
    replaysSessionSampleRate: 0.0, // The sample rate for replays that begin recording immediately
    replaysOnErrorSampleRate: 1.0, // The sample rate for replays that are recorded when an error happens
});

const theme = createTheme({
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            }
        }
    },
    palette: {
        primary: {
            main: '#5e1022',
            light: '#642e3b',
            dark: '#410b17',
        },
        jagtred: {
            main: '#5e1022',
            contrastText: '#fff',
            light: '#642e3b',
            dark: '#410b17',
        },
        jagtyellow: {
            main: '#ebcf82',
            contrastText: '#000',
        },
        // Used for information boxes
        information: {
            green: {
                background: "#DBEED3",
                text: "#447F43",
            },
            yellow: {
                background: "#faffe1",
                text: "#7f6136",
                border: "#f3e189",
            },
        }
    },
    Link: {
        color: "#000",
        "&:hover": {
            color: "#000000",
            textDecoration: "underline #000000",
        },
    },
    typography: {
        fontFamily: "Poppins",
        topRightLinks: {
            fontFamily: "sans",
            fontSize: 16,
        },
        topRightButtons: {
            fontSize: "60%",
        },
    },
});

// Extensions
String.prototype.toUpperFirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

window.addEventListener('beforeinstallprompt', (e) => {
    // Stash the event so it can be triggered later.
    console.log("Got beforeinstallprompt");
    window.deferredPrompt = e;
});

ReactDOM.render(
    // To prevent your CSS to be loaded after Material UI's
    // styles thereby overwriting our styles
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <ConstantsContextProvider>
                <MediaContextProvider>
                    <ConfirmationDialogProvider>
                        <InfoDialogProvider>
                            <SnackbarProvider>
                                <Router >
                                    {/* Needs to be within the router */}
                                    <SearchContextProvider>
                                        <App />
                                    </SearchContextProvider>
                                </Router>
                            </SnackbarProvider>
                        </InfoDialogProvider>
                    </ConfirmationDialogProvider>
                </MediaContextProvider>
            </ConstantsContextProvider>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
