
import {accountStore} from "../Stores/AccountStore";
import * as Sentry from "@sentry/react";

/**
 * @param error - The exception to report
 * @param options - An object containing key-value pairs to be attached as extra data
 * {notify: true}, this will send a notification to the users part of the Sentry Jagtbasen team.
 */
export const reportException = (error, {notify = false, options = {}} = {}) => {
    Sentry.withScope(scope => {
        scope.setTags({
            user: accountStore.userInfo.username ?? "-",
            notify: notify
        });

        for (let key in options) {
            scope.setExtra(key, options[key])
        }

        Sentry.captureException(error);
    });
}

export const reportMessage = (message, {notify = false, options = {}} = {}) => {
    Sentry.withScope(scope => {
        scope.setTags({
            user: accountStore.userInfo.username ?? "-",
            notify: notify
        });

        for (let key in options) {
            scope.setExtra(key, options[key])
        }

        Sentry.captureMessage(message);
    });
}