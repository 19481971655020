import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import FormHelperText from "@mui/material/FormHelperText";

const PasswordField = ({ register, errors, id, label, options, maxWidth, autoComplete, type, showPassword, handleClickShowPassword, handleMouseDownPassword }) => {
  return(
    <FormControl error={!!errors[id]} fullWidth variant="outlined">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="end" sx={{padding: "0 7px 0 0"}}>
            <IconButton
              aria-label="Vis adgangskode"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...register(id, options)}
      />
      <FormHelperText id={id}>{errors[id] && errors[id].message}</FormHelperText>
    </FormControl>
  )
}

export default observer(PasswordField);