import React, { Fragment, useEffect } from "react";
import { Container } from "@mui/material";
import Space from "../../Basic/Space";
import Typography from "@mui/material/Typography";
import PictureRender from "../../Shared/Pictures/PictureRender";

export default function CookieSettings() {
  useEffect(()=>{
    const tag = document.createElement("script");
    tag.async = true;
    tag.src = "https://consent.cookiebot.com/579558e9-8615-4c93-8ad4-d8b74b9fda35/cd.js";
    tag.id = "CookieDeclaration"
    document.getElementById("cookie-declaration").appendChild(tag);
    return ()=>{
      document.getElementById("cookie-declaration").removeChild(tag);
    };
  },[])
  return (
    <React.Fragment>
      <Space height="20px" />
      <Container>
        <PictureRender image="logo-round-text" size={150}/>
        <Typography variant="h3">Cookiedeklaration</Typography>
        <Space height="10px" />
        <div id={"cookie-declaration"} />
      </Container>
    </React.Fragment>
  );
}