import {Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MobileGallery from "./MobileGallery";
import {accountStore} from "../../Stores/AccountStore";
import MobileEditBar from "./MobileEditBar";
import ContactOwner from "./ContactOwner";
import React from "react";
import { conditionMap } from "./Post";
import Column from "../Basic/Column";
import Chat from "../Chat/Chat";
import {formatNumber} from "../../Utilities/postUtils";
import {timestampToDateTime} from "../../Utilities/dateUtils";
import {Ad} from "../Ads/Ad";
import {bannerStore} from "../../Stores/BannerStore";
import {LinkSanitizerWrapper} from "./LinkSanitizerWrapper";

export const PostMobile = ({
    loading,
    isMyPost,
    onDeactivate,
    onActivate,
    onDelete,
    scrollToMessageArea,
    post
}) => {
    const bannerLocation = "M3"
    const banner = bannerStore.banners[bannerLocation];
    const aspectRatio = "3/1";

    return <Stack>
        <Column>
            {/* Title row */}
            <Typography sx={{
                fontWeight: 'bold',
                fontSize: '30px',
                wordWrap: "break-word",
                lineHeight: '35px',
            }}>
                {post.Heading}
            </Typography>

            {/* Price row */}
            <Typography
                alignItems="flex-start"
                sx={{fontSize: '24px'}}
            >
                {formatNumber({value: post.Price.toString()})} kr.
            </Typography>

            {/* Date row  */}
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{color: "#474747"}}
            >
                <CalendarMonthIcon fontSize="small"/>
                <Typography>{timestampToDateTime(post.CreationTime)}</Typography>
            </Stack>
        </Column>

        <MobileGallery gallery={post.Images}/>

        {!loading && isMyPost && accountStore.logged && (
            <Box mt={2}>
                <MobileEditBar
                    handleDeactivatePost={onDeactivate}
                    handleActivatePost={onActivate}
                    handleDeletePost={onDelete}
                    post={post}
                />
            </Box>
        )}
        <Box mt={2}>
            <TableContainer>
                <Table sx={{
                    backgroundColor: "#f3f3f4",
                    borderRadius: 1
                }}>
                    <TableBody>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{color: "#7d7d7d"}}>
                                Tilstand
                            </TableCell>
                            <TableCell sx={{width: "100%"}}>
                                {conditionMap[post.Condition]}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{color: "#7d7d7d"}}>
                                Kategori
                            </TableCell>
                            <TableCell sx={{width: "100%"}}>
                                {post.Subcategory.Name}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        <Box mt={2}>
            <LinkSanitizerWrapper text={post.Body} postId={post.PostId} />
        </Box>

        <Box mt={1}>
            <ContactOwner scrollToMessageArea={scrollToMessageArea} sx={{padding: "10px 0"}} post={post}/>
        </Box>
        <Box mt={1}>
            <Ad
                banner={banner}
                aspectRatio={aspectRatio}
            />        </Box>
        <Box sx={{p: 1, borderRadius: 1}} id="postMessages">
            <Chat post={post}/>
        </Box>
    </Stack>
}