import {Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import React from "react";
import {useMediaContext} from "../../MediaContext";
import {FrontPageDesktop} from "./FrontpageDesktop/FrontpageDesktop";
import {FrontPageMobile} from "./FrontpageMobile/FrontpageMobile";

export const Title = ({title}) => {
    return (
        <Typography
            variant="h5"
            sx={{mb: 1, fontWeight: "bold"}}
        >{title}</Typography>
    );
}

function FrontPage() {
    const mediaContext = useMediaContext();

    if (mediaContext.isMobile)
        return <FrontPageMobile/>

    if (!mediaContext.isMobile)
        return <FrontPageDesktop/>
}

export default observer(FrontPage);
