import {reportException} from "../Utilities/sentry";

class CategoryService {
    async fetchCategories() {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };
        try {
            let response = await fetch(
                process.env.REACT_APP_API_URL + "/api/Categories",
                requestOptions
            );
            let result = await response.json();
            return result;
        } catch (e) {
            reportException(e);
        }
    }
}

export default new CategoryService();
